import { useMemo, useState } from 'react';
import { AdminSettingsLayout, Table, UpdateFieldModal } from '../../../common/components';
import { useScreenBack } from '../../../common/hooks';
import { LinkComponent } from './ScreenPlan';

const messageHeaders = [
    { label: 'Tipo', path: 'description' },
    { label: 'Mensagem', path: 'value' },
];

const imageHeaders = [
    { label: 'Tipo', path: 'description' },
    // { label: 'Tamanho', path: 'size' },
    { label: 'Imagem', path: 'value', Component: LinkComponent },
];

const messageValues = [
    { id: 'success_text', description: 'Titulo de devolução com sucesso', value: '', type: 'text' },
    { id: 'fail_text', description: 'Titulo de devolução com falha', value: '', type: 'text' },
    {
        id: 'success_content',
        description: 'Mensagem de devolução com sucesso',
        value: '',
        type: 'text',
    },
    { id: 'fail_content', description: 'Mensagem do devolução com falha', value: '', type: 'text' },
];

const imageValues = [
    {
        id: 'success_cross',
        description: 'Imagem de devolução com sucesso da máquina de 8 slots',
        value: '',
        // size: '1280x1024',
        type: 'image',
    },
    {
        id: 'fail_cross',
        description: 'Imagem de devolução com falha da máquina de 8 slots',
        value: '',
        // size: '1280x1024',
        type: 'image',
    },
    {
        id: 'success_vertical',
        description: 'Imagem de devolução com sucesso do gabinete grande',
        value: '',
        // size: '1280x1024',
        type: 'image',
    },
    {
        id: 'fail_vertical',
        description: 'Imagem de devolução com falha do gabinete grande',
        value: '',
        // size: '1280x1024',
        type: 'image',
    },
];

const AdminScreenBacks = () => {
    const { screenBack, updateScreenBackFile, updateScreenBack, loading } = useScreenBack(true);

    const [screenBackMessagesData, screenBackImagesData] = useMemo(() => {
        if (!screenBack) return [messageValues, imageValues];

        const _messages = messageValues.map((message) => ({
            ...message,
            value: screenBack[message.id],
        }));

        const _images = imageValues.map((image) => ({
            ...image,
            value: screenBack[image.id],
        }));

        return [_messages, _images];
        // eslint-disable-next-line
    }, [screenBack]);

    const [updateBack, setUpdateBack] = useState<any>();

    const onConfirmUpdate = async (data: any) => {
        if (!data) return;
        try {
            if (typeof data === 'string') {
                await updateScreenBack({ [updateBack.id]: data });
            } else {
                await updateScreenBackFile(updateBack.id, data);
            }
        } catch (err) {}
    };

    return (
        <AdminSettingsLayout title="Padrões de devolução">
            <div className="flex flex-col flex-1">
                <div className="mb-4 text-lg font-semibold">Mensagens de texto</div>
                <Table
                    headers={messageHeaders}
                    data={screenBackMessagesData}
                    actionButtonLabel="Alterar"
                    onClickAction={setUpdateBack}
                    loading={loading}
                />
                <div className="mt-10 mb-4 text-lg font-semibold">Imagens</div>
                <Table
                    headers={imageHeaders}
                    data={screenBackImagesData}
                    actionButtonLabel="Alterar"
                    onClickAction={setUpdateBack}
                    loading={loading}
                />
            </div>
            <UpdateFieldModal
                title={updateBack?.description}
                open={!!updateBack}
                acceptButtonAction={onConfirmUpdate}
                inputName={updateBack?.id}
                closeModal={() => setUpdateBack(undefined)}
                loading={loading}
                type={updateBack?.type}
                fileInputAccept="image/*"
                value={updateBack?.value}
            />
        </AdminSettingsLayout>
    );
};

export default AdminScreenBacks;
