import { Dialog, Transition } from '@headlessui/react';
import JsonView from '@uiw/react-json-view';
import { FC, Fragment, useRef } from 'react';

interface Props {
    open: boolean;
    details?: any;
    closeModal: () => void;
}

const LogDetailsModal: FC<Props> = ({ open, details, closeModal }) => {
    const cancelButtonRef = useRef(null);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={closeModal}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="overflow-y-auto fixed inset-0 z-10">
                    <div className="flex justify-center items-end p-4 min-h-full text-center sm:items-center sm:p-0 sm:pb-[5%]">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="overflow-hidden relative max-w-6xl text-left bg-white rounded-lg shadow-xl transition-all transform sm:my-8 sm:w-full">
                                <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        {/* <pre>{JSON.stringify(details, null, 4)}</pre> */}
                                        <JsonView
                                            value={details}
                                            enableClipboard={false}
                                            // displayDataTypes={false}
                                            // displayObjectSize={false}
                                            collapsed={1}
                                        />
                                    </div>
                                </div>
                                <div className="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-3 py-2 mt-3 w-full text-sm font-semibold text-gray-900 bg-white rounded-md ring-1 ring-inset ring-gray-300 shadow-sm hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={closeModal}
                                        ref={cancelButtonRef}
                                    >
                                        Fechar
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default LogDetailsModal;
