import { FC } from 'react';
import { ToastContainer } from 'react-toastify';
import colors from '../../config/colors';

type Props = {
    children?: React.ReactNode;
};

const BaseLayout: FC<Props> = ({ children }) => {
    return (
        <div style={{ backgroundColor: colors.layout.background }} className="overflow-y-auto">
            <ToastContainer />
            {children}
        </div>
    );
};

export default BaseLayout;
