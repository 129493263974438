import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PartnerLayoutWithSidebar } from '../../../common/components';
import { useLocations } from '../../../common/hooks';
import { getSelectedOrg } from '../../../common/store';
import { payloadFromSchema, schema } from './PartnerCreateLocation';
import PartnerLocationForm from './PartnerLocationForm';

interface Props {
    orgId?: string;
}

const createFormData = (data: any) => ({
    ...data,
    split_rule: {
        ...data.split_rule,
        percentage_splits: data.split_rule.percentage_splits.map((split: any) => ({
            ...split,
            bank_account_id: split.bank_account
                ? {
                      value: split.bank_account.id,
                      label: split.bank_account.name,
                  }
                : undefined,
        })),
    },
    devices: data.devices.map((dev: any) => ({
        value: dev.id,
        label: dev.serial_number,
    })),
});

const PartnerUpdateLocation: FC<Props> = () => {
    const org = useSelector(getSelectedOrg);
    const orgId = org?.id as string;
    const { updateLocation, loading, error } = useLocations();
    const navigate = useNavigate();
    const location = useLocation();

    const locationData = location.state?.data;

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        getValues,
        setValue,
        watch,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: useMemo(() => locationData && createFormData(locationData), [locationData]),
    });

    useEffect(() => {
        reset(createFormData(locationData));

        // eslint-disable-next-line
    }, []);

    const [advertising_org_time_percentage, advertising_org_value_percentage] = watch([
        'advertising_org_time_percentage',
        'advertising_org_value_percentage',
    ]);

    useEffect(() => {
        const advertising_total_time_percentage = Number(org?.advertising_org_time_percentage);
        setValue('advertising_total_time_percentage', advertising_total_time_percentage);

        if (!advertising_org_time_percentage) {
            setValue('advertising_org_time_percentage', advertising_total_time_percentage);
        }

        if (!advertising_org_value_percentage) {
            setValue('advertising_org_value_percentage', 100);
        }

        // eslint-disable-next-line
    }, []);

    const onSubmit = async (data: any) => {
        const payload = payloadFromSchema(data, orgId);

        try {
            await updateLocation(locationData.id, payload);
            const path = `/orgs/${orgId}/locations`;
            navigate(path);
        } catch (err) {
            // try again...
        }
    };

    return (
        <PartnerLayoutWithSidebar>
            <PartnerLocationForm
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors} // client-side validation
                error={error} // server-side error
                loading={loading}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
                org={org}
            />
        </PartnerLayoutWithSidebar>
    );
};

export default PartnerUpdateLocation;
