import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { PartnerLayoutWithSidebar } from '../../../common/components';
import { useOrgUsers } from '../../../common/hooks';
import { requiredFieldMessage, validEmailMessage } from '../../../common/utils/validation.utils';
import UserForm from './UserForm';

const schema = yup
    .object({
        name: yup.string().trim().required(requiredFieldMessage()),
        email: yup.string().trim().email(validEmailMessage()).required(requiredFieldMessage()),
    })
    .required(requiredFieldMessage());

const PartnerAddUser = () => {
    const { addOrgUser, loading, error } = useOrgUsers();
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: '',
            name: '',
        },
    });

    const onSubmit = async (data: any) => {
        if (loading) return;

        try {
            await addOrgUser(data);
            navigate(-1);
        } catch (err) {
            // try again...
        }
    };

    return (
        <PartnerLayoutWithSidebar>
            <UserForm
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors} // client-side validation
                error={error} // server-side error
                loading={loading}
            />
        </PartnerLayoutWithSidebar>
    );
};

export default PartnerAddUser;
