import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';

interface Options {
    fetchData?: boolean;
    fetchDataOptions?: boolean;
    orgId?: string;
}

const useBankAccounts = (
    { fetchData = false, fetchDataOptions = false, orgId }: Options = {} as any,
) => {
    const [bankAccounts, setBankAccounts] = useState<{
        meta: any;
        data: any[];
    }>();
    const [bankAccountOptions, setBankAccountOptions] = useState<any[]>([]);
    const { get, post, patch, _delete, loading, error } = useRequests();

    const fetchBankAccounts = async (page?: string) => {
        let route = `/admin/bank-accounts?`;

        if (orgId) {
            route += `orgId=${orgId}&`;
        }

        if (page) {
            route += `page=${page}`;
        }

        try {
            const response = await get(route);
            const data = response?.data;
            setBankAccounts(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const fetchBankAccountOptions = async () => {
        let route = `/admin/bank-accounts?perPage=1000&`;

        if (orgId) {
            route += `orgId=${orgId}`;
        }

        try {
            const response = await get(route);
            const data = response?.data?.data;
            const options = data?.map((bankAccount: any) => ({
                value: bankAccount.id,
                label: bankAccount.name,
            }));
            setBankAccountOptions(options || []);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const createBankAccount = async (payload: any) => {
        let route = `/admin/bank-accounts`;

        if (orgId) {
            payload.org_id = orgId;
        }

        try {
            await post(route, payload);
            setTimeout(() => toast.success('Conta bancária criada com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const updateBankAccount = async (id: string, payload: any) => {
        let route = `/admin/bank-accounts/${id}`;

        try {
            await patch(route, payload);
            setTimeout(() => toast.success('Conta bancária alterada com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const deleteBankAccount = async (id: string) => {
        let route = `/admin/bank-accounts/${id}`;

        try {
            await _delete(route);
            setTimeout(() => toast.success('Conta bancária excluida com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const fetchAddressFromZipcode = async (zipcode: string) => {
        const route = `https://viacep.com.br/ws/${zipcode}/json/`;

        try {
            const response = await get(route, { raw: true });
            return response.data;
        } catch (err: any) {
            throw err;
        }
    };

    useEffect(() => {
        if (fetchData) {
            fetchBankAccounts();
        }
        if (fetchDataOptions) {
            fetchBankAccountOptions();
        }

        // eslint-disable-next-line
    }, []);

    return {
        bankAccounts,
        bankAccountOptions,
        fetchBankAccounts,
        createBankAccount,
        updateBankAccount,
        deleteBankAccount,
        fetchAddressFromZipcode,
        error,
        loading,
    };
};

export default useBankAccounts;
