import { useState } from 'react';
import { AdminSettingsLayout, DeleteConfirmationModal, Table } from '../../../common/components';
import { useAdminAccounts, useUsers } from '../../../common/hooks';

const headers = [
    { label: 'Nome', path: 'name' },
    { label: 'Email', path: 'email' },
];

const AdminAccounts = () => {
    const { adminAccounts, fetchAdminAccounts, loading } = useAdminAccounts(true);
    const { deleteUser } = useUsers();

    const [userToDelete, setUserToDelete] = useState<User>();
    const closeUserDeleteModal = () => setUserToDelete(undefined);

    const onClickDelete = (user: User) => {
        setUserToDelete(user);
    };

    const onConfirmDelete = async () => {
        if (!userToDelete) return;
        const error = await deleteUser(userToDelete.id);

        if (error) return;
        setUserToDelete(undefined);
        fetchAdminAccounts();
    };

    return (
        <AdminSettingsLayout title="Administradores">
            <Table
                headers={headers}
                data={adminAccounts}
                onClickDelete={onClickDelete}
                loading={loading}
            />
            <DeleteConfirmationModal
                title="Excluir conta de administrador"
                description="Você tem certeza que deseja excluir essa conta de usuário
administrador? Essa operação não pode ser desfeita."
                open={!!userToDelete}
                closeModal={closeUserDeleteModal}
                acceptButtonAction={onConfirmDelete}
            />
        </AdminSettingsLayout>
    );
};

export default AdminAccounts;
