import { useSelector } from 'react-redux';
import { PartnerLayoutWithSidebar } from '../../../common/components';
import { getSelectedOrg } from '../../../common/store';
import BankAccountList from '../../common/bank-accounts/BankAccountList';

const PartnerBankAccountList = () => {
    const selectedOrg = useSelector(getSelectedOrg);
    const orgId = selectedOrg?.id;

    return (
        <PartnerLayoutWithSidebar>
            <BankAccountList orgId={orgId} />
        </PartnerLayoutWithSidebar>
    );
};

export default PartnerBankAccountList;
