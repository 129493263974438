import { useState } from 'react';
import {
    AdminSettingsLayout,
    DeleteConfirmationModal,
    TableOrderingButton,
    TableWithReordering,
} from '../../../../common/components';
import { useFaqs } from '../../../../common/hooks';

const headers = [
    { label: '', path: 'position', Component: TableOrderingButton },
    { label: 'Título', path: 'title' },
];

const Faq = () => {
    const { faqs, fetchFaqs, deleteFaq, updateFaqPosition, loading } = useFaqs();

    const [faqToDelete, setFaqToDelete] = useState<Faq>();
    const closeFaqDeleteModal = () => setFaqToDelete(undefined);

    const onClickDelete = (faq: Faq) => {
        setFaqToDelete(faq);
    };

    const onConfirmDelete = async () => {
        if (!faqToDelete) return;
        try {
            await deleteFaq(faqToDelete.id);
        } catch (err) {
            // try again...
        }

        setFaqToDelete(undefined);
        fetchFaqs();
    };

    const addFaqTitle = 'Adicionar tópico de ajuda';
    const addFaqHref = '/settings/faq/new';
    const editFaqHref = '/settings/faq/edit';

    const onSortItem = async (itemId: string, position: number) => {
        await updateFaqPosition(itemId, position);
    };

    return (
        <AdminSettingsLayout title="Tópicos de ajuda (FAQ)">
            <TableWithReordering
                headers={headers}
                data={faqs}
                onClickDelete={onClickDelete}
                titleButtonLabel={addFaqTitle}
                titleButtonHref={addFaqHref}
                editPath={editFaqHref}
                loading={loading}
                onReorderAction={onSortItem}
            />
            <DeleteConfirmationModal
                title="Excluir tópico de ajuda"
                description="Você tem certeza que deseja excluir esse tópico de ajuda? Essa operação não pode ser desfeita."
                open={!!faqToDelete}
                closeModal={closeFaqDeleteModal}
                acceptButtonAction={onConfirmDelete}
            />
        </AdminSettingsLayout>
    );
};

export default Faq;
