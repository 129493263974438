import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Pagination,
    PartnerLayoutWithSidebar,
    SearchBarInput,
    Table,
} from '../../../common/components';
import { BatteryStatusPillComponent } from '../../../common/components/StatusPillComponent';
import { useBatteries } from '../../../common/hooks';
import { getSelectedOrg } from '../../../common/store';
import { formatDate } from '../../../common/utils/date.utils';

export const DateParserComponent: FC<{ value: string }> = ({ value }) => {
    const date = useMemo(() => {
        return formatDate(value) || '-';
    }, [value]);

    return <>{date}</>;
};

const PercentageComponent: FC<any> = ({ value }) => {
    if (typeof value !== 'number') return <>-</>;
    return <>{value}%</>;
};

const headers = [
    { label: 'Número de série', path: 'serial_number' },
    { label: 'Proprietário', path: 'org.name' },
    { label: 'Último parceiro', path: 'last_location.org.name' },
    { label: 'Último local', path: 'last_location.name' },
    { label: 'Carga', path: 'charge', Component: PercentageComponent },
    { label: 'Status', path: 'status', Component: BatteryStatusPillComponent },
    {
        label: 'Data atualização',
        path: 'updated_at',
        Component: DateParserComponent,
    },
];

const PartnerBatteryList = () => {
    const selectedOrg = useSelector(getSelectedOrg);
    const orgId = selectedOrg?.id;
    const { batteries, fetchBatteries, loading } = useBatteries({
        fetchData: true,
        orgId,
    });

    const [search, setSearch] = useState('');

    const submitSearch = () => {
        fetchBatteries({ search });
    };

    const changePage = (page: number | string) => {
        if (page !== batteries?.meta.currentPage) {
            fetchBatteries({ search, page });
        }
    };

    return (
        <PartnerLayoutWithSidebar>
            <SearchBarInput search={search} setSearch={setSearch} submit={submitSearch} />
            <Table
                headers={headers}
                data={batteries?.data || []}
                className="mt-4"
                loading={loading}
            />
            <Pagination meta={batteries?.meta} changePage={changePage} />
        </PartnerLayoutWithSidebar>
    );
};

export default PartnerBatteryList;
