import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    AccentButton,
    AdminLayout,
    AdminLayoutContentCard,
    BaseInput,
    BaseSelectInput,
    BasicButton,
    DatePickerRangeInput,
    LogStatusPillComponent,
    Pagination,
    Table,
} from '../../../common/components';
import { useLogs } from '../../../common/hooks';
import { formatDate } from '../../../common/utils/date.utils';
import LogDetailsModal from './LogDetailsModal';

export const DateTimeSecondsParserComponent: FC<{ value: string }> = ({ value }) => {
    const date = useMemo(() => {
        return formatDate(value, true) || '-';
    }, [value]);

    return <>{date}</>;
};

const headers = [
    { label: 'Tipo', path: 'level', Component: LogStatusPillComponent },
    { label: 'Escopo', path: 'scope' },
    { label: 'Titulo', path: 'title' },
    // { label: 'Descrição', path: 'description' },
    // { label: 'Cliente', path: 'user.name', defaultValue: 'Luis Phelipe' },
    { label: 'Cliente', path: 'user.name' },
    { label: 'Local', path: 'location.name' },
    { label: 'Parceiro', path: 'org.name' },
    { label: 'Máquina', path: 'device.serial_number' },
    { label: 'Bateria', path: 'battery.serial_number' },
    { label: 'Data', path: 'created_at', Component: DateTimeSecondsParserComponent },
];

const levelOptions = [
    { label: 'Todos', value: '' },
    { label: 'Debug', value: 'DEBUG' },
    { label: 'Info', value: 'INFO' },
    { label: 'Aviso', value: 'WARNING' },
    { label: 'Erro', value: 'ERROR' },
    { label: 'Fatal', value: 'FATAL' },
];

const defaultFilters = {};
const defaultDateRange = {
    startDate: null,
    endDate: null,
};

const extractStateFilters = (location: any) => {
    if (location.state?.filters) {
        return location.state?.filters;
    }

    if (location.search) {
        const params = new URLSearchParams(location.search);
        const key = params.get('filter_key');
        const value = params.get('filter_val');

        if (key && value) {
            return { [key]: value };
        }
    }

    return defaultFilters;
};

const extractStateTitle = (location: any) => {
    const title = 'Logs ';

    if (location?.state?.title) return title + location.state.title;

    if (location.search) {
        const params = new URLSearchParams(location.search);
        const _title = params.get('title');
        if (_title) return title + _title;
    }

    return title;
};

const LogList = () => {
    const location = useLocation();

    const title = useMemo(() => extractStateTitle(location), [location]);
    const filters = useMemo(() => extractStateFilters(location), [location]);

    const { logs, fetchLogs, loading } = useLogs({
        fetchData: true,
        filters,
    });

    const [type, setType] = useState(levelOptions[0]);
    const [dateRange, setDateRange] = useState<any>(defaultDateRange);
    const [trigger, setTrigger] = useState(false);

    const [clientSearch, setClientSearch] = useState('');
    const [locationSearch, setLocationSearch] = useState('');
    const [orgSearch, setOrgSearch] = useState('');
    const [deviceSearch, setDeviceSearch] = useState('');
    const [batterySearch, setBatterySearch] = useState('');

    const clearFilters = () => {
        // basic filters
        setType(levelOptions[0]);
        setDateRange(defaultDateRange);

        // advanced filters
        setClientSearch('');
        setLocationSearch('');
        setOrgSearch('');
        setDeviceSearch('');
        setBatterySearch('');

        // trigger to re-fetch logs
        setTrigger(true);
    };

    const [showFilters, setShowFilters] = useState(false);

    const toggleFilters = () => {
        setShowFilters((old) => !old);
    };

    const submitFilters = (page?: any) => {
        const search_filters = {
            user_search: clientSearch,
            location_search: locationSearch,
            org_search: orgSearch,
            device_search: deviceSearch,
            battery_search: batterySearch,
        };

        fetchLogs({
            level: type?.value as LogLevel,
            date_from: dateRange.startDate,
            date_to: dateRange.endDate,
            search_filters,
            page,
        });
    };

    const changePage = (page: number | string) => {
        if (page !== logs?.meta.currentPage) {
            submitFilters(page);
        }
    };

    useEffect(() => {
        if (trigger) {
            setTrigger(false);
            submitFilters();
        }
        // eslint-disable-next-line
    }, [trigger]);

    const [detailsModalOpen, setDetailsModalOpen] = useState(false);
    const [detailsModal, setDetailsModal] = useState();

    const openDetailsModal = (details: any) => {
        setDetailsModal(details);
        setDetailsModalOpen(true);
    };

    const closeDetailsModal = () => {
        setDetailsModalOpen(false);
        setTimeout(() => setDetailsModal(undefined), 300);
    };

    return (
        <AdminLayout title={title}>
            <AdminLayoutContentCard>
                <div className="flex flex-row flex-wrap gap-y-2 gap-x-4 items-end">
                    <BaseSelectInput
                        name="Tipo"
                        options={levelOptions}
                        className="!max-w-[160px]"
                        value={type}
                        onChange={setType}
                    />
                    <div className="!max-w-[290px]">
                        <DatePickerRangeInput
                            value={dateRange}
                            onChange={setDateRange}
                            name="date_filter"
                            label="Período"
                        />
                    </div>

                    {showFilters && (
                        <>
                            <BaseInput
                                name="cliente"
                                label="Cliente"
                                value={location?.state?.filters?.user_id || clientSearch}
                                onChange={(e: any) => setClientSearch(e.target.value)}
                                className="!max-w-[220px]"
                                inputClassName="h-[38px]"
                                disabled={location?.state?.filters?.user_id}
                            />
                            <BaseInput
                                name="location"
                                label="Local"
                                value={location?.state?.filters?.location_id || locationSearch}
                                onChange={(e: any) => setLocationSearch(e.target.value)}
                                className="!max-w-[220px]"
                                inputClassName="h-[38px]"
                                disabled={location?.state?.filters?.location_id}
                            />
                            <BaseInput
                                name="org"
                                label="Parceiro"
                                value={location?.state?.filters?.org_id || orgSearch}
                                onChange={(e: any) => setOrgSearch(e.target.value)}
                                className="!max-w-[220px]"
                                inputClassName="h-[38px]"
                                disabled={location?.state?.filters?.org_id}
                            />
                            <BaseInput
                                name="device"
                                label="Máquina"
                                value={location?.state?.filters?.device_id || deviceSearch}
                                onChange={(e: any) => setDeviceSearch(e.target.value)}
                                className="!max-w-[220px]"
                                inputClassName="h-[38px]"
                                disabled={location?.state?.filters?.device_id}
                            />
                            <BaseInput
                                name="battery"
                                label="Bateria"
                                value={location?.state?.filters?.battery_id || batterySearch}
                                onChange={(e: any) => setBatterySearch(e.target.value)}
                                className="!max-w-[220px]"
                                inputClassName="h-[38px]"
                                disabled={location?.state?.filters?.battery_id}
                            />
                        </>
                    )}
                    <div className="ml-2">
                        <BasicButton
                            className="h-[38px] !shadow-none !px-4"
                            onClick={toggleFilters}
                        >
                            {showFilters ? (
                                <EyeIcon color="#6B7280" height={24} />
                            ) : (
                                <EyeSlashIcon color="#6B7280" height={24} />
                            )}
                            <p className="ml-2">Filtros Avançados</p>
                        </BasicButton>
                    </div>
                    <div>
                        <BasicButton
                            // className="h-[36px] border-0 !shadow-none px-6"
                            className="h-[38px] !shadow-none px-6"
                            onClick={clearFilters}
                        >
                            Limpar
                        </BasicButton>
                    </div>
                    <div>
                        <AccentButton className="h-[38px] px-6" onClick={submitFilters}>
                            Filtrar
                        </AccentButton>
                    </div>
                </div>

                <Table
                    headers={headers}
                    data={logs?.data || []}
                    className="mt-4"
                    valueClassName="!py-2"
                    actionButtonLabel="Detalhes"
                    onClickAction={openDetailsModal}
                    loading={loading}
                />
                <Pagination meta={logs?.meta} changePage={changePage} />
            </AdminLayoutContentCard>

            <LogDetailsModal
                open={detailsModalOpen}
                closeModal={closeDetailsModal}
                details={detailsModal}
            />
        </AdminLayout>
    );
};

export default LogList;
