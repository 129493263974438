import { FC } from 'react';
import { ButtonLoading } from '../assets';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    children?: React.ReactNode;
    loading?: boolean;
};

const BasicButton: FC<Props> = ({ children, loading, className, ...props }) => {
    const isDisabled = props.disabled || loading;

    return (
        <button
            type="button"
            {...props}
            disabled={isDisabled}
            className={`inline-flex justify-center items-center px-6 text-sm font-semibold leading-4 text-gray-900 bg-white rounded-md border border-gray-300 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-accent-400 h-[31px] disabled:bg-gray-100 disabled:border-gray-100 disabled:pointer-events-none ${className}`}
        >
            {loading ? (
                <div className="relative bottom-0.5 translate-x-1.5 translate-y-0.5">
                    <ButtonLoading />
                </div>
            ) : (
                children
            )}
        </button>
    );
};
export default BasicButton;
