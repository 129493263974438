import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';

const useScreenRent = (fetchData = false) => {
    const [screenRent, setScreenRent] = useState<any>({});
    const { get, patch, loading, error } = useRequests();

    const fetchScreenRent = async () => {
        const route = '/admin/screen-rents';

        try {
            const response = await get(route);
            const data = response?.data?.data?.[0];
            setScreenRent(data || {});
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const updateScreenRentFile = async (field: string, file: any) => {
        // to upload file
        const formData = new FormData();
        formData.append(field, file);
        return updateScreenRent(formData);
    };

    const updateScreenRent = async (payload: any) => {
        if (!screenRent.id) return;
        const route = `/admin/screen-rents/${screenRent.id}`;

        try {
            const response = await patch(route, payload);
            const _screenRent = response?.data;
            setScreenRent(_screenRent || {});
            return _screenRent;
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    useEffect(() => {
        if (fetchData) {
            fetchScreenRent();
        }
        // eslint-disable-next-line
    }, []);

    return {
        screenRent,
        updateScreenRentFile,
        updateScreenRent,
        error,
        loading,
    };
};

export default useScreenRent;
