import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { FC, Fragment, useMemo, useRef, useState } from 'react';
import { getFileType } from '../utils/file.utils';
import AccentButton from './AccentButton';
import { BaseInput, FileInput } from './Input';

interface Props {
    title: string;
    description?: string;
    type?: 'video,image' | 'video' | 'image' | 'audio' | 'text';
    open: boolean;
    closeModal: () => void;
    acceptButtonAction: (data: any) => Promise<void>;
    inputName: string;
    fileInputAccept?: string;
    loading?: boolean;
    value?: string;
}

const UpdateFieldModal: FC<Props> = ({
    open,
    title: _title,
    description,
    inputName: _inputName,
    type: _type = 'audio',
    fileInputAccept,
    acceptButtonAction,
    loading,
    closeModal: _closeModal,
    value: _value,
}) => {
    const buildCache = () => {
        return {
            type: _type,
            title: _title,
            inputName: _inputName,
            value: _value,
        };
    };

    const cache = useRef<any>();

    // This fix the issue of information briefly disapearing during the close modal animation
    const { type, title, inputName, value } = useMemo(() => {
        if (open || !cache.current) {
            cache.current = buildCache();
        }
        return cache.current;
        // eslint-disable-next-line
    }, [open]);

    // REF TO FOCUS
    const cancelButtonRef = useRef(null);

    // MODAL INPUT DATA
    const [data, setData] = useState<any>();

    // SUBMIT MODAL
    const onConfirm = async () => {
        await acceptButtonAction(data);
        closeModal();
    };

    // CLOSE MODAL
    const closeModal = () => {
        _closeModal();
        setTimeout(() => setData(undefined), 300);
        setTimeout(() => setImagePreview(undefined), 300);
        setTimeout(() => setVideoPreview(undefined), 300);
        setTimeout(() => (videoRef.current = undefined), 300);
    };

    const types = type.split(',');

    // IMAGE/VIDEO PREVIEW
    const hasVideoPreview = types.includes('video');
    const hasImagePreview = types.includes('image');

    const [imagePreview, setImagePreview] = useState<any>();

    const [videoPreview, setVideoPreview] = useState<any>();
    const videoRef = useRef<any>();

    // FILE INPUT
    const handleFileInputChange = (event: any) => {
        const file = event.target.files[0];
        setData(file);

        if (hasImagePreview && file.type.startsWith('image')) {
            setVideoPreview(undefined);
            const reader = new FileReader();
            reader.onloadend = () => setImagePreview(reader.result);
            reader.readAsDataURL(file);
        }

        if (hasVideoPreview && file.type.startsWith('video')) {
            setImagePreview(undefined);
            const videoSrc = URL.createObjectURL(file);
            setVideoPreview(videoSrc);

            // Cleanup the old video source when it changes or the component unmounts
            return () => {
                URL.revokeObjectURL(videoSrc);
            };
        }
    };

    const renderFileInput = () => {
        return (
            <FileInput
                name={inputName || '-'}
                onChange={handleFileInputChange}
                className="w-full"
                type="file"
                data={data}
                accept={fileInputAccept}
            />
        );
    };

    // TEXT INPUT
    const handleTextInputChange = (event: any) => {
        setData(event.target.value);
    };

    const renderTextInput = () => {
        const selectedValue = data || value;
        return (
            <BaseInput
                name={inputName || '-'}
                onChange={handleTextInputChange}
                className="sm:w-full"
                value={selectedValue}
            />
        );
    };

    let renderInput = renderFileInput;
    if (type === 'text') {
        renderInput = renderTextInput;
    }

    const selectedType = useMemo(() => {
        if (videoPreview) return 'video';
        if (imagePreview) return 'image';
        return getFileType(value);
    }, [videoPreview, imagePreview, value]);

    const selectedImage = hasImagePreview && (imagePreview || value);
    const selectedVideo = hasVideoPreview && (videoPreview || value);

    // console.log({ selectedType, imagePreview, videoPreview });

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={closeModal}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="overflow-y-auto fixed inset-0 z-10">
                    <div className="flex justify-center items-end p-4 min-h-full text-center sm:items-center sm:p-0 sm:pb-[5%]">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="overflow-hidden relative text-left bg-white rounded-lg shadow-xl transition-all transform sm:my-8 sm:w-full sm:max-w-3xl">
                                <div className="p-6 pb-4 bg-white">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-left">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-base font-semibold leading-6 text-gray-900"
                                            >
                                                {title || '-'}
                                            </Dialog.Title>
                                            {description ? (
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        {description}
                                                    </p>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="px-4 mb-6 bg-white sm:p-6 sm:pb-4">
                                    {renderInput()}
                                </div>
                                {hasImagePreview && (
                                    <div className="flex flex-col flex-grow mx-6 mb-6">
                                        <div className="mb-3 text-sm font-medium">
                                            Pré-visualização
                                        </div>
                                        <div className="flex justify-center rounded-md border-gray-300 border align-center w-full h-full max-h-[600px] min-h-[300px]">
                                            {hasImagePreview && selectedType === 'image' && (
                                                <img
                                                    src={selectedImage}
                                                    alt="Pré-visualização"
                                                    className="object-scale-down"
                                                />
                                            )}
                                            {hasVideoPreview && selectedType === 'video' && (
                                                <video
                                                    ref={videoRef}
                                                    src={selectedVideo}
                                                    controls
                                                    onLoadedData={() => {
                                                        if (videoRef.current.readyState >= 3) {
                                                            videoRef.current.play();
                                                        }
                                                    }}
                                                    className="w-full h-full"
                                                ></video>
                                            )}
                                        </div>
                                    </div>
                                )}

                                <div className="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                                    <AccentButton
                                        onClick={onConfirm}
                                        disabled={!data}
                                        loading={loading}
                                        className="inline-flex ml-3 sm:w-auto"
                                    >
                                        Salvar
                                    </AccentButton>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-3 py-2 mt-3 w-full text-sm font-semibold text-gray-900 bg-white rounded-md ring-1 ring-inset ring-gray-300 shadow-sm hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={closeModal}
                                        ref={cancelButtonRef}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                                <div className="block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={closeModal}
                                    >
                                        <span className="sr-only">fechar</span>
                                        <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default UpdateFieldModal;
