import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    DeleteConfirmationModal,
    Pagination,
    PartnerLayoutWithSidebar,
    Table,
} from '../../../common/components';
import { usePoints } from '../../../common/hooks';
import { getSelectedOrg } from '../../../common/store';

const headers = [
    { label: 'Nome', path: 'name' },
    { label: 'E-mail', path: 'email' },
];

const PartnerPointList = () => {
    const selectedOrg = useSelector(getSelectedOrg);
    const orgId = selectedOrg?.id as any;
    const { points, fetchPoints, deletePoint, loading } = usePoints({
        fetchData: true,
        orgId,
    });

    const [search, setSearch] = useState('');

    const submitSearch = () => {
        console.log('searching for', { search });
        fetchPoints({ search });
    };

    const changePage = (page: number | string) => {
        if (page !== points?.meta.currentPage) {
            fetchPoints({ search, page });
        }
    };

    const [pointToDelete, setPointToDelete] = useState<User>();
    const closeUserDeleteModal = () => setPointToDelete(undefined);

    const onClickDelete = (user: User) => {
        setPointToDelete(user);
    };

    const onConfirmDelete = async () => {
        if (!pointToDelete) return;
        try {
            await deletePoint(pointToDelete.id);
        } catch (err) {}
        setPointToDelete(undefined);
        fetchPoints();
    };

    const activePath = `/orgs/${orgId}/points`;
    const addPointHref = `${activePath}/new`;
    const editPointHref = `${activePath}/edit`;

    return (
        <PartnerLayoutWithSidebar>
            <Table
                headers={headers}
                data={points?.data || []}
                className="mt-4"
                onClickDelete={onClickDelete}
                titleButtonLabel="Adicionar ponto"
                titleButtonHref={addPointHref}
                editPath={editPointHref}
                searchBarProps={{ search, setSearch, submit: submitSearch }}
                loading={loading}
            />
            <Pagination meta={points?.meta} changePage={changePage} />
            <DeleteConfirmationModal
                title="Excluir ponto"
                description="Você tem certeza que deseja excluir esse ponto? 
        Essa operação não pode ser desfeita."
                open={!!pointToDelete}
                closeModal={closeUserDeleteModal}
                acceptButtonAction={onConfirmDelete}
                acceptButtonLabel="conta"
            />
        </PartnerLayoutWithSidebar>
    );
};

export default PartnerPointList;
