import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    AccentButton,
    AdminLayout,
    AdminLayoutContentCard,
    BaseInput,
    BaseSelectInput,
    BasicButton,
    DatePickerRangeInput,
    DeleteConfirmationModal,
    Pagination,
    RentStatusPillComponent,
    Table,
} from '../../../common/components';
import { useAdminRents } from '../../../common/hooks';
import { formatDate } from '../../../common/utils/date.utils';
import { MinutesParserComponent } from '../../partner/rents/PartnerRentList';

export const DateTimeSecondsParserComponent: FC<{ value: string }> = ({ value }) => {
    const date = useMemo(() => {
        return formatDate(value, true) || '-';
    }, [value]);

    return <>{date}</>;
};

const headers = [
    { label: 'Número do pedido', path: 'id' },
    { label: 'Usuário', path: 'client.user.name' },
    { label: 'Data de início', path: 'start_time', Component: DateTimeSecondsParserComponent },
    { label: 'Data de retorno', path: 'end_time', Component: DateTimeSecondsParserComponent },
    { label: 'Duração', path: 'total_time', Component: MinutesParserComponent }, // TODO: use time parse component
    { label: 'Bateria', path: 'battery.serial_number' },
    { label: 'Máquina Origem', path: 'device.serial_number' },
    { label: 'Máquina Destino', path: 'device.serial_number' },
    { label: 'Status', path: 'status', Component: RentStatusPillComponent },
];

const statusOptions = [
    { label: 'Todos', value: '' },
    { label: 'Pagamento pendente', value: 'PENDING_PAYMENT' },
    { label: 'Em andamento', value: 'ACTIVE' },
    { label: 'Finalizado', value: 'FINISHED' },
    { label: 'Cancelado', value: 'CANCELED' },
    { label: 'Falha', value: 'FAILED' },
];

const defaultOption = { value: '', label: '-' };

const defaultDateRange = {
    startDate: null,
    endDate: null,
};

const AdminRentList = () => {
    const location = useLocation();

    const { rents, orgOptions, locationOptions, fetchRents, cancelRent, loading } = useAdminRents({
        fetchData: true,
        fetchOptions: true,
    });

    const [status, setType] = useState(statusOptions[0]); // TODOS
    const [clientSearch, setClientSearch] = useState('');
    const [dateRange, setDateRange] = useState<any>(defaultDateRange);
    const [trigger, setTrigger] = useState(false);

    // advanced
    const [idSearch, setIdSearch] = useState('');
    const [orgId, setOrgId] = useState<SelectOption>(defaultOption);
    const [locationId, setLocationId] = useState<SelectOption>(defaultOption);
    const [batterySearch, setBatterySearch] = useState('');
    const [deviceOriginSearch, setDeviceOriginSearch] = useState('');
    const [deviceReturnSearch, setDeviceReturnSearch] = useState('');

    const clearFilters = () => {
        // basic filters
        setType(statusOptions[0]);
        setDateRange(defaultDateRange);

        // advanced filters
        setOrgId(defaultOption);
        setLocationId(defaultOption);
        setClientSearch('');
        setIdSearch('');
        setDeviceOriginSearch('');
        setDeviceReturnSearch('');
        setBatterySearch('');

        // trigger to re-fetch rents
        setTrigger(true);
    };

    const [showFilters, setShowFilters] = useState(false);

    const toggleFilters = () => {
        setShowFilters((old) => !old);
    };

    const submitFilters = (page?: any) => {
        const search_filters = {
            id_search: idSearch,
            user_search: clientSearch,
            device_origin_search: deviceOriginSearch,
            device_return_search: deviceReturnSearch,
            battery_search: batterySearch,
            org_id: orgId?.value,
            location_id: locationId?.value,
        };

        console.log({ page, search_filters });

        fetchRents({
            status: status?.value as RentStatus,
            date_from: dateRange.startDate,
            date_to: dateRange.endDate,
            search_filters,
            page,
        });
    };

    const changePage = (page: number | string) => {
        if (page !== rents?.meta.currentPage) {
            submitFilters(page);
        }
    };

    useEffect(() => {
        if (trigger) {
            setTrigger(false);
            submitFilters();
        }
        // eslint-disable-next-line
    }, [trigger]);

    // const [detailsModalOpen, setDetailsModalOpen] = useState(false);
    // const [detailsModal, setDetailsModal] = useState();

    // const openDetailsModal = (details: any) => {
    //     setDetailsModal(details);
    //     setDetailsModalOpen(true);
    // };

    // const closeDetailsModal = () => {
    //     setDetailsModalOpen(false);
    //     setTimeout(() => setDetailsModal(undefined), 300);
    // };

    const [rentToCancel, setRentToCancel] = useState<Rent>();
    const closeRentDeleteModal = () => setRentToCancel(undefined);

    const onClickDelete = (rent: Rent) => {
        setRentToCancel(rent);
    };

    const onConfirmCancel = async () => {
        if (!rentToCancel) return;
        try {
            await cancelRent(rentToCancel.id);
            setRentToCancel(undefined);
            submitFilters(rents?.meta.currentPage);
        } catch (err) {
            // try again...
        }
    };

    return (
        <AdminLayout title="Pedidos">
            <AdminLayoutContentCard>
                <div className="flex flex-row flex-wrap gap-2 items-end">
                    <BaseSelectInput
                        name="Status"
                        options={statusOptions}
                        className="!max-w-[160px]"
                        value={status}
                        onChange={setType}
                    />
                    <div className="!max-w-[290px]">
                        <DatePickerRangeInput
                            value={dateRange}
                            onChange={setDateRange}
                            name="date_filter"
                            label="Período"
                        />
                    </div>
                    <BaseInput
                        name="cliente"
                        label="Cliente"
                        value={location?.state?.filters?.user_id || clientSearch}
                        onChange={(e: any) => setClientSearch(e.target.value)}
                        className="!max-w-[220px]"
                        inputClassName="h-[38px]"
                        disabled={location?.state?.filters?.user_id}
                    />
                    {showFilters && (
                        <>
                            <BaseSelectInput
                                name="org"
                                label="Parceiro"
                                value={orgId}
                                onChange={setOrgId}
                                className="!max-w-[220px]"
                                options={orgOptions}
                                hasEmptyOption
                            />
                            <BaseSelectInput
                                name="location"
                                label="Local"
                                value={locationId}
                                onChange={setLocationId}
                                className="!max-w-[220px]"
                                options={locationOptions}
                                hasEmptyOption
                            />

                            <BaseInput
                                name="battery"
                                label="Bateria"
                                value={location?.state?.filters?.battery_id || batterySearch}
                                onChange={(e: any) => setBatterySearch(e.target.value)}
                                className="!max-w-[220px]"
                                inputClassName="h-[38px]"
                                disabled={location?.state?.filters?.battery_id}
                            />
                            <BaseInput
                                name="device_origin"
                                label="Máquina Origem"
                                value={deviceOriginSearch}
                                onChange={(e: any) => setDeviceOriginSearch(e.target.value)}
                                className="!max-w-[220px]"
                                inputClassName="h-[38px]"
                            />
                            <BaseInput
                                name="device_return"
                                label="Máquina Destino"
                                value={deviceReturnSearch}
                                onChange={(e: any) => setDeviceReturnSearch(e.target.value)}
                                className="!max-w-[220px]"
                                inputClassName="h-[38px]"
                            />
                            <BaseInput
                                name="id"
                                label="Número do pedido"
                                value={idSearch}
                                onChange={(e: any) => setIdSearch(e.target.value)}
                                className="!max-w-[220px]"
                                inputClassName="h-[38px]"
                            />
                        </>
                    )}
                    <div className="ml-2">
                        <BasicButton
                            className="h-[38px] !shadow-none !px-4"
                            onClick={toggleFilters}
                        >
                            {showFilters ? (
                                <EyeIcon color="#6B7280" height={24} />
                            ) : (
                                <EyeSlashIcon color="#6B7280" height={24} />
                            )}
                            <p className="ml-2">Filtros Avançados</p>
                        </BasicButton>
                    </div>
                    <div>
                        <BasicButton
                            // className="h-[36px] border-0 !shadow-none px-6"
                            className="h-[38px] !shadow-none px-6"
                            onClick={clearFilters}
                        >
                            Limpar
                        </BasicButton>
                    </div>
                    <div>
                        <AccentButton className="h-[38px] px-6" onClick={() => submitFilters()}>
                            Filtrar
                        </AccentButton>
                    </div>
                </div>

                <Table
                    headers={headers}
                    data={rents?.data || []}
                    className="mt-4"
                    valueClassName="!py-2"
                    deleteButtonLabel="Cancelar" // replace this to onClickDelete...
                    onClickDelete={onClickDelete}
                    deleteCondition={(item: Rent) =>
                        ['PENDING_PAYMENT', 'ACTIVE', 'FINISHED'].includes(item.status)
                    }
                    // actionButtonLabel="Detalhes"
                    // onClickAction={openDetailsModal}
                    loading={loading}
                    logKey="rent_id"
                    logTitle={(item) => `do aluguel ${item.id}`}
                />
                <Pagination meta={rents?.meta} changePage={changePage} />
            </AdminLayoutContentCard>

            <DeleteConfirmationModal
                title="Cancelar Aluguel"
                description="Você tem certeza que deseja cancelar esse aluguel? Se já existir uma transação a mesma também será cancelada/estornada"
                open={!!rentToCancel}
                closeModal={closeRentDeleteModal}
                acceptButtonAction={onConfirmCancel}
                acceptButtonCompleteLabel="Sim, cancelar alguel"
                loading={loading}
            />

            {/* <RentDetailsModal
                open={detailsModalOpen}
                closeModal={closeDetailsModal}
                details={detailsModal}
            /> */}
        </AdminLayout>
    );
};

export default AdminRentList;
