import { useState } from 'react';
import {
    AdminLayout,
    AdminLayoutContentCard,
    DeleteConfirmationModal,
    Pagination,
    SearchBarInput,
    Table,
} from '../../../common/components';
import { useDevices } from '../../../common/hooks';

const headers = [
    { label: 'Número de série', path: 'serial_number' },
    { label: 'Modelo', path: 'model' },
    { label: 'Slots', path: 'battery_slot_quantity' },
    { label: 'Parceiro', path: 'org.name' },
];

const DeviceList = () => {
    const { devices, fetchDevices, deleteDevice, loading } = useDevices({
        fetchData: true,
    });
    const [search, setSearch] = useState('');

    const submitSearch = () => {
        fetchDevices({ search });
    };

    const changePage = (page: number | string) => {
        if (page !== devices?.meta.currentPage) {
            fetchDevices({ search, page });
        }
    };

    const [deviceToDelete, setDeviceToDelete] = useState<User>();
    const closeDeviceDeleteModal = () => setDeviceToDelete(undefined);

    const onClickDelete = (user: User) => {
        setDeviceToDelete(user);
    };

    const onConfirmDelete = async () => {
        if (!deviceToDelete) return;
        try {
            await deleteDevice(deviceToDelete.id);
            setDeviceToDelete(undefined);
            submitSearch();
        } catch (err) {
            // try again...
        }
    };

    return (
        <AdminLayout
            title="Máquinas"
            titleButtonLabel="Adicionar máquina"
            titleButtonHref="/devices/new"
        >
            <AdminLayoutContentCard>
                <SearchBarInput search={search} setSearch={setSearch} submit={submitSearch} />
                <Table
                    headers={headers}
                    data={devices?.data || []}
                    className="mt-4"
                    editPath="/devices/edit"
                    onClickDelete={onClickDelete}
                    loading={loading}
                    logKey="device_id"
                    logTitle={(item) => `da máquina ${item.serial_number}`}
                />
                <Pagination meta={devices?.meta} changePage={changePage} />
            </AdminLayoutContentCard>
            <DeleteConfirmationModal
                title="Excluir máquina"
                description="Você tem certeza que deseja excluir essa máquina? 
        Essa operação não pode ser desfeita."
                open={!!deviceToDelete}
                closeModal={closeDeviceDeleteModal}
                acceptButtonAction={onConfirmDelete}
                // acceptButtonLabel="conta"
            />
        </AdminLayout>
    );
};

export default DeviceList;
