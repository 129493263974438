import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    AdminLayout,
    AdminLayoutContentCard,
    DeleteConfirmationModal,
    Pagination,
    SearchBarInput,
    Table,
} from '../../../common/components';
import { useOrgs } from '../../../common/hooks';
import { applicationActions } from '../../../common/store';

export const PercentageComponent: FC<any> = ({ value }) => {
    if (!value) return <>-</>;
    return <>{value}%</>;
};

export const CountryComponent: FC<any> = ({ value }) => {
    if (!value) return <>-</>;
    const countries: any = { BRA: 'Brasil' };
    return <>{countries[value]}</>;
};

const headers = [
    { label: 'Nome', path: 'name' },
    { label: 'Região', path: 'country', Component: CountryComponent },
    {
        label: 'Comissão',
        path: 'platform_split.total_percentage',
        Component: PercentageComponent,
    },
    {
        label: 'Tempo Publicidade',
        path: 'advertising_platform_time_percentage',
        Component: PercentageComponent,
    },
    {
        label: 'Valor Publicidade',
        path: 'advertising_platform_value_percentage',
        Component: PercentageComponent,
    },
];

const OrgList = () => {
    const { orgs, fetchOrgs, deleteOrg, loading } = useOrgs({ fetchData: true });
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const submitSearch = () => {
        fetchOrgs({ search });
    };

    const changePage = (page: number | string) => {
        if (page !== orgs?.meta.currentPage) {
            fetchOrgs({ search, page });
        }
    };

    const [orgToDelete, setOrgToDelete] = useState<User>();
    const closeOrgDeleteModal = () => setOrgToDelete(undefined);

    const onClickDelete = (user: User) => {
        setOrgToDelete(user);
    };

    const onConfirmDelete = async () => {
        if (!orgToDelete) return;
        try {
            await deleteOrg(orgToDelete.id);
            setOrgToDelete(undefined);
            submitSearch();
        } catch (err) {
            // try again...
        }
    };

    const onClickView = (org: Org) => {
        dispatch(applicationActions.selectOrg(org));
        navigate(`/orgs/${org.id}/devices`);
    };

    return (
        <AdminLayout
            title="Parceiros"
            titleButtonLabel="Adicionar parceiro"
            titleButtonHref="/orgs/new"
        >
            <AdminLayoutContentCard>
                <SearchBarInput search={search} setSearch={setSearch} submit={submitSearch} />
                <Table
                    headers={headers}
                    data={orgs?.data || []}
                    className="mt-4"
                    editPath="/orgs/edit"
                    onClickView={onClickView}
                    onClickDelete={onClickDelete}
                    loading={loading}
                    logKey="org_id"
                    logTitle={(item) => (item.name ? `do parceiro ${item.name}` : '')}
                />
                <Pagination meta={orgs?.meta} changePage={changePage} />
            </AdminLayoutContentCard>
            <DeleteConfirmationModal
                title="Excluir conta de parceiro"
                description="Você tem certeza que deseja excluir essa conta de parceiro? 
        Essa operação não pode ser desfeita."
                open={!!orgToDelete}
                closeModal={closeOrgDeleteModal}
                acceptButtonAction={onConfirmDelete}
                // acceptButtonLabel="conta"
            />
        </AdminLayout>
    );
};

export default OrgList;
