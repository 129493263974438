import { FC } from 'react';
import { Link } from 'react-router-dom';

type Props = {
    href?: string;
    className?: string;
    children?: React.ReactNode;
};

const LinkComponent: FC<Props> = ({ href = '#', className, children }) => {
    return (
        <Link
            to={href}
            className={`font-semibold leading-6 text-accent-400 hover:text-accent-600 ${className}`}
        >
            {children}
        </Link>
    );
};
export default LinkComponent;
