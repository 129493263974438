import { FC, useState } from 'react';
import { DeleteConfirmationModal, Pagination, Table } from '../../../common/components';
import { useBankAccounts } from '../../../common/hooks';
import { BankAccountPagbankPillComponent } from '../../../common/components/StatusPillComponent';

const headers = [
    { label: 'Nome', path: 'name' },
    { label: 'Documento', path: 'document' },
    { label: 'Banco', path: 'account.bank_name' },
    { label: 'Agência', path: 'account.agency' },
    { label: 'Número da conta', path: 'account.number' },
    { label: 'PagBank', path: 'pagbank_id', Component: BankAccountPagbankPillComponent },
];

interface Props {
    orgId?: string;
}

const BankAccountList: FC<Props> = ({ orgId }) => {
    const { bankAccounts, fetchBankAccounts, deleteBankAccount, loading } = useBankAccounts({
        fetchData: true,
        orgId,
    });

    const changePage = (page: number) => {
        if (page !== bankAccounts?.meta.currentPage) {
            fetchBankAccounts(page.toString());
        }
    };

    const [accountToDelete, setAccountToDelete] = useState<User>();
    const closeUserDeleteModal = () => setAccountToDelete(undefined);
    const onClickDelete = (user: User) => {
        setAccountToDelete(user);
    };

    const onConfirmDelete = async () => {
        if (!accountToDelete) return;
        try {
            await deleteBankAccount(accountToDelete.id);
        } catch (err) {}
        setAccountToDelete(undefined);
        fetchBankAccounts();
    };

    const activePath = orgId ? `/orgs/${orgId}` : `/settings`;
    const addBankAccountHref = `${activePath}/bank-accounts/new`;
    const editBankAccountHref = `${activePath}/bank-accounts/edit`;

    return (
        <div className="flex flex-col flex-grow">
            <Table
                headers={headers}
                data={bankAccounts?.data || []}
                onClickDelete={onClickDelete}
                titleButtonLabel="Adicionar conta bancária"
                titleButtonHref={addBankAccountHref}
                editPath={editBankAccountHref}
                loading={loading}
            />
            <Pagination meta={bankAccounts?.meta} changePage={changePage} />
            <DeleteConfirmationModal
                title="Excluir conta bancária"
                description="Você tem certeza que deseja excluir essa conta bancária? 
        Essa operação não pode ser desfeita."
                open={!!accountToDelete}
                closeModal={closeUserDeleteModal}
                acceptButtonAction={onConfirmDelete}
                acceptButtonLabel="conta"
            />
        </div>
    );
};

export default BankAccountList;
