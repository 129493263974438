import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import stateOptions from '../../../common/data/states.json';
import { useBankAccounts } from '../../../common/hooks';
import { requiredFieldMessage, validEmailMessage } from '../../../common/utils/validation.utils';
import BankaccountForm from './BankAccountForm';
import { getYear } from '../../../common/utils/date.utils';
import { useSelector } from 'react-redux';
import { getUser } from '../../../common/store';

export const schema = yup
    .object({
        name: yup.string().trim().required(requiredFieldMessage()),
        document: yup.string().trim().required(requiredFieldMessage()),
        address: yup.object({
            cep: yup.string().trim().required(requiredFieldMessage()),
            street_name: yup.string().trim().required(requiredFieldMessage()),
            street_number: yup.string().trim().required(requiredFieldMessage()),
            complement: yup.string().trim(),
            neighborhood: yup.string().trim().required(requiredFieldMessage()),
            state: yup.object().shape({
                value: yup.string().trim().required(requiredFieldMessage()),
            }),
            city: yup.string().trim().required(requiredFieldMessage()),
            country: yup.object().shape({
                value: yup.string().trim().required(requiredFieldMessage()),
            }),
        }),
        owner: yup.object({
            full_name: yup.string().trim().required(requiredFieldMessage()),
            first_name: yup.string().trim().required(requiredFieldMessage()),
            last_name: yup.string().trim().required(requiredFieldMessage()),
            email: yup.string().trim().email(validEmailMessage()).required(requiredFieldMessage()),
            phone: yup.string().trim().required(requiredFieldMessage()),
            cpf: yup.string().trim().required(requiredFieldMessage()),
            birth_date: yup.string().trim().required(requiredFieldMessage()),
            mother_name: yup.string().trim().required(requiredFieldMessage()),
        }),
        account: yup.object({
            bank_id: yup.object().shape({
                value: yup.string().trim().required(requiredFieldMessage()),
            }),
            type: yup.object().shape({
                value: yup.string().trim().required(requiredFieldMessage()),
            }),
            agency: yup.string().required(requiredFieldMessage()),
            agency_digit: yup.string(),
            number: yup.string().required(requiredFieldMessage()),
            number_digit: yup.string(),
        }),
        tos_accepted: yup.boolean().nullable(),
        tos_accepted_by: yup.string().nullable(),
    })
    .required(requiredFieldMessage());

const initialState = {
    name: '',
    document: '',
    address: {
        cep: '',
        street_name: '',
        street_number: '',
        complement: '',
        neighborhood: '',
        state: { value: '', label: '' },
        city: '',
        country: { value: 'BRA', label: 'Brasil' },
    },
    owner: {
        full_name: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        cpf: '',
        birth_date: '',
        mother_name: '',
    },
    account: {
        bank_id: { value: '', label: '' },
        type: { value: 'CC', label: 'Conta Corrente' },
        agency: '',
        agency_digit: '' as string | undefined,
        number: '',
        number_digit: '' as string | undefined,
    },
    tos_accepted: false,
    tos_accepted_by: undefined,
};

export const payloadFromSchema = (data: typeof initialState) => {
    // convert data from select-input format to api format
    const payload = {
        ...data,
        owner: {
            ...data.owner,
            birth_date: getYear(data.owner.birth_date),
        },
        account: {
            ...data.account,
            bank_name: data.account.bank_id.label,
            bank_id: data.account.bank_id.value,
            type: data.account.type.value,
        },
        address: {
            ...data.address,
            state: data.address.state.value,
            country: data.address.country.value,
        },
    };

    if (!payload.account.agency_digit) delete payload.account.agency_digit;
    if (!payload.account.number_digit) delete payload.account.number_digit;

    return payload;
};

interface Props {
    orgId?: string;
}

const CreateBankAccount: FC<Props> = ({ orgId }) => {
    const user = useSelector(getUser);
    // User is bank account owner if
    // A) partner editing partner account
    // B) admin editing admin account
    const isBankAccountOwner = user?.org?.id === orgId || (user?.org?.id === 'ADMIN' && !orgId);

    console.log({ user, isBankAccountOwner });

    const { createBankAccount, fetchAddressFromZipcode, loading, error } = useBankAccounts({
        orgId,
    });
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: initialState,
    });

    const full_name = watch('owner.full_name');

    useEffect(() => {
        const name = full_name?.trim()?.split(' ');
        if (name.length > 0) {
            setValue('owner.first_name', name[0]);
        }
        if (name.length > 1) {
            setValue('owner.last_name', name[name.length - 1]);
        }
        // eslint-disable-next-line
    }, [full_name]);

    const onSubmit = async (data: any) => {
        const payload = payloadFromSchema(data);

        try {
            await createBankAccount(payload);
            const path = orgId ? `/orgs/${orgId}/bank-accounts` : '/settings/bank-accounts';
            navigate(path);
        } catch (err) {
            // try again...
        }
    };

    const zipcode = watch('address.cep');

    const fetchAddressInfo = async () => {
        if (zipcode.length < 8) return;
        try {
            // console.log(zipcode);
            const zipcodeNumbers = zipcode.replace('-', '');
            const address = await fetchAddressFromZipcode(zipcodeNumbers);

            setValue('address.neighborhood', address.bairro);
            setValue('address.city', address.localidade);
            setValue('address.street_name', address.logradouro);

            const addressState = stateOptions.find((state) => state.value === address.uf);
            if (addressState) {
                setValue('address.state', addressState);
            }
        } catch (err) {}
    };

    useEffect(() => {
        fetchAddressInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipcode]);

    return (
        <>
            <BankaccountForm
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors} // client-side validation
                error={error} // server-side error
                loading={loading}
                displayTos={isBankAccountOwner}
                watch={watch}
            />
        </>
    );
};

export default CreateBankAccount;
