import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { AdminSettingsLayout } from '../../../../common/components';
import { useAdvertisingSegments } from '../../../../common/hooks';
import { payloadFromSchema, schema } from './CreateAdvertisingSegment';
import BankaccountForm from './AdvertisingSegmentForm';

interface Props {}

const createFormData = (data: any) => {
    return data;
};

const UpdateAdvertisingSegment: FC<Props> = () => {
    const { updateAdvertisingSegment, loading, error } = useAdvertisingSegments();
    const navigate = useNavigate();
    const location = useLocation();

    const advertisingSegmentData = location.state?.data;

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: useMemo(
            () => advertisingSegmentData && createFormData(advertisingSegmentData),
            [advertisingSegmentData],
        ),
    });

    useEffect(() => {
        if (advertisingSegmentData) {
            reset(createFormData(advertisingSegmentData));
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = async (data: any) => {
        const payload = payloadFromSchema(data);

        try {
            await updateAdvertisingSegment(advertisingSegmentData.id, payload);
            navigate('/settings/advertising-segment');
        } catch (err) {
            // try again...
        }
    };

    return (
        <AdminSettingsLayout title="Alterar segmento de publicidade">
            <BankaccountForm
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors} // client-side validation
                error={error} // server-side error
                loading={loading}
                isEdit={true}
            />
        </AdminSettingsLayout>
    );
};

export default UpdateAdvertisingSegment;
