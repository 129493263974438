import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { AdminLayout, AdminLayoutContentCard } from '../../../common/components';
import modelOptions from '../../../common/data/models.json';
import { useDevices } from '../../../common/hooks';
import { requiredFieldMessage } from '../../../common/utils/validation.utils';
import { payloadFromSchema } from './CreateDevice';
import DeviceForm from './DeviceForm';

const schema = yup
    .object({
        serial_number: yup.string().trim().required(requiredFieldMessage()),
        model: yup.object().shape({
            value: yup.string().trim().required(requiredFieldMessage()),
        }),
        battery_slot_quantity: yup.number().required(requiredFieldMessage()),
        org_id: yup.object().shape({
            value: yup.string().trim(),
        }),
    })
    .required(requiredFieldMessage());

const buildFormFromData = (data: Device): any => {
    const modelOption = modelOptions.find((model) => model.value === data.model);

    return {
        serial_number: data.serial_number,
        model: modelOption,
        battery_slot_quantity: data.battery_slot_quantity,
        org_id: { value: data.org?.id || '', label: data.org?.name || '' },
    };
};

const UpdateDevice = () => {
    const { updateDevice, loading, error } = useDevices();
    const navigate = useNavigate();
    const location = useLocation();

    // 1: get device data from navigation
    const deviceData = location.state?.data;

    // 2: fill form data with the device data
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: useMemo(() => buildFormFromData(deviceData), [deviceData]),
    });

    useEffect(() => {
        reset(buildFormFromData(deviceData));
        // eslint-disable-next-line
    }, []);

    const onSubmit = async (data: any) => {
        const payload = payloadFromSchema(data);

        try {
            await updateDevice(deviceData.id, payload);
            navigate('/devices');
        } catch (err) {
            // try again...
        }
    };

    return (
        <AdminLayout title="Alterar máquina">
            <AdminLayoutContentCard>
                <DeviceForm
                    control={control}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    errors={errors} // client-side validation
                    error={error} // server-side error
                    loading={loading}
                    isEdit={true}
                />
            </AdminLayoutContentCard>
        </AdminLayout>
    );
};

export default UpdateDevice;
