import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';
import fileDownload from 'js-file-download';

interface Options {
    fetchData?: boolean;
    fetchOptions?: boolean;
    filters?: any;
    partnerId?: string;
}

type SearchFilters =
    | 'org_id'
    | 'bank_account_id'
    | 'location_id'
    | 'device_search'
    | 'battery_search'
    | 'payment_type';

type FetchTransactionsParams = FetchParams & {
    status?: TransactionStatus;
    date_from?: any;
    date_to?: any;
    search_filters?: {
        [K in SearchFilters]?: string;
    };
};

const useTransactions = (
    { fetchData = false, fetchOptions = false, filters, partnerId }: Options = {} as any,
) => {
    const [transactions, setTransactions] = useState<{ meta: any; data: any[] }>();
    const { get, loading, error } = useRequests();

    const fetchTransactions = async (queryParams: FetchTransactionsParams = {}) => {
        const route = '/admin/transactions';
        const queryString = buildTransactionsQueryString(route, queryParams);

        try {
            const response = await get(queryString);
            const data = response?.data;
            setTransactions(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const downloadTransactions = async (queryParams: FetchTransactionsParams = {}) => {
        const route = '/admin/transactions/csv';
        const queryString = buildTransactionsQueryString(route, queryParams);

        try {
            const response = await get(queryString, { responseType: 'blob' });

            // generate query id
            const { search_filters, ...query } = queryParams;
            const query_id = { ...query, ...search_filters };
            const query_id_string = Object.values(query_id).filter((item) => !!item);
            const id = query_id_string.join('_') || 'ALL';

            // generate query filename
            const filename = `transactions_${Date.now()}_${id}.xlsx`;

            fileDownload(response.data, filename);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const buildTransactionsQueryString = (
        route: string,
        queryParams: FetchTransactionsParams = {},
    ) => {
        let queryString = `${route}?`;

        // add relationship filters
        for (const [key, value] of Object.entries(filters || {})) {
            queryString += `${key}=${value}&`;
        }

        // add query filter key/values
        const queryKeys: (keyof FetchTransactionsParams)[] = [
            'page',
            'status',
            'date_from',
            'date_to',
        ];

        for (const queryKey of queryKeys) {
            const queryValue = queryParams[queryKey];
            if (queryValue) {
                queryString += `${queryKey}=${queryValue}&`;
            }
        }

        const org_id_filter = partnerId || queryParams.search_filters?.org_id;
        if (org_id_filter) {
            queryString += `org_id=${org_id_filter}&`;
        }

        // add query search filter key/values
        const searchFilterKeys: SearchFilters[] = [
            'bank_account_id',
            'location_id',
            'device_search',
            'battery_search',
            'payment_type',
        ];

        for (const searchKey of searchFilterKeys) {
            const searchValue = queryParams.search_filters?.[searchKey];
            if (searchValue) {
                queryString += `${searchKey}=${searchValue}&`;
            }
        }

        return queryString;
    };

    const [orgOptions, setOrgOptions] = useState<SelectOption[]>([]);
    const [bankAccountOptions, setBankAccountOptions] = useState<any[]>([]);
    const [locationOptions, setLocationOptions] = useState<any[]>([]);

    const fetchOrgOptions = async () => {
        if (partnerId) return;
        let route = '/admin/orgs?perPage=9999&';

        try {
            const response = await get(route);
            const data = response?.data?.data;
            const options = data?.map((org: any) => ({
                value: org.id,
                label: org.name,
            }));
            setOrgOptions(options || []);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const fetchBankAccountOptions = async () => {
        let route = `/admin/bank-accounts/system?`; // endpoint for listing all bank-accounts system-wide
        if (partnerId) route += `orgId=${partnerId}`; // limit to active/selected partner

        try {
            const response = await get(route);
            const data = response?.data;

            const options = buildOptionsGroupedByOrg(data);
            // const options = data?.map((bankAccount: any) => ({
            //     value: bankAccount.id,
            //     label: bankAccount.name,
            // }));

            setBankAccountOptions(options || []);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const fetchLocationOptions = async () => {
        let route = '/partner/locations?perPage=9999&';
        if (partnerId) route += `orgId=${partnerId}`; // limit to active/selected partner

        try {
            const response = await get(route);
            const data = response?.data?.data;

            const options = buildOptionsGroupedByOrg(data);
            // const options = data?.map((location: any) => ({
            //     value: location.id,
            //     label: location.name,
            // }));

            setLocationOptions(options || []);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const buildOptionsGroupedByOrg = (data: any) => {
        // create map of org { id => name }
        const orgNameById: any = {};

        // group items by org
        const byOrg: any = {};
        for (const item of data) {
            if (!byOrg[item.org_id]) {
                byOrg[item.org_id] = [];
            }

            byOrg[item.org_id].push({ value: item.id, label: item.name });
            orgNameById[item.org_id] = item.org?.name || partnerId || item.org_id;
        }

        // create react-select grouped options
        const optionGroups = [];
        for (const orgId of Object.keys(byOrg)) {
            optionGroups.push({
                label: orgNameById[orgId] || partnerId || orgId,
                options: byOrg[orgId],
            });
        }

        return optionGroups;
    };

    const fetchTransactionData = async () => {
        if (fetchData) {
            fetchTransactions();
        }

        if (fetchOptions) {
            fetchOrgOptions();
            fetchBankAccountOptions();
            fetchLocationOptions();
        }
    };

    useEffect(() => {
        fetchTransactionData();

        // eslint-disable-next-line
    }, [filters]);

    return {
        transactions,
        orgOptions,
        bankAccountOptions,
        locationOptions,
        fetchTransactions,
        downloadTransactions,
        error,
        loading,
    };
};

export default useTransactions;
