import { FC } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import { NumericFormat, PatternFormat } from 'react-number-format';
import InputContainer from './InputContainer';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    name: string;
    label?: string;
    type?: string;
    required?: boolean;
    className?: string;
    control: Control<any>;
    error?: FieldError;
    tooltip?: string;
    currency?: boolean;
    format?: string;
    useFloatValue?: boolean;
    disabled?: boolean;
    mask?: string;
};

const NumberInput: FC<Props> = ({
    name,
    label,
    required = false,
    className = '',
    control,
    error,
    tooltip,
    currency = false,
    format,
    useFloatValue = false,
    disabled = false,
    mask = '_',
}) => {
    const currencyprops = currency
        ? {
              prefix: 'R$ ',
              decimalScale: 2,
              fixedDecimalScale: true,
              decimalSeparator: ',',
          }
        : {};

    const formatProps: any = format ? { format, allowEmptyFormatting: true, mask } : {};
    const Component = format ? PatternFormat : NumericFormat;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, ref, ...field } }) => (
                <InputContainer
                    name={name}
                    label={label}
                    className={className}
                    error={error}
                    tooltip={tooltip}
                >
                    <Component
                        disabled={disabled}
                        type="text"
                        {...currencyprops}
                        {...formatProps}
                        id={name}
                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-400 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:text-gray-500 "
                        required={required}
                        {...field}
                        onValueChange={({ value, floatValue }: any) =>
                            onChange(useFloatValue ? floatValue : value)
                        }
                        getInputRef={ref}
                    />
                </InputContainer>
            )}
        />
    );
};

export default NumberInput;
