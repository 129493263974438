import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';

const useScreenAudio = (fetchData = false) => {
    const [screenAudio, setScreenAudio] = useState<any>({});
    const { get, patch, loading, error } = useRequests();

    const fetchScreenAudio = async () => {
        const route = '/admin/screen-audios';

        try {
            const response = await get(route);
            const data = response?.data?.data?.[0];
            setScreenAudio(data || {});
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const updateScreenAudio = async (field: string, file: any) => {
        if (!screenAudio.id) return;
        const route = `/admin/screen-audios/${screenAudio.id}`;

        // to upload file
        const formData = new FormData();
        formData.append(field, file);

        try {
            const response = await patch(route, formData);
            const _screenAudio = response?.data;
            setScreenAudio(_screenAudio || {});
            return _screenAudio;
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    useEffect(() => {
        if (fetchData) {
            fetchScreenAudio();
        }
        // eslint-disable-next-line
    }, []);

    return {
        screenAudio,
        updateScreenAudio,
        error,
        loading,
    };
};

export default useScreenAudio;
