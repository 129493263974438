const colors = {
    layout: {
        background: '#F9FAFB',
    },
    accent: {
        purple: '#7210FF',
    },
};

export default colors;
