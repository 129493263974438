import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';

const useScreenBack = (fetchData = false) => {
    const [screenBack, setScreenBack] = useState<any>({});
    const { get, patch, loading, error } = useRequests();

    const fetchScreenBack = async () => {
        const route = '/admin/screen-backs';

        try {
            const response = await get(route);
            const data = response?.data?.data?.[0];
            setScreenBack(data || {});
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const updateScreenBackFile = async (field: string, file: any) => {
        // to upload file
        const formData = new FormData();
        formData.append(field, file);
        return updateScreenBack(formData);
    };

    const updateScreenBack = async (payload: any) => {
        if (!screenBack.id) return;
        const route = `/admin/screen-backs/${screenBack.id}`;

        try {
            const response = await patch(route, payload);
            const _screenBack = response?.data;
            setScreenBack(_screenBack || {});
            return _screenBack;
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    useEffect(() => {
        if (fetchData) {
            fetchScreenBack();
        }
        // eslint-disable-next-line
    }, []);

    return {
        screenBack,
        updateScreenBackFile,
        updateScreenBack,
        error,
        loading,
    };
};

export default useScreenBack;
