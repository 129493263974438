import { useState } from 'react';
import { useRequests } from '.';

const usePagbank = () => {
    const { get, loading } = useRequests();

    const [errorMessage, setErrorMessage] = useState('');

    const fetchPagbankConnectUrl = async (bankAccountId: string) => {
        setErrorMessage('');

        let route = `/admin/bank-accounts/${bankAccountId}/pagbank-connect`;

        try {
            const response = await get(route);
            return response?.data;
        } catch (err: any) {
            setErrorMessage(err?.response?.data?.message || err?.message);
        }
    };

    const forceCreatePagbank = async (bankAccountId: string) => {
        setErrorMessage('');

        let route = `/admin/bank-accounts/${bankAccountId}/pagbank-create`;

        try {
            const response = await get(route);
            return response?.data;
        } catch (err: any) {
            setErrorMessage(err?.response?.data?.message || err?.message);
        }
    };

    return {
        fetchPagbankConnectUrl,
        forceCreatePagbank,
        errorMessage,
        loading,
    };
};

export default usePagbank;
