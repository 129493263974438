import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { FC, useMemo } from 'react';

interface Meta {
    total: number;
    lastPage: number;
    currentPage: number;
    perPage: number;
    prev: string | null;
    next: string | null;
}

interface Props {
    meta?: Meta;
    changePage: (page: number) => void;
}

const buildPageList = (meta?: Meta) => {
    if (!meta) return [1];

    const lastPage = Math.ceil(meta?.total / meta?.perPage);

    let pages = Array.from(
        new Set([
            1,
            Math.round((1 + meta?.currentPage) / 2),
            meta?.currentPage - 1,
            meta?.currentPage,
            meta?.currentPage + 1,
            Math.round((lastPage + meta?.currentPage) / 2),
            lastPage,
        ]),
    );

    pages = pages.filter((item) => {
        const isLessThanMin = item < 1;
        const isMoreThanMax = item > lastPage;
        if (isLessThanMin || isMoreThanMax) return false;
        return true;
    });

    if (pages[1] && pages[1] > pages[0] + 1) {
        // pages.splice(1, 0, -1);
    }

    if (pages[pages.length - 2] && pages[pages.length - 2] + 1 < pages[pages.length - 1]) {
        // pages.splice(pages.length - 1, 0, -2);
    }

    return pages;
};

const Pagination: FC<Props> = ({ meta, changePage }) => {
    const start = meta && (meta.currentPage - 1) * meta.perPage + 1;
    const end = start && Math.min(start + meta.perPage - 1, meta.total);

    const currentPage = meta ? meta?.currentPage : 1;
    const pages = useMemo(() => buildPageList(meta), [meta]);

    const goToPreviousPage = () => {
        if (currentPage > 1) changePage(currentPage - 1);
    };

    const goToNextPage = () => {
        if (currentPage < pages[pages.length - 1]) changePage(currentPage + 1);
    };

    const rangeLabel = (meta?.total || 0) > 0 ? `${start}-${end} ` : '0 ';

    return (
        <div className="flex justify-between items-center pt-5 bg-white border-t border-gray-300">
            <div className="flex flex-wrap flex-1 gap-2 justify-between items-center">
                <div>
                    {meta && (
                        <p className="text-sm text-gray-700">
                            Mostrando <span className="font-bold">{rangeLabel}</span>
                            de <span className="font-bold">{meta?.total}</span>
                        </p>
                    )}
                </div>
                <div>
                    <nav
                        className="inline-flex isolate -space-x-px rounded-md shadow-sm"
                        aria-label="Pagination"
                    >
                        {!!pages.length && (
                            <div
                                onClick={goToPreviousPage}
                                className="inline-flex relative items-center px-2 py-2 text-gray-400 rounded-l-md ring-1 ring-inset ring-gray-300 cursor-pointer hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                                <span className="sr-only">Previous</span>
                                <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                            </div>
                        )}
                        {pages.map((page) =>
                            page >= 0 ? (
                                <div
                                    //   TODO: add pagination link
                                    key={page}
                                    aria-current={currentPage === page && 'page'}
                                    onClick={() => changePage(page)}
                                    className={
                                        currentPage === page
                                            ? 'select-none relative z-10 inline-flex items-center bg-accent-400 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-400'
                                            : 'cursor-pointer inline-flex relative items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                    }
                                >
                                    {page}
                                </div>
                            ) : (
                                <span
                                    key={page} // negative number
                                    className="inline-flex relative items-center px-4 py-2 text-sm font-semibold text-gray-400 bg-gray-100 ring-1 ring-inset ring-gray-300 select-none focus:outline-offset-0"
                                >
                                    ...
                                </span>
                            ),
                        )}

                        {!!pages.length && (
                            <div
                                onClick={goToNextPage}
                                className="inline-flex relative items-center px-2 py-2 text-gray-400 rounded-r-md ring-1 ring-inset ring-gray-300 cursor-pointer hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                            </div>
                        )}
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Pagination;
