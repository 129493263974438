import { Ring } from '@uiball/loaders';
import clsx from 'clsx';
import { Tooltip } from 'flowbite-react';
import get from 'lodash.get';
import { FC } from 'react';
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
} from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import SearchBarInput from '../SearchBarInput';
import { buildLogState, buildLogUrl } from './Table';

type Header = {
    label: string;
    path: string;
    Component?: FC<{ value: string; item: any }>;
    displayTitle?: boolean;
    containerClassName?: string;
    defaultValue?: any;
};

interface Props {
    title?: string;
    headers: Header[];
    data: any[];
    onClickView?: (item: any) => void;
    deleteButtonLabel?: string;
    onClickDelete?: any;
    editPath?: string;
    titleButtonLabel?: string;
    titleButtonHref?: string;
    className?: string;
    actionButtonLabel?: string;
    onClickAction?: (item: any) => void;
    searchBarProps?: any;
    valueClassName?: string;
    actionSecondaryButtonLabel?: (a: any) => string | string;
    onClickActionSecondary?: (item: any) => void;
    deleteCondition?: (item: any) => boolean;
    loading?: boolean;
    logKey?: string;
    logKeyPath?: string;
    logTitle?: (item: any) => string;
    onReorderAction: (itemId: string, position: number) => void;
}

const TableWithReordering: FC<Props> = ({
    title,
    headers,
    data,
    onClickView,
    deleteButtonLabel,
    onClickDelete,
    editPath,
    titleButtonLabel,
    titleButtonHref,
    className,
    searchBarProps,
    valueClassName,
    actionButtonLabel,
    onClickAction,
    actionSecondaryButtonLabel,
    onClickActionSecondary,
    deleteCondition,
    loading,
    logKey,
    logKeyPath,
    logTitle,
    onReorderAction,
}) => {
    const hasHeader = title || titleButtonHref;
    const hasActionButtons = onClickView || editPath || onClickDelete || onClickAction || logKey;
    const reorderActionProps = onReorderAction
        ? {
              callback: onReorderAction,
              minPosition: data.map((item) => item.position).reduce((a, b) => (a < b ? a : b), 999),
              maxPosition: data.map((item) => item.position).reduce((a, b) => (a > b ? a : b), 0),
          }
        : {};

    // extracting these functions into new components would add too much boilerplate code
    const renderHeader = () => {
        if (!hasHeader) return <></>;
        return (
            <div className="mb-8 sm:flex sm:items-center sm:mb-4">
                {title}
                <div className="sm:flex sm:flex-auto sm:items-end">
                    {searchBarProps && <SearchBarInput {...searchBarProps} className="" />}
                    {title && <h1 className="text-xl font-semibold text-gray-900">{title}</h1>}
                    {titleButtonHref && (
                        <div className="flex flex-grow content-end items-end">
                            <Link
                                className="ml-auto w-max flex justify-center rounded-md bg-accent-400 px-8 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-accent-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-400 disabled:opacity-50 disabled:pointer-events-none max-h-[36px]"
                                to={titleButtonHref}
                            >
                                {titleButtonLabel}
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const onDragEnd = (data: any) => {
        if (
            data.draggableId &&
            data.destination.index !== undefined &&
            data.destination.index !== data?.source?.index
        ) {
            // console.log('ON DRAG END', data);
            onReorderAction(data?.draggableId, data.destination.index);
        }
    };

    const renderTableBody = () => {
        return (
            <Droppable droppableId="table">
                {(droppableProvided: DroppableProvided) => (
                    <tbody
                        className="bg-white"
                        ref={(ref: any) => {
                            droppableProvided.innerRef(ref);
                        }}
                        {...droppableProvided.droppableProps}
                    >
                        {data.map((item, index) => (
                            <Draggable draggableId={item.id} index={index} key={item.id}>
                                {(
                                    provided: DraggableProvided,
                                    snapshot: DraggableStateSnapshot,
                                ) => (
                                    <tr
                                        key={item.id || get(item, headers[0].path)}
                                        className={clsx(
                                            'border-t border-gray-300',
                                            snapshot.isDragging && `bg-gray-50`,
                                        )}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        {headers.map(
                                            (
                                                {
                                                    path,
                                                    Component,
                                                    displayTitle,
                                                    containerClassName = '',
                                                    defaultValue,
                                                },
                                                index,
                                            ) => (
                                                <td
                                                    key={`${path}_${get(item, path)}`}
                                                    className={clsx(
                                                        `overflow-y-hidden py-4 pr-3 text-sm text-gray-500 whitespace-nowrap leading-5 first:py-4 first:pr-3 first:font-medium first:text-gray-900 max-w-[400px] [&>*:first-child]:w-full`,
                                                        '[&:nth-child(2)]:text-gray-900 [&:nth-child(2)]:font-medium',
                                                        valueClassName,
                                                        containerClassName,
                                                    )}
                                                >
                                                    <HasTooltip
                                                        tooltip={displayTitle && get(item, path)}
                                                    >
                                                        <div className="truncate">
                                                            {Component ? (
                                                                <Component
                                                                    value={
                                                                        get(item, path) ??
                                                                        defaultValue
                                                                    }
                                                                    item={item}
                                                                    {...(index === 0
                                                                        ? reorderActionProps
                                                                        : {})}
                                                                />
                                                            ) : (
                                                                get(item, path) ||
                                                                defaultValue ||
                                                                '-'
                                                            )}
                                                        </div>
                                                    </HasTooltip>
                                                </td>
                                            ),
                                        )}
                                        {hasActionButtons && (
                                            <td
                                                className={clsx(
                                                    'relative py-4 pl-3 text-sm font-medium text-right whitespace-nowrap w-[180px]',
                                                    valueClassName,
                                                )}
                                            >
                                                {onClickAction && (
                                                    <span
                                                        onClick={() => onClickAction(item)}
                                                        className="cursor-pointer text-accent-400 hover:text-accent-600"
                                                    >
                                                        {actionButtonLabel}
                                                        <span className="sr-only">
                                                            , {item.name}
                                                        </span>
                                                    </span>
                                                )}
                                                {onClickActionSecondary && (
                                                    <span
                                                        onClick={() => onClickActionSecondary(item)}
                                                        className="block cursor-pointer text-accent-400 hover:text-accent-600 w-[90px] ml-3"
                                                    >
                                                        {actionSecondaryButtonLabel &&
                                                            (typeof actionSecondaryButtonLabel ===
                                                            'string'
                                                                ? actionSecondaryButtonLabel
                                                                : actionSecondaryButtonLabel(item))}
                                                        <span className="sr-only">
                                                            , {item.name}
                                                        </span>
                                                    </span>
                                                )}
                                                {onClickView && (
                                                    <span
                                                        onClick={() => onClickView(item)}
                                                        className="cursor-pointer text-accent-400 hover:text-accent-600"
                                                    >
                                                        Ver
                                                        <span className="sr-only">
                                                            , {item.name}
                                                        </span>
                                                    </span>
                                                )}

                                                {editPath && (
                                                    <Link
                                                        to={editPath}
                                                        className="ml-8 text-accent-400 hover:text-accent-600"
                                                        state={{ data: item }}
                                                    >
                                                        Alterar
                                                        <span className="sr-only">
                                                            , {item.name}
                                                        </span>
                                                    </Link>
                                                )}

                                                {onClickDelete &&
                                                    (!deleteCondition || deleteCondition(item)) && (
                                                        <span
                                                            onClick={() => onClickDelete(item)}
                                                            className="ml-8 text-red-500 cursor-pointer hover:text-red-800"
                                                        >
                                                            {deleteButtonLabel || 'Excluir'}
                                                            <span className="sr-only">
                                                                , {item.name}
                                                            </span>
                                                        </span>
                                                    )}

                                                {logKey && (
                                                    <Link
                                                        to={buildLogUrl(item, {
                                                            logKey,
                                                            logKeyPath,
                                                            logTitle,
                                                        })}
                                                        className="ml-8 text-accent-400 hover:text-accent-600"
                                                        state={buildLogState(item, {
                                                            logKey,
                                                            logKeyPath,
                                                            logTitle,
                                                        })}
                                                    >
                                                        Logs
                                                        <span className="sr-only">
                                                            , {item.name}
                                                        </span>
                                                    </Link>
                                                )}
                                            </td>
                                        )}
                                    </tr>
                                )}
                            </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                    </tbody>
                )}
            </Droppable>
        );
    };

    const renderLoading = () => {
        if (!loading) return <></>;
        return (
            <div className="flex absolute top-0 left-0 justify-center items-center w-full h-full bg-white bg-opacity-50">
                <Ring size={40} lineWeight={5} speed={2} color="black" />
            </div>
        );
    };

    const renderTable = () => {
        return (
            <div className="flex relative flex-col flex-grow">
                <div className="-my-2 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block py-2 min-w-full align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full table-fixed">
                                <thead className="border-t border-gray-300">
                                    <tr>
                                        {headers.map(({ label }) => (
                                            <th
                                                key={label}
                                                scope="col"
                                                className="pr-3 py-3.5 text-left text-sm font-bold text-gray-900 first:sm:pr-6 first:py-3.5 first:pr-3"
                                            >
                                                {label}
                                            </th>
                                        ))}
                                        {hasActionButtons && (
                                            <th
                                                scope="col"
                                                className="w-24 relative py-3.5 pl-3 pr-4 sm:pr-6"
                                            >
                                                <span className="sr-only">Editar</span>
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                {renderTableBody()}
                            </table>
                        </div>
                    </div>
                </div>
                {renderLoading()}
            </div>
        );
    };

    return (
        <div className={`flex relative flex-col flex-grow ${className}`}>
            <DragDropContext onDragEnd={onDragEnd}>
                {renderHeader()}
                {renderTable()}
            </DragDropContext>
        </div>
    );
};

export default TableWithReordering;

const HasTooltip: FC<any> = ({ children, tooltip }) => {
    if (!tooltip) return <>{children}</>;
    return <Tooltip content={tooltip}>{children}</Tooltip>;
};
