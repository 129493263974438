import { AdminSettingsLayout } from '../../../common/components';
import BankAccountList from '../../common/bank-accounts/BankAccountList';

const AdminBankAccountList = () => {
    return (
        <AdminSettingsLayout title="Contas Bancárias">
            <BankAccountList />
        </AdminSettingsLayout>
    );
};

export default AdminBankAccountList;
