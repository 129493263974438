import { FC, useEffect, useMemo, useState } from 'react';
import {
    AccentButton,
    AdminLayout,
    AdminLayoutContentCard,
    BaseInput,
    BaseSelectInput,
    BasicButton,
    Pagination,
    Table,
} from '../../../common/components';
import { useCommandLogs } from '../../../common/hooks';
import { formatDate } from '../../../common/utils/date.utils';
import {
    CLCommandStatusPillComponent,
    CLLogStatusPillComponent,
} from '../../../common/components/StatusPillComponent';
import LogDetailsModal from '../logs/LogDetailsModal';

export const DateTimeSecondsParserComponent: FC<{ value: string }> = ({ value }) => {
    const date = useMemo(() => {
        return formatDate(value, true) || '-';
    }, [value]);

    return <>{date}</>;
};

const headers = [
    { label: 'Bateria', path: 'battery_serial_number' },
    { label: 'Status do comando', path: 'command_status', Component: CLCommandStatusPillComponent },
    { label: 'Status do log', path: 'log_status', Component: CLLogStatusPillComponent },
    // { label: 'Detalhes', path: 'details' },
    // { label: 'Pedido', path: 'rent_id' },
    { label: 'Máquina', path: 'device.serial_number' },
    { label: 'Criado', path: 'created_at', Component: DateTimeSecondsParserComponent },
    { label: 'Verificado', path: 'verified_at', Component: DateTimeSecondsParserComponent },
];

const logStatusOptions = [
    { label: 'Todos', value: '' },
    { label: 'Ok', value: 'OK' },
    { label: 'Alerta', value: 'WARNING' },
];

const CommandLogList = () => {
    const title = 'Logs de Comando';

    const { logs, fetchCommandLogs, loading } = useCommandLogs({
        fetchData: true,
    });

    const [logStatus, setLogStatus] = useState(logStatusOptions[0]);
    const [batterySearch, setBatterySearch] = useState('');
    // const [dateRange, setDateRange] = useState<any>(defaultDateRange);
    const [trigger, setTrigger] = useState(false);

    const clearFilters = () => {
        // basic filters
        setLogStatus(logStatusOptions[0]);
        setBatterySearch('');
        // setDateRange(defaultDateRange);

        // trigger to re-fetch logs
        setTrigger(true);
    };

    const submitFilters = (page?: any) => {
        const search_filters = {
            battery_serial_number: batterySearch,
            log_status: logStatus.value,
        };

        fetchCommandLogs({
            search_filters,
            page,
        });
    };

    const changePage = (page: number | string) => {
        if (page !== logs?.meta.currentPage) {
            submitFilters(page);
        }
    };

    useEffect(() => {
        if (trigger) {
            setTrigger(false);
            submitFilters();
        }
        // eslint-disable-next-line
    }, [trigger]);

    const [detailsModalOpen, setDetailsModalOpen] = useState(false);
    const [detailsModal, setDetailsModal] = useState();

    const openDetailsModal = (details: any) => {
        setDetailsModal(details);
        setDetailsModalOpen(true);
    };

    const closeDetailsModal = () => {
        setDetailsModalOpen(false);
        setTimeout(() => setDetailsModal(undefined), 300);
    };

    return (
        <AdminLayout title={title}>
            <AdminLayoutContentCard>
                <div className="flex flex-row flex-wrap gap-y-2 gap-x-4 items-end">
                    <BaseSelectInput
                        name="Status do Log"
                        options={logStatusOptions}
                        className="!max-w-[160px]"
                        value={logStatus}
                        onChange={setLogStatus}
                    />
                    <BaseInput
                        name="battery"
                        label="Bateria"
                        value={batterySearch}
                        onChange={(e: any) => setBatterySearch(e.target.value)}
                        className="!max-w-[220px]"
                        inputClassName="h-[38px]"
                    />

                    <div>
                        <BasicButton
                            // className="h-[36px] border-0 !shadow-none px-6"
                            className="h-[38px] !shadow-none px-6"
                            onClick={clearFilters}
                        >
                            Limpar
                        </BasicButton>
                    </div>
                    <div>
                        <AccentButton className="h-[38px] px-6" onClick={submitFilters}>
                            Filtrar
                        </AccentButton>
                    </div>
                </div>

                <Table
                    headers={headers}
                    data={logs?.data || []}
                    className="mt-4"
                    valueClassName="!py-2"
                    actionButtonLabel="Detalhes"
                    onClickAction={openDetailsModal}
                    loading={loading}
                />
                <Pagination meta={logs?.meta} changePage={changePage} />
            </AdminLayoutContentCard>
            <LogDetailsModal
                open={detailsModalOpen}
                closeModal={closeDetailsModal}
                details={detailsModal}
            />
        </AdminLayout>
    );
};

export default CommandLogList;
