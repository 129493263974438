import { FC, ReactNode } from 'react';

import {
    BanknotesIcon,
    PhotoIcon,
    PuzzlePieceIcon,
    QuestionMarkCircleIcon,
    SpeakerWaveIcon,
    UsersIcon,
    ArchiveBoxIcon,
} from '@heroicons/react/24/outline';
import AdminLayout from './AdminLayout';
import SidebarNavigation from './SidebarNavigation';

interface PageContent {
    title?: string;
    children?: ReactNode;
}

const navigation = [
    { name: 'Administradores', href: '/settings/users', icon: UsersIcon },
    {
        name: 'Contas bancárias',
        href: '/settings/bank-accounts',
        icon: BanknotesIcon,
    },
    {
        name: 'Ajuda (FAQ)',
        href: '/settings/faq',
        icon: QuestionMarkCircleIcon,
    },
    {
        name: 'Padrões de publicidade',
        href: '/settings/screen-plan',
        icon: PhotoIcon,
    },
    {
        name: 'Padrões de aluguel',
        href: '/settings/screen-rent',
        icon: PhotoIcon,
    },
    {
        name: 'Padrões de devolução',
        href: '/settings/screen-return',
        icon: PhotoIcon,
    },
    {
        name: 'Padrões de áudio',
        href: '/settings/screen-audio',
        icon: SpeakerWaveIcon,
    },
    {
        name: 'Segmentos de publicidade',
        href: '/settings/advertising-segment',
        icon: PuzzlePieceIcon,
    },
    {
        name: 'Logs de comando',
        href: '/command-logs',
        icon: ArchiveBoxIcon,
    },
];

const AdminSettingsLayout: FC<PageContent> = ({ children, title }) => {
    return (
        <AdminLayout title={title} navigation={navigation}>
            <div className="flex gap-8">
                <SidebarNavigation navigation={navigation} />
                <div className="flex overflow-y-auto flex-col flex-grow p-8 max-w-full bg-white rounded-lg">
                    {children}
                </div>
            </div>
        </AdminLayout>
    );
};

export default AdminSettingsLayout;
