import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';

interface Options {
    fetchData?: boolean;
    filters?: any;
}

type SearchFilters =
    | 'user_search'
    | 'location_search'
    | 'org_search'
    | 'device_search'
    | 'battery_search';

type FetchLogsParams = FetchParams & {
    level?: LogLevel;
    date_from?: any;
    date_to?: any;
    search_filters?: {
        [K in SearchFilters]: string;
    };
};

const useLogs = ({ fetchData = false, filters }: Options = {} as any) => {
    const [logs, setLogs] = useState<{ meta: any; data: any[] }>();
    const { get, loading, error } = useRequests();

    const fetchLogs = async (queryParams: FetchLogsParams = {}) => {
        let route = '/admin/logs?';

        // add relationship filters
        for (const [key, value] of Object.entries(filters || {})) {
            route += `${key}=${value}&`;
        }

        // add query filter key/values
        const queryKeys: (keyof FetchLogsParams)[] = ['page', 'level', 'date_from', 'date_to'];

        for (const queryKey of queryKeys) {
            const queryValue = queryParams[queryKey];
            if (queryValue) {
                route += `${queryKey}=${queryValue}&`;
            }
        }

        // add query search filter key/values
        const searchFilterKeys: SearchFilters[] = [
            'user_search',
            'location_search',
            'org_search',
            'device_search',
            'battery_search',
        ];

        for (const searchKey of searchFilterKeys) {
            const searchValue = queryParams.search_filters?.[searchKey];
            if (searchValue) {
                route += `${searchKey}=${searchValue}&`;
            }
        }

        try {
            const response = await get(route);
            const data = response?.data;
            setLogs(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    useEffect(() => {
        if (fetchData) {
            fetchLogs();
        }
        // eslint-disable-next-line
    }, [filters]);

    return {
        logs,
        fetchLogs,
        error,
        loading,
    };
};

export default useLogs;
