import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import accountTypeOptions from '../../../common/data/account-types.json';
import bankOptions from '../../../common/data/banks.json';
import countryOptions from '../../../common/data/countries.json';
import stateOptions from '../../../common/data/states.json';
import { useBankAccounts } from '../../../common/hooks';
import BankaccountForm from './BankAccountForm';
import { payloadFromSchema, schema } from './CreateBankAccount';
import { useSelector } from 'react-redux';
import { getUser } from '../../../common/store';
import { isoDateToBrl } from '../../../common/utils/date.utils';

interface Props {
    orgId?: string;
}

const createFormData = (data: any) => {
    const bankOption = bankOptions.find((bank) => bank.value === Number(data.account.bank_id));
    const stateOption = stateOptions.find((state) => state.value === data.address.state);
    const countryOption = countryOptions.find((country) => country.value === data.address.country);
    const typeOption = accountTypeOptions.find((type) => type.value === data.account.type);

    return {
        ...data,
        address: {
            ...data.address,
            // cep: Number(data.address.cep),
            state: stateOption,
            country: countryOption,
        },
        account: {
            ...data.account,
            bank_id: bankOption,
            type: typeOption,
            number_digit: data.account.number_digit || '',
            agency_digit: data.account.agency_digit || '',
        },
        owner: {
            ...data.owner,
            full_name: data.owner.full_name || '',
            birth_date: isoDateToBrl(data.owner.birth_date),
        },
        tos_accepted: !!data.tos_accepted_by,
        tos_accepted_by: data.tos_accepted_by,
    };
};

const UpdateBankAccount: FC<Props> = ({ orgId }) => {
    const user = useSelector(getUser);
    const isBankAccountOwner = user?.org?.id === orgId || (user?.org?.id === 'ADMIN' && !orgId);

    const { updateBankAccount, fetchAddressFromZipcode, loading, error } = useBankAccounts({
        orgId,
    });
    const navigate = useNavigate();
    const location = useLocation();

    const bankAccountData = location.state?.data;

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: useMemo(
            () => bankAccountData && createFormData(bankAccountData),
            [bankAccountData],
        ),
    });

    const full_name = watch('owner.full_name');

    useEffect(() => {
        const name = full_name?.trim()?.split(' ');
        if (name.length > 0) {
            setValue('owner.first_name', name[0]);
        }
        if (name.length > 1) {
            setValue('owner.last_name', name[name.length - 1]);
        }
        // eslint-disable-next-line
    }, [full_name]);

    useEffect(() => {
        if (bankAccountData) {
            reset(createFormData(bankAccountData));
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = async (data: any) => {
        const payload = payloadFromSchema(data);

        try {
            await updateBankAccount(bankAccountData.id, payload);
            const path = orgId ? `/orgs/${orgId}/bank-accounts` : '/settings/bank-accounts';
            navigate(path);
        } catch (err) {
            // try again...
        }
    };

    const zipcode = watch('address.cep');
    const lastZipcode = useRef(-1);

    const fetchAddressInfoFromZipcode = async () => {
        if (lastZipcode.current === -1 && !!zipcode) {
            lastZipcode.current = zipcode;
        }

        if (zipcode !== lastZipcode.current) {
            await fetchAddressInfo();
        }

        if (lastZipcode.current !== -1) {
            lastZipcode.current = zipcode;
        }
    };

    const fetchAddressInfo = async () => {
        if (zipcode.length < 8) return;
        try {
            const zipcodeNumbers = zipcode.replace('-', '');
            const address = await fetchAddressFromZipcode(zipcodeNumbers);

            setValue('address.neighborhood', address.bairro);
            setValue('address.city', address.localidade);
            setValue('address.street_name', address.logradouro);

            const addressState = stateOptions.find((state) => state.value === address.uf);
            if (addressState) {
                setValue('address.state', addressState);
            }
        } catch (err) {}
    };

    useEffect(() => {
        fetchAddressInfoFromZipcode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipcode]);

    return (
        <>
            <BankaccountForm
                bankAccountId={bankAccountData?.id}
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors} // client-side validation
                error={error} // server-side error
                loading={loading}
                isEdit={true}
                displayTos={isBankAccountOwner}
                watch={watch}
                canConnectPagbank={!!bankAccountData?.tos_accepted_by}
                pagbankConnected={bankAccountData?.pagbank_id}
            />
        </>
    );
};

export default UpdateBankAccount;
