import { FC } from 'react';
import { AdminAdvertisingLayout } from '../../../common/components';
import PointList from '../../common/points/PointList';

interface Props {
    partnerId?: string;
}

const AdminPointList: FC<Props> = () => {
    const title = 'Pontos';

    return (
        <AdminAdvertisingLayout title={title}>
            <PointList />
        </AdminAdvertisingLayout>
    );
};

export default AdminPointList;
