import { FC, ReactNode } from 'react';

interface Props {
    color?: 'green' | 'blue' | 'red' | 'gray' | 'yellow' | 'black';
    children: ReactNode;
    className?: string;
}

// this approach is necessary since tailwind doesn't support dynamic classes (ie. `text-${color}-800`)
// https://stackoverflow.com/questions/71413730/conditionally-set-background-color-in-react-component-with-tailwind-css
const colors = {
    gray: {
        background: 'bg-gray-100',
        text: 'text-gray-700',
        dot: 'text-gray-500',
    },
    green: {
        background: 'bg-green-100',
        text: 'text-green-700',
        dot: 'text-green-500',
    },
    blue: {
        background: 'bg-blue-100',
        text: 'text-blue-700',
        dot: 'text-blue-500',
    },
    red: { background: 'bg-red-100', text: 'text-red-700', dot: 'text-red-500' },
    yellow: {
        background: 'bg-yellow-100',
        text: 'text-yellow-700',
        dot: 'text-yellow-500',
    },
    black: {
        background: 'bg-black',
        text: 'text-white',
        dot: 'text-white',
    },
};

const BadgePill: FC<Props> = ({ color: selectedColor = 'gray', children, className }) => {
    const color = colors[selectedColor];

    return (
        <span
            className={`inline-flex items-center rounded ${color.background} px-2 py-0.5 rounded-full h-[18px] ${className}`}
        >
            <span className={color.dot}>
                <svg className="mr-1.5 h-2 w-2" fill="currentColor" viewBox="0 0 8 8">
                    <circle cx={4} cy={4} r={3} />
                </svg>
            </span>
            <span className={`text-xs font-medium ${color.text}`}>{children}</span>
        </span>
    );
};

export default BadgePill;
