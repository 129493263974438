import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { PartnerLayoutWithSidebar } from '../../../common/components';
import { usePoints } from '../../../common/hooks';
import { requiredFieldMessage, validEmailMessage } from '../../../common/utils/validation.utils';
import UserForm from '../users/UserForm';
import { useSelector } from 'react-redux';
import { getSelectedOrg } from '../../../common/store';

export const schema = yup
    .object({
        name: yup.string().trim().required(requiredFieldMessage()),
        email: yup.string().trim().email(validEmailMessage()).required(requiredFieldMessage()),
    })
    .required(requiredFieldMessage());

const PartnerCreatePoint = () => {
    const org = useSelector(getSelectedOrg);
    const orgId = org?.id as string;

    const { createPoint, loading, error } = usePoints();

    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: '',
            name: '',
        },
    });

    const onSubmit = async (data: any) => {
        if (loading) return;

        try {
            await createPoint({ ...data, org_id: orgId });
            navigate(-1);
        } catch (err) {
            // try again...
        }
    };

    return (
        <PartnerLayoutWithSidebar>
            <UserForm
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors} // client-side validation
                error={error} // server-side error
                loading={loading}
                title="Conta de acesso do ponto"
                description="Informações da conta de usuário que terá acesso a conta do ponto"
            />
        </PartnerLayoutWithSidebar>
    );
};

export default PartnerCreatePoint;
