import { AdminAdvertisingLayout } from '../../../../common/components';
import CreateAdvertisingContent from '../../../common/advertising-content/CreateAdvertisingContent';

const AdminCreateAdvertisingContent = () => {
    return (
        <AdminAdvertisingLayout title="Adicionar conteúdo">
            <CreateAdvertisingContent />
        </AdminAdvertisingLayout>
    );
};

export default AdminCreateAdvertisingContent;
