import { FC } from 'react';
import BadgePill from './BadgePill';

interface Props {
    item: Partial<Device>;
    label?: string;
    className?: string;
}
const DeviceStatusPillComponent: FC<Props> = ({ item, label, ...props }) => {
    const status = {
        online: { label: 'Online', color: 'green' },
        faulty: { label: 'Com Defeito', color: 'red' },
        disabled: { label: 'Desabilitado', color: 'red' },
        offline: { label: 'Offline', color: 'gray' },
    };

    let currentStatus = status.offline;
    if (item.is_online) currentStatus = status.online;
    if (item.is_faulty) currentStatus = status.faulty;
    if (item.is_frozen) currentStatus = status.disabled;

    return (
        <BadgePill color={currentStatus.color as any} {...props}>
            {label || currentStatus.label}
        </BadgePill>
    );
};

export const BatteryStatusPillComponent: FC<Omit<Props, 'item'> & { item: Battery }> = ({
    item,
    label,
    ...props
}) => {
    const status = {
        normal: { label: 'Disponível', color: 'green' },
        unavailable: { label: 'Indisponível', color: 'yellow' },
        renting: { label: 'Alugada', color: 'yellow' },
        faulty: { label: 'Com Defeito', color: 'red' },
        lost: { label: 'Perdida', color: 'red' },
    };

    let currentStatus = status.normal;
    if (!item.device_slot) currentStatus = status.unavailable;
    if (item.is_renting) currentStatus = status.renting;
    if (item.is_faulty) currentStatus = status.faulty;
    if (item.is_lost) currentStatus = status.lost;

    return (
        <BadgePill color={currentStatus.color as any} {...props}>
            {label || currentStatus.label}
        </BadgePill>
    );
};

export const RentStatusPillComponent: FC<Omit<Props, 'item'> & { item: Rent }> = ({
    item,
    label,
    ...props
}) => {
    const status = {
        default: { label: '?', color: 'gray' },
        pending_payment: { label: 'Pagamento pendente', color: 'yellow' },
        active: { label: 'Em andamento', color: 'yellow' },
        finished: { label: 'Finalizado', color: 'green' },
        cancelled: { label: 'Cancelado', color: 'red' },
        failed: { label: 'Falha', color: 'red' },
    };

    let currentStatus = status.default;
    if (item.status === 'PENDING_PAYMENT') currentStatus = status.pending_payment;
    if (item.status === 'ACTIVE') currentStatus = status.active;
    if (item.status === 'FINISHED') currentStatus = status.finished;
    if (item.status === 'CANCELED') currentStatus = status.cancelled;
    if (['FAILED', 'FAILED_PRE_AUTHORIZATION'].includes(item.status)) currentStatus = status.failed;

    return (
        <BadgePill color={currentStatus.color as any} {...props}>
            {label || currentStatus.label}
        </BadgePill>
    );
};

export const ClientStatusPillComponent: FC<Omit<Props, 'item'> & { item: Client }> = ({
    item,
    label,
    ...props
}) => {
    if (!item.user) return <>-</>;

    const status = {
        active: { label: 'Ativo', color: 'green' },
        blocked: { label: 'Bloqueado', color: 'red' },
    };

    let currentStatus = status.active;
    if (item.user?.is_blocked) currentStatus = status.blocked;

    return (
        <BadgePill color={currentStatus.color as any} {...props}>
            {label || currentStatus.label}
        </BadgePill>
    );
};

export const LocationStatusPillComponent: FC<Omit<Props, 'item'> & { item: AppLocation }> = ({
    item,
    label,
    ...props
}) => {
    const status = {
        active: { label: 'Ativo', color: 'green' },
        disabled: { label: 'Desativado', color: 'red' },
    };

    let currentStatus = item.is_active ? status.active : status.disabled;

    return (
        <BadgePill color={currentStatus.color as any} {...props}>
            {label || currentStatus.label}
        </BadgePill>
    );
};

export const LogStatusPillComponent: FC<Omit<Props, 'item'> & { item: Log }> = ({
    item,
    label,
    ...props
}) => {
    const status = {
        DEBUG: { label: 'Debug', color: 'green' },
        INFO: { label: 'Info', color: 'blue' },
        WARNING: { label: 'Aviso', color: 'yellow' },
        ERROR: { label: 'Erro', color: 'red' },
        FATAL: { label: 'Fatal', color: 'black' },
    };

    let currentStatus = status[item.level];

    return (
        <BadgePill color={currentStatus.color as any} {...props}>
            {label || currentStatus.label}
        </BadgePill>
    );
};

export const CLCommandStatusPillComponent: FC<Omit<Props, 'item'> & { item: CommandLog }> = ({
    item,
    label,
    ...props
}) => {
    const status = {
        PENDING: { label: 'Pendente', color: 'yellow' },
        SUCCESS: { label: 'Sucesso', color: 'green' },
        FAILED: { label: 'Falha', color: 'red' },
    };

    let currentStatus = status[item.command_status];

    return (
        <BadgePill color={currentStatus.color as any} {...props}>
            {label || currentStatus.label}
        </BadgePill>
    );
};

export const CLLogStatusPillComponent: FC<Omit<Props, 'item'> & { item: CommandLog }> = ({
    item,
    label,
    ...props
}) => {
    const status = {
        PENDING: { label: 'Pendente', color: 'yellow' },
        OK: { label: 'OK', color: 'green' },
        WARNING: { label: 'ALERTA', color: 'red' },
    };

    let currentStatus = status[item.log_status];

    return (
        <BadgePill color={currentStatus.color as any} {...props}>
            {label || currentStatus.label}
        </BadgePill>
    );
};

export const TransactionStatusPillComponent: FC<Omit<Props, 'item'> & { item: Transaction }> = ({
    item,
    label,
    ...props
}) => {
    const status = {
        FINISHED: { label: 'Finalizado', color: 'green' },
        CANCELED: { label: 'Cancelado', color: 'red' },
    };

    let currentStatus = status[item.status];

    return (
        <BadgePill color={currentStatus.color as any} {...props}>
            {label || currentStatus.label}
        </BadgePill>
    );
};

export const PaymentTypeStatusPillComponent: FC<Omit<Props, 'item'> & { item: Transaction }> = ({
    item,
    label,
    ...props
}) => {
    const status = {
        IMMEDIATE: { label: 'Imediato', color: 'green' },
        PRE_AUTHORIZATION: { label: 'Pré-autorização', color: 'yellow' },
    };

    let currentStatus = status[item.payment_type];

    return (
        <BadgePill color={currentStatus.color as any} {...props}>
            {label || currentStatus.label}
        </BadgePill>
    );
};

export const BankAccountPagbankPillComponent: FC<Omit<Props, 'item'> & { item: BankAccount }> = ({
    item,
    label,
    ...props
}) => {
    const status = {
        MISSING_INFORMATION: { label: 'Dados Incompletos', color: 'red' },
        NOT_CONNECTED: { label: 'Não conectado', color: 'yellow' },
        CONNECTED: { label: 'Conectada', color: 'green' },
    };

    let currentStatus = status.MISSING_INFORMATION;
    if (item.tos_accepted_by) currentStatus = status.NOT_CONNECTED;
    if (item.pagbank_id) currentStatus = status.CONNECTED;

    return (
        <BadgePill color={currentStatus.color as any} {...props}>
            {label || currentStatus.label}
        </BadgePill>
    );
};

export default DeviceStatusPillComponent;
