import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';

interface UpdateUserPayload {
    is_blocked?: boolean;
}

const useUsers = () => {
    const { _delete, patch, error, loading } = useRequests();

    const deleteUser = async (id: string) => {
        const route = `/auth/users/${id}`;

        try {
            await _delete(route);
            toast.success('Usuário removido com sucesso!');
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            return true;
        }
    };

    const updateUser = async (id: string, payload: UpdateUserPayload) => {
        const route = `/auth/users/${id}`;

        try {
            await patch(route, payload);
            toast.success('Usuário atualizado com sucesso!');
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            return true;
        }
    };

    return {
        deleteUser,
        updateUser,
        error,
        loading,
    };
};

export default useUsers;
