import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    AdminLayout,
    AdminLayoutContentCard,
    ClientStatusPillComponent,
    DeleteConfirmationModal,
    Pagination,
    SearchBarInput,
    Table,
} from '../../../common/components';
import { useClients, useUsers } from '../../../common/hooks';
import { DateParserComponent } from '../../partner/batteries/PartnerBatteryList';

const headers = [
    { label: 'Nome', path: 'user.name' },
    { label: 'E-mail', path: 'user.email' },
    { label: 'Telefone', path: 'phone' },
    { label: 'Último acesso em', path: 'user.last_access_at', Component: DateParserComponent },
    { label: 'Termos aceitos em', path: 'accepted_tos_at', Component: DateParserComponent },
    { label: 'Status', path: 'user.is_blocked', Component: ClientStatusPillComponent },
];

const ClientList = () => {
    const navigate = useNavigate();
    const { clients, fetchClients, loading } = useClients({
        fetchData: true,
    });
    const { updateUser } = useUsers();

    const [search, setSearch] = useState('');

    const submitSearch = () => {
        fetchClients({ search });
    };

    const changePage = (page: number | string) => {
        if (page !== clients?.meta.currentPage) {
            fetchClients({ search, page });
        }
    };

    const navigateToClientRents = (client: Client) => {
        const route = `/clients/${client.id}/rents`;
        navigate(route, { state: { client } });
    };

    const [userToBlock, setUserToBlock] = useState<User>();
    const closeUserBlockModal = () => setUserToBlock(undefined);

    const onClickBlock = (client: Client) => {
        setUserToBlock(client.user);
    };

    const onConfirmBlock = async () => {
        if (!userToBlock) return;
        try {
            await updateUser(userToBlock.id, { is_blocked: !userToBlock?.is_blocked });
            setUserToBlock(undefined);
            submitSearch();
        } catch (err) {
            // try again...
        }
    };

    const deleteModalTitle = userToBlock?.is_blocked ? 'Desbloquear cliente' : 'Bloquear cliente';
    const deleteModalDescription = userToBlock?.is_blocked
        ? 'Você tem certeza que desbloquear a conta desse cliente?'
        : 'Você tem certeza que bloquear a conta desse cliente? Enquanto a conta estiver bloqueada, ele não conseguirá acessar o sistema';
    const deleteModalConfirmLabel = userToBlock?.is_blocked
        ? 'Sim, desbloquear conta'
        : 'Sim, bloquear conta';

    return (
        <AdminLayout title="Clientes">
            <AdminLayoutContentCard>
                <SearchBarInput search={search} setSearch={setSearch} submit={submitSearch} />
                <Table
                    headers={headers}
                    data={clients?.data || []}
                    className="mt-4"
                    valueClassName="!py-2"
                    actionButtonLabel="Pedidos"
                    onClickAction={navigateToClientRents}
                    actionSecondaryButtonLabel={(item: Client) =>
                        item.user?.is_blocked ? 'Desbloquear' : 'Bloquear'
                    }
                    onClickActionSecondary={onClickBlock}
                    logKey="user_id"
                    logKeyPath="user.id"
                    logTitle={(item) => (item.user?.name ? `de ${item.user.name}` : '')}
                    loading={loading}
                />
                <Pagination meta={clients?.meta} changePage={changePage} />
            </AdminLayoutContentCard>
            <DeleteConfirmationModal
                title={deleteModalTitle}
                description={deleteModalDescription}
                open={!!userToBlock}
                closeModal={closeUserBlockModal}
                acceptButtonAction={onConfirmBlock}
                acceptButtonCompleteLabel={deleteModalConfirmLabel}
            />
        </AdminLayout>
    );
};

export default ClientList;
