import { useEffect, useMemo, useState } from 'react';

interface LocationState {
    loading: boolean;
    accuracy: any;
    altitude: any;
    altitudeAccuracy: any;
    heading: any;
    latitude: any;
    longitude: any;
    speed: any;
    timestamp: any;
    error?: any;
}

const useGeolocation = () => {
    const [state, setState] = useState<LocationState>({
        loading: true,
        accuracy: null,
        altitude: null,
        altitudeAccuracy: null,
        heading: null,
        latitude: null,
        longitude: null,
        speed: null,
        timestamp: null,
        error: null,
    });

    const coords = useMemo(() => ({ lat: state.latitude, lng: state.longitude }), [state]);

    useEffect(() => {
        const onEvent = ({ coords, timestamp }: any) => {
            setState({
                loading: false,
                timestamp,
                latitude: coords.latitude,
                longitude: coords.longitude,
                altitude: coords.altitude,
                accuracy: coords.accuracy,
                altitudeAccuracy: coords.altitudeAccuracy,
                heading: coords.heading,
                speed: coords.speed,
            });
        };

        const onEventError = (error: any) => {
            setState((s) => ({
                ...s,
                loading: false,
                error,
            }));
        };

        navigator.geolocation.getCurrentPosition(onEvent, onEventError);

        // const watchId = navigator.geolocation.watchPosition(
        //   onEvent,
        //   onEventError,
        //   optionsRef.current
        // );

        // return () => {
        //   navigator.geolocation.clearWatch(watchId);
        // };
    }, []);

    return { ...state, coords };
};

export default useGeolocation;
