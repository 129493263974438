import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { AdminLayout, AdminLayoutContentCard } from '../../../common/components';
import { useBatteries } from '../../../common/hooks';
import { requiredFieldMessage } from '../../../common/utils/validation.utils';
import BatteryForm from './BatteryForm';

const schema = yup
    .object({
        serial_number: yup.string().trim().required(requiredFieldMessage()),
        org_id: yup.object().shape({
            value: yup.string().trim(),
        }),
    })
    .required(requiredFieldMessage());

const initialValue = {
    serial_number: '',
    org_id: { value: '' },
};

export const payloadFromSchema = (data: typeof initialValue) => {
    return {
        ...data,
        org_id: data.org_id.value || null,
    };
};

const CreateBattery = () => {
    const { createBattery, loading, error } = useBatteries();
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: initialValue,
    });

    const onSubmit = async (data: any) => {
        const payload = payloadFromSchema(data);
        try {
            await createBattery(payload);
            navigate('/batteries');
        } catch (err) {
            // try again...
        }
    };

    return (
        <AdminLayout title="Adicionar bateria">
            <AdminLayoutContentCard>
                <BatteryForm
                    control={control}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    errors={errors} // client-side validation
                    error={error} // server-side error
                    loading={loading}
                />
            </AdminLayoutContentCard>
        </AdminLayout>
    );
};

export default CreateBattery;
