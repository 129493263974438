import { AdminSettingsLayout } from '../../../common/components';
import UpdateBankAccount from '../../common/bank-accounts/UpdateBankAccount';

const AdminUpdateBankAccount = () => {
    return (
        <AdminSettingsLayout title="Alterar conta bancária">
            <UpdateBankAccount />
        </AdminSettingsLayout>
    );
};
export default AdminUpdateBankAccount;
