import { FC } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import InputContainer from './InputContainer';
import Toggle from './Toggle';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    name: string;
    label?: any;
    className?: string;
    control: Control<any>;
    error?: FieldError;
    labelActive?: string;
    labelDisabled?: string;
    disabled?: boolean;
};

const Input: FC<Props> = ({
    name,
    label,
    className = '',
    labelActive = 'Ativado',
    labelDisabled = 'Desativado',
    control,
    error,
    disabled,
}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <InputContainer
                    name={name}
                    className={`flex items-center fex-row ${className}`}
                    error={error}
                    label=" "
                    innerClassName="flex items-center"
                >
                    <Toggle enabled={field.value} setEnabled={field.onChange} disabled={disabled} />
                    <label
                        htmlFor={name}
                        className="flex items-center ml-4 text-sm font-medium leading-6 text-gray-900 whitespace-nowrap"
                    >
                        {label?.[field?.value] || (field?.value ? labelActive : labelDisabled)}
                    </label>
                </InputContainer>
            )}
        />
    );
};

export default Input;
