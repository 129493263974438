import { FC } from 'react';
import { AdminLayout, AdminLayoutContentCard } from '../../../common/components';
import TransactionList from '../../common/transactions/TransactionList';

interface Props {
    partnerId?: string;
}

const AdminTransactionList: FC<Props> = () => {
    const title = 'Financeiro';

    return (
        <AdminLayout title={title}>
            <AdminLayoutContentCard>
                <TransactionList />
            </AdminLayoutContentCard>
        </AdminLayout>
    );
};

export default AdminTransactionList;
