import { useState } from 'react';
import {
    AdminSettingsLayout,
    DeleteConfirmationModal,
    Pagination,
    Table,
} from '../../../../common/components';
import { useAdvertisingSegments } from '../../../../common/hooks';

const headers = [{ label: 'Nome', path: 'name' }];

const AdvertisingSegment = () => {
    const { advertisingSegments, fetchAdvertisingSegments, deleteAdvertisingSegment, loading } =
        useAdvertisingSegments();

    const [advertisingSegmentToDelete, setAdvertisingSegmentToDelete] =
        useState<AdvertisingSegment>();
    const closeAdvertisingSegmentDeleteModal = () => setAdvertisingSegmentToDelete(undefined);

    const changePage = (page: number) => {
        if (page !== advertisingSegments?.meta.currentPage) {
            fetchAdvertisingSegments(page.toString());
        }
    };

    const onClickDelete = (advertisingSegment: AdvertisingSegment) => {
        setAdvertisingSegmentToDelete(advertisingSegment);
    };

    const onConfirmDelete = async () => {
        if (!advertisingSegmentToDelete) return;
        try {
            await deleteAdvertisingSegment(advertisingSegmentToDelete.id);
        } catch (err) {
            // try again...
        }

        setAdvertisingSegmentToDelete(undefined);
        fetchAdvertisingSegments();
    };

    const addAdvertisingSegmentTitle = 'Adicionar segmento';
    const addAdvertisingSegmentHref = '/settings/advertising-segment/new';
    const editAdvertisingSegmentHref = '/settings/advertising-segment/edit';

    return (
        <AdminSettingsLayout title="Segmentos de publicidade">
            <Table
                headers={headers}
                data={advertisingSegments?.data || []}
                onClickDelete={onClickDelete}
                titleButtonLabel={addAdvertisingSegmentTitle}
                titleButtonHref={addAdvertisingSegmentHref}
                editPath={editAdvertisingSegmentHref}
                loading={loading}
            />
            <Pagination meta={advertisingSegments?.meta} changePage={changePage} />
            <DeleteConfirmationModal
                title="Excluir segmento"
                description="Você tem certeza que deseja excluir essa segmento? 
                Essa operação não pode ser desfeita."
                acceptButtonLabel="segmento"
                open={!!advertisingSegmentToDelete}
                closeModal={closeAdvertisingSegmentDeleteModal}
                acceptButtonAction={onConfirmDelete}
            />
        </AdminSettingsLayout>
    );
};

export default AdvertisingSegment;
