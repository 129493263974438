import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { AdminLayout, AdminLayoutContentCard } from '../../../common/components';
import countryOptions from '../../../common/data/countries.json';
import { useOrgs } from '../../../common/hooks';
import {
    positiveNumberMessage,
    requiredFieldMessage,
    requiredNumberMessage,
} from '../../../common/utils/validation.utils';
import { payloadFromSchema } from './CreateOrg';
import OrgForm from './OrgForm';

const schema = yup
    .object({
        name: yup.string().trim().required(requiredFieldMessage()),
        country: yup.object().shape({
            value: yup.string().trim().required(requiredFieldMessage()),
        }),
        platform_split: yup.object({
            total_percentage: yup
                .number()
                .positive(positiveNumberMessage())
                .required(requiredFieldMessage()),
            percentage_splits: yup.array().of(
                yup.object().shape({
                    id: yup.string().trim().required(requiredFieldMessage()),
                    total_percentage: yup
                        .number()
                        .positive(positiveNumberMessage())
                        .required(requiredFieldMessage()),
                    bank_account_id: yup.object().shape({
                        value: yup.string().trim().required(requiredFieldMessage()),
                    }),
                }),
            ),
        }),
        advertising_platform_time_percentage: yup
            .number()
            .typeError(requiredNumberMessage())
            .positive(positiveNumberMessage())
            .required(requiredFieldMessage())
            .min(0)
            .max(100),
        advertising_platform_value_percentage: yup
            .number()
            .typeError(requiredNumberMessage())
            .positive(positiveNumberMessage())
            .required(requiredFieldMessage())
            .min(0)
            .max(100),
    })
    .required(requiredFieldMessage());

const buildFormFromData = (data: any): any => {
    const countryOption = countryOptions.find((country) => country.value === data.country);

    return {
        name: data.name || '',
        country: countryOption || { value: '' },
        platform_split: {
            total_percentage: Number(data.platform_split.total_percentage || '0'),
            percentage_splits:
                data?.platform_split?.percentage_splits?.map((item: any) => ({
                    id: item.id,
                    total_percentage: Number(item.total_percentage),
                    bank_account_id: item.bank_account
                        ? {
                              value: item.bank_account.id,
                              label: item.bank_account.name,
                          }
                        : undefined,
                })) || [],
        },
        advertising_platform_time_percentage: data.advertising_platform_time_percentage || 0,
        advertising_platform_value_percentage: data.advertising_platform_value_percentage || 0,
        advertising_org_time_percentage:
            data.advertising_org_time_percentage || 100 - data.advertising_platform_time_percentage,
        advertising_org_value_percentage:
            data.advertising_org_value_percentage ||
            100 - data.advertising_platform_value_percentage,
    };
};

const UpdateOrg = () => {
    const { updateOrg, loading, error } = useOrgs();
    const navigate = useNavigate();
    const location = useLocation();

    // 1: get org data from navigation
    const orgData = location.state?.data;

    // 2: fill form data with the org data
    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        reset,
        watch,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: useMemo(() => orgData && buildFormFromData(orgData), [orgData]),
    });

    useEffect(() => {
        if (orgData) {
            reset(buildFormFromData(orgData));
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = async (data: any) => {
        const payload = payloadFromSchema(data);

        try {
            await updateOrg(orgData.id, payload);
            navigate('/orgs');
        } catch (err) {
            // try again...
        }
    };

    const advertising_platform_time_percentage = watch('advertising_platform_time_percentage');
    const advertising_platform_value_percentage = watch('advertising_platform_value_percentage');

    useEffect(() => {
        setValue('advertising_org_time_percentage', 100 - advertising_platform_time_percentage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advertising_platform_time_percentage]);

    useEffect(() => {
        setValue('advertising_org_value_percentage', 100 - advertising_platform_value_percentage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advertising_platform_value_percentage]);

    return (
        <AdminLayout title="Alterar parceiro">
            <AdminLayoutContentCard>
                <OrgForm
                    control={control}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    errors={errors} // client-side validation
                    error={error} // server-side error
                    getValues={getValues}
                    setValue={setValue}
                    watch={watch}
                    loading={loading}
                    isEdit={true}
                />
            </AdminLayoutContentCard>
        </AdminLayout>
    );
};

export default UpdateOrg;
