import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { AdminSettingsLayout } from '../../../../common/components';
import { useFaqs } from '../../../../common/hooks';
import { requiredFieldMessage } from '../../../../common/utils/validation.utils';
import FaqForm from './FaqForm';

export const schema = yup
    .object({
        title: yup.string().trim().required(requiredFieldMessage()),
        description: yup.string().trim().required(requiredFieldMessage()),
    })
    .required(requiredFieldMessage());

const initialState = {
    title: '',
    description: '',
};

export const payloadFromSchema = (data: typeof initialState) => {
    return data;
};

interface Props {}

const CreateFaq: FC<Props> = () => {
    const { createFaq, loading, error } = useFaqs();
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: initialState,
    });

    const onSubmit = async (data: any) => {
        const payload = payloadFromSchema(data);

        try {
            await createFaq(payload);
            navigate('/settings/faq');
        } catch (err) {
            // try again...
        }
    };

    return (
        <AdminSettingsLayout title="Adicionar tópico de ajuda">
            <FaqForm
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors} // client-side validation
                error={error} // server-side error
                loading={loading}
            />
        </AdminSettingsLayout>
    );
};

export default CreateFaq;
