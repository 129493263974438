import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    DeviceStatusPillComponent,
    Pagination,
    PartnerLayoutWithSidebar,
    SearchBarInput,
    Table,
} from '../../../common/components';
import { useDevices } from '../../../common/hooks';
import { getSelectedOrg } from '../../../common/store';
import DeviceBatteriesModal from './DeviceBatteriesModal';

const headers = [
    { label: 'Número de série', path: 'serial_number' },
    { label: 'Modelo', path: 'model' },
    { label: 'Local', path: 'location.name' },
    { label: 'Slots', path: 'battery_slot_quantity' },
    { label: 'Alugar', path: 'battery_available_quantity' },
    { label: 'Retornar', path: 'battery_borrowed_quantity' },
    { label: 'Status', path: 'status', Component: DeviceStatusPillComponent },
];

const PartnerDeviceList = () => {
    const selectedOrg = useSelector(getSelectedOrg);
    const orgId = selectedOrg?.id;
    const { devices, fetchDevices, loading } = useDevices({
        fetchData: true,
        orgId,
    });

    const [search, setSearch] = useState('');
    const [selectedDevice, setSelectedDevice] = useState<Device>();

    const submitSearch = () => {
        fetchDevices({ search });
    };

    const changePage = (page: number | string) => {
        if (page !== devices?.meta.currentPage) {
            fetchDevices({ search, page });
        }
    };

    const handleActionClick = (device: Device) => {
        setSelectedDevice(device);
    };

    const refreshDeviceList = (device?: Device) => {
        if (device) {
            setSelectedDevice((old) => ({ ...old, ...device }));
        }

        fetchDevices({ search, page: devices?.meta.currentPage || 0 });
    };

    const closeDeviceModal = () => {
        setSelectedDevice(undefined);
        fetchDevices({ search, page: devices?.meta.currentPage || 0 });
    };

    return (
        <PartnerLayoutWithSidebar>
            <SearchBarInput search={search} setSearch={setSearch} submit={submitSearch} />
            <Table
                headers={headers}
                data={devices?.data || []}
                className="mt-4"
                actionButtonLabel="Gerenciar"
                onClickAction={handleActionClick}
                loading={loading}
            />
            <Pagination meta={devices?.meta} changePage={changePage} />
            <DeviceBatteriesModal
                open={!!selectedDevice}
                closeModal={closeDeviceModal}
                device={selectedDevice}
                refreshDeviceList={refreshDeviceList}
            />
        </PartnerLayoutWithSidebar>
    );
};

export default PartnerDeviceList;
