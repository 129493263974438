import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { BoltIcon } from '../../common/assets';
import { AccentButton, BaseLayout, Input, Link } from '../../common/components';
import Checkbox from '../../common/components/Checkbox';
import { useAuth } from '../../common/hooks';
import { requiredFieldMessage, validEmailMessage } from '../../common/utils/validation.utils';

const schema = yup
    .object({
        email: yup.string().trim().email(validEmailMessage()).required(requiredFieldMessage()),
        password: yup.string().trim().required(requiredFieldMessage()),
    })
    .required(requiredFieldMessage());

function Login() {
    const { login, loading } = useAuth();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: '',
            password: '',
        },
    });

    const onSubmit = async (data: any) => {
        await login(data);
    };

    return (
        <BaseLayout>
            <div className="flex flex-col flex-1 px-6 py-12 min-h-full lg:px-8">
                <div className="mt-16 sm:mx-auto sm:w-full sm:max-w-sm">
                    <BoltIcon className="mx-auto w-auto h-10" />
                    <h2 className="mt-10 text-2xl font-bold tracking-tight leading-9 text-center text-gray-900">
                        Login
                    </h2>
                </div>

                <div className="p-12 mt-10 bg-white rounded shadow-md sm:mx-auto md:w-full md:max-w-md">
                    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <Input
                                name="email"
                                label="Email"
                                type="email"
                                required
                                control={control}
                                error={errors?.email}
                            />
                        </div>

                        <div>
                            <Input
                                name="password"
                                label="Senha"
                                type="password"
                                required
                                control={control}
                                error={errors?.password}
                            />
                        </div>

                        <div className="flex justify-between w-full align-center">
                            <Checkbox name="remember-me" label="Permanecer conectado" />
                            <div className="flex justify-center text-sm">
                                <Link href="/auth/forgot-password">Esqueceu a senha?</Link>
                            </div>
                        </div>
                        <div>
                            <AccentButton type="submit" loading={loading}>
                                Sign in
                            </AccentButton>
                        </div>
                    </form>
                </div>
            </div>
        </BaseLayout>
    );
}

export default Login;
