import { FC } from 'react';

type Props = {
    name: string;
    label?: string;
    type?: string;
    required?: boolean;
    className?: string;
};

const Checkbox: FC<Props> = ({ name, label, required = false, className }) => {
    return (
        <div className={`flex relative gap-x-3 ${className}`}>
            <div className="flex items-center h-6">
                <input
                    id={name}
                    name={name}
                    type="checkbox"
                    className="w-4 h-4 rounded border-gray-300 text-accent-400 focus:ring-accent-400"
                    required={required}
                />
            </div>
            <div className="text-sm leading-6">
                <label htmlFor={name} className="font-medium text-gray-900">
                    {label}
                </label>
            </div>
        </div>
    );
};

export default Checkbox;
