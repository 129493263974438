import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useAdvertisingContents } from '../../../common/hooks';
import { requiredFieldMessage } from '../../../common/utils/validation.utils';
import AdvertisingContentForm from './AdvertisingContentForm';
import { formattedMinutesToIntSeconds } from '../../../common/utils/date.utils';
import { getFileType } from '../../../common/utils/file.utils';

export const schema = yup
    .object({
        name: yup.string().trim().required(requiredFieldMessage()),
        duration: yup.string().trim().required(requiredFieldMessage()),
    })
    .required(requiredFieldMessage());

const initialState = {
    name: '',
    duration: '',
};

export const payloadFromSchema = (data: typeof initialState, file: any) => {
    const duration = formattedMinutesToIntSeconds(data.duration);

    let payload: any = {
        ...data,
        duration,
    };

    if (file) {
        const file_ext = file?.name?.split('.')?.[1];
        const file_type = getFileType(file?.name)?.toUpperCase();

        payload = {
            ...payload,
            file_url: file,
            file_ext,
            file_type,
            file_name: file?.name,
        };
    }

    console.log('payloadFromSchema', { payload });

    return payload;
};

interface Props {
    orgId?: string;
}

const CreateAdvertisingContent: FC<Props> = ({ orgId }) => {
    const { createAdvertisingContent, loading, error } = useAdvertisingContents(orgId);
    const navigate = useNavigate();

    const [file, setFile] = useState<any>();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: initialState,
    });

    const onSubmit = async (data: any) => {
        const payload = payloadFromSchema(data, file);

        try {
            await createAdvertisingContent(payload);
            const activePath = orgId ? `/orgs/${orgId}` : '';
            navigate(`${activePath}/advertising/contents`);
        } catch (err) {
            // try again...
        }
    };

    return (
        <>
            <AdvertisingContentForm
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors} // client-side validation
                error={error} // server-side error
                loading={loading}
                file={file}
                setFile={setFile}
            />
        </>
    );
};

export default CreateAdvertisingContent;
