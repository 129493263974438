import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { BoltIcon } from '../../common/assets';
import { AccentButton, BaseLayout, Input } from '../../common/components';
import { useAuth } from '../../common/hooks';
import { requiredFieldMessage, validEmailMessage } from '../../common/utils/validation.utils';

const schema = yup
    .object({
        email: yup.string().trim().email(validEmailMessage()).required(requiredFieldMessage()),
    })
    .required(requiredFieldMessage());

function ForgotPassword() {
    const { forgotPassword, hasSentRecoverRequest, loading } = useAuth();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: '',
        },
    });

    const onSubmit = async (data: any) => {
        await forgotPassword(data);
    };

    return (
        <BaseLayout>
            <div className="flex flex-col flex-1 px-6 py-12 min-h-full lg:px-8">
                <div className="mt-16 sm:mx-auto sm:w-full sm:max-w-sm">
                    <BoltIcon className="mx-auto w-auto h-10" />
                    <h2 className="mt-10 text-2xl font-bold tracking-tight leading-9 text-center text-gray-900">
                        Recuperar senha
                    </h2>
                    {hasSentRecoverRequest ? (
                        <p className="mt-4 text-lg text-center text-gray-500">
                            Um link para recuperação de senha foi enviado para o seu email
                        </p>
                    ) : (
                        <p className="mt-4 text-center text-gray-500">
                            Digite o seu e-mail e iremos enviar um link para redefinir sua senha.
                        </p>
                    )}
                </div>

                {hasSentRecoverRequest ? (
                    <></>
                ) : (
                    <div className="p-12 mt-10 bg-white rounded shadow-md sm:mx-auto md:w-full md:max-w-md">
                        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                            <div>
                                <Input
                                    name="email"
                                    label="Email"
                                    type="email"
                                    required
                                    control={control}
                                    error={errors?.email}
                                />
                            </div>

                            <div>
                                <AccentButton type="submit" loading={loading}>
                                    Recuperar senha
                                </AccentButton>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </BaseLayout>
    );
}

export default ForgotPassword;
