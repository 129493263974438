import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';

interface Options {
    fetchData?: boolean;
}

const useClients = ({ fetchData = false }: Options = {} as any) => {
    const [clients, setClients] = useState<{ meta: PaginationMeta; data: Client[] }>();
    const { get, patch, loading, error } = useRequests();

    const fetchClients = async ({ page, search }: FetchParams = {}) => {
        let route = '/client/clients?';

        if (page) {
            route += `page=${page}&`;
        }

        if (search) {
            route += `search=${search}&`;
        }

        try {
            const response = await get(route);
            const data = response?.data;
            setClients(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const updateClient = async (id: string, payload: any) => {
        let route = `/clients/client/${id}`;

        try {
            await patch(route, payload);
            setTimeout(() => toast.success('Cliente alterado com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    useEffect(() => {
        if (fetchData) {
            fetchClients();
        }
        // eslint-disable-next-line
    }, []);

    return {
        clients,
        fetchClients,
        updateClient,
        error,
        loading,
    };
};

export default useClients;
