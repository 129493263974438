import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface InitialState {
    selectedOrg?: Org;
    accessType?: 'ADMIN' | 'PARTNER';
}

const initialState: InitialState = {
    selectedOrg: undefined,
    accessType: undefined,
};

const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        selectOrg: (state, action) => {
            state.selectedOrg = action.payload;
        },
        setAccessType: (state, action) => {
            state.accessType = action.payload;
        },
    },
});

// selectors
export const getSelectedOrg = (state: RootState) => state.application.selectedOrg;
export const getAccessType = (state: RootState) => state.application.accessType;

// actions & reducer
export const { actions, reducer } = applicationSlice;
