import { useSelector } from 'react-redux';
import { PartnerLayoutWithSidebar } from '../../../common/components';
import { getSelectedOrg } from '../../../common/store';
import AdvertisingContentList from '../../common/advertising-content/AdvertisingContent';

const PartnerAdvertisingContentList = () => {
    const selectedOrg = useSelector(getSelectedOrg);
    const orgId = selectedOrg?.id as string;

    return (
        <PartnerLayoutWithSidebar>
            <AdvertisingContentList orgId={orgId} />
        </PartnerLayoutWithSidebar>
    );
};

export default PartnerAdvertisingContentList;
