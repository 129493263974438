import { useSelector } from 'react-redux';
import { PartnerLayoutWithSidebar } from '../../../common/components';
import { getSelectedOrg } from '../../../common/store';
import TransactionList from '../../common/transactions/TransactionList';

const PartnerTransactionList = () => {
    const selectedOrg = useSelector(getSelectedOrg);
    const orgId = selectedOrg?.id;
    const partnerName = selectedOrg?.name;

    return (
        <PartnerLayoutWithSidebar>
            <TransactionList partnerId={orgId} partnerName={partnerName} />
        </PartnerLayoutWithSidebar>
    );
};

export default PartnerTransactionList;
