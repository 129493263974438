import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';

interface Options {
    fetchData?: boolean;
    orgId?: string;
    clientId?: string;
}

const useRents = ({ fetchData = false, orgId, clientId }: Options = {} as any) => {
    const [rents, setRents] = useState<{ meta: any; data: any[] }>();
    const { get, patch, loading, error } = useRequests();

    const fetchRents = async ({ page, search }: FetchParams = {}) => {
        let route = '/partner/rents?';

        if (clientId) {
            route += `clientId=${clientId}&`;
        }

        if (orgId) {
            route += `orgId=${orgId}&`;
        }

        if (page) {
            route += `page=${page}&`;
        }

        if (search) {
            route += `search=${search}&`;
        }

        try {
            const response = await get(route);
            const data = response?.data;
            setRents(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const updateRent = async (id: string, payload: any) => {
        let route = `/partner/rents/${id}`;

        try {
            await patch(route, payload);
            setTimeout(() => toast.success('Aluguel alterado com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const cancelRent = async (id: string) => {
        let route = `/partner/rents/${id}/cancel`;

        try {
            await patch(route, {});
            setTimeout(() => toast.success('Aluguel cancelado com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    useEffect(() => {
        if (fetchData) {
            fetchRents();
        }
        // eslint-disable-next-line
    }, []);

    return {
        rents,
        fetchRents,
        updateRent,
        cancelRent,
        error,
        loading,
    };
};

export default useRents;
