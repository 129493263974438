import { EyeIcon, EyeSlashIcon, ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    AccentButton,
    BaseInput,
    BaseSelectInput,
    BasicButton,
    DatePickerRangeInput,
    TransactionStatusPillComponent,
    Pagination,
    Table,
    PaymentTypeStatusPillComponent,
} from '../../../common/components';
import { useTransactions } from '../../../common/hooks';
import { PercentageComponent } from '../../admin/orgs/OrgList';
import { DateTimeSecondsParserComponent } from '../../admin/logs/LogList';
import { formatCurrency } from '../../../common/utils/format.utils';
import { MinutesParserComponent } from '../../../common/components/Table/TableComponents';

export const CurrencyParserComponent: FC<{ value: string }> = ({ value }) => {
    const date = useMemo(() => {
        return formatCurrency(value, '') || '-';
    }, [value]);

    return <>{date}</>;
};

const headers = [
    { label: 'Pedido', path: 'rent_id' },
    { label: 'Conta Bancária', path: 'bank_account.name' },
    { label: 'Local', path: 'location.name' },
    { label: 'Máquina', path: 'device.serial_number' },
    { label: 'Bateria', path: 'battery.serial_number' },
    { label: 'Cliente', path: 'client.user.name' },
    { label: 'Data de inicío', path: 'rent.start_time', Component: DateTimeSecondsParserComponent },
    { label: 'Data de retorno', path: 'rent.end_time', Component: DateTimeSecondsParserComponent },
    { label: 'Duração', path: 'rent.total_time', Component: MinutesParserComponent },
    { label: 'Crédito', path: 'rent.used_rent_hours_credit' },
    { label: 'Tempo grátis', path: 'rent.charge_free_minutes' },
    {
        label: 'Valor 1a hora',
        path: 'rent.charge_value_first_hour',
        Component: CurrencyParserComponent,
    },
    { label: 'Valor hora', path: 'rent.charge_value_per_hour', Component: CurrencyParserComponent },
    { label: 'Tempo faturado', path: 'rent.total_billed_time', Component: MinutesParserComponent },
    { label: 'Valor total', path: 'rent.total_billed', Component: CurrencyParserComponent },
    { label: '% Split', path: 'split_percentage', Component: PercentageComponent },
    { label: 'Comissão', path: 'split_billed', Component: CurrencyParserComponent },
    { label: 'Pagamento', path: 'payment_type', Component: PaymentTypeStatusPillComponent },
    { label: 'Status', path: 'status', Component: TransactionStatusPillComponent },
];

const statusOptions = [
    { label: 'Finalizado', value: 'FINISHED' },
    { label: 'Cancelado', value: 'CANCELED' },
];

const paymentTypeOptions = [
    { label: 'Imediato', value: 'IMMEDIATE' },
    { label: 'Pré-autorização', value: 'PRE_AUTHORIZATION' },
];

const defaultOption = { value: '', label: '-' };

// const defaultFilters = {};
const defaultDateRange = {
    startDate: null,
    endDate: null,
};

// const extractStateFilters = (location: any) => {
//     if (location.state?.filters) {
//         return location.state?.filters;
//     }

//     if (location.search) {
//         const params = new URLSearchParams(location.search);
//         const key = params.get('filter_key');
//         const value = params.get('filter_val');

//         if (key && value) {
//             return { [key]: value };
//         }
//     }

//     return defaultFilters;
// };

interface Props {
    partnerId?: string;
    partnerName?: string;
}

const TransactionList: FC<Props> = ({ partnerId, partnerName }) => {
    const location = useLocation();

    const {
        transactions,
        orgOptions,
        bankAccountOptions,
        locationOptions,
        fetchTransactions,
        downloadTransactions,
        loading,
    } = useTransactions({
        fetchData: true,
        fetchOptions: true,
        partnerId: partnerId,
    });

    // basic
    const [dateRange, setDateRange] = useState<any>(defaultDateRange);
    const [orgId, setOrgId] = useState<SelectOption>(defaultOption);

    // advanced
    const [bankAccountId, setBankAccountId] = useState<SelectOption>(defaultOption);
    const [locationId, setLocationId] = useState<SelectOption>(defaultOption);
    const [status, setStatus] = useState<SelectOption>(defaultOption);
    const [paymentType, setPaymentType] = useState<SelectOption>(defaultOption);
    const [deviceSearch, setDeviceSearch] = useState('');
    const [batterySearch, setBatterySearch] = useState('');

    const [trigger, setTrigger] = useState(false);

    const clearFilters = () => {
        // basic filters
        setDateRange(defaultDateRange);
        if (!partnerId) setOrgId(defaultOption);

        // advanced filters
        setBankAccountId(defaultOption);
        setLocationId(defaultOption);
        setStatus(defaultOption);
        setPaymentType(defaultOption);
        setDeviceSearch('');
        setBatterySearch('');

        // trigger to re-fetch transactions
        setTrigger(true);
    };

    const [showFilters, setShowFilters] = useState(false);

    const toggleFilters = () => {
        setShowFilters((old) => !old);
    };

    const submitFilters = (page: number | string = 1) => {
        const params = buildFiltersPayload(page);
        fetchTransactions(params);
    };

    const downloadCSV = async () => {
        const params = buildFiltersPayload();
        await downloadTransactions(params);
    };

    const buildFiltersPayload = (page?: number | string) => {
        const search_filters = {
            org_id: orgId?.value,
            bank_account_id: bankAccountId?.value,
            location_id: locationId?.value,
            payment_type: paymentType?.value,
            device_search: deviceSearch,
            battery_search: batterySearch,
        };

        const params = {
            status: status?.value as unknown as any,
            date_from: dateRange.startDate,
            date_to: dateRange.endDate,
            search_filters,
            page,
        };

        return params;
    };

    const changePage = (page: number | string) => {
        if (page !== transactions?.meta.currentPage) {
            submitFilters(page);
        }
    };

    useEffect(() => {
        if (trigger) {
            setTrigger(false);
            submitFilters();
        }
        // eslint-disable-next-line
    }, [trigger]);

    useEffect(() => {
        if (partnerId) {
            const partnerOption = { label: partnerName || partnerId, value: partnerId };
            setOrgId(partnerOption);
        }

        // eslint-disable-next-line
    }, [partnerId]);

    return (
        // <div className="flex-col flex-grow max-w-full">
        <>
            <div className="flex flex-row flex-wrap gap-y-2 gap-x-4 items-end">
                <div className="!max-w-[290px]">
                    <DatePickerRangeInput
                        value={dateRange}
                        onChange={setDateRange}
                        name="date_filter"
                        label="Período"
                    />
                </div>

                <BaseSelectInput
                    name="org"
                    label="Parceiro"
                    value={orgId}
                    onChange={setOrgId}
                    className="!max-w-[220px]"
                    options={orgOptions}
                    hasEmptyOption
                    isDisabled={!!partnerId}
                />

                {showFilters ? (
                    <>
                        <BaseSelectInput
                            name="bankAccount"
                            label="Conta Bancária"
                            value={bankAccountId}
                            onChange={setBankAccountId}
                            className="!max-w-[220px]"
                            options={bankAccountOptions}
                            hasEmptyOption
                        />

                        <BaseSelectInput
                            name="location"
                            label="Local"
                            value={locationId}
                            onChange={setLocationId}
                            className="!max-w-[220px]"
                            options={locationOptions}
                            hasEmptyOption
                        />
                        <BaseInput
                            name="device"
                            label="Máquina"
                            value={location?.state?.filters?.device_id || deviceSearch}
                            onChange={(e: any) => setDeviceSearch(e.target.value)}
                            className="!max-w-[220px]"
                            inputClassName="h-[38px]"
                            disabled={location?.state?.filters?.device_id}
                        />
                        <BaseInput
                            name="battery"
                            label="Bateria"
                            value={location?.state?.filters?.battery_id || batterySearch}
                            onChange={(e: any) => setBatterySearch(e.target.value)}
                            className="!max-w-[220px]"
                            inputClassName="h-[38px]"
                            disabled={location?.state?.filters?.battery_id}
                        />

                        <BaseSelectInput
                            name="status"
                            label="Status"
                            value={status}
                            onChange={setStatus}
                            className="!max-w-[220px]"
                            options={statusOptions}
                            hasEmptyOption
                        />

                        <BaseSelectInput
                            name="paymentType"
                            label="Tipo de Pagamento"
                            value={paymentType}
                            onChange={setPaymentType}
                            className="!max-w-[220px]"
                            options={paymentTypeOptions}
                            hasEmptyOption
                        />
                    </>
                ) : (
                    <></>
                )}
                <div>
                    <BasicButton className="h-[38px] !shadow-none !px-4" onClick={toggleFilters}>
                        {showFilters ? (
                            <EyeIcon color="#6B7280" height={24} />
                        ) : (
                            <EyeSlashIcon color="#6B7280" height={24} />
                        )}
                        <p className="ml-2">Filtros Avançados</p>
                    </BasicButton>
                </div>
                <div title="Limpar Filtros">
                    <BasicButton
                        // className="h-[36px] border-0 !shadow-none px-6"
                        className="h-[38px] !shadow-none px-6"
                        onClick={clearFilters}
                    >
                        Limpar
                    </BasicButton>
                </div>
                <div title="Aplicar Filtros">
                    <AccentButton className="h-[38px] px-6" onClick={() => submitFilters()}>
                        Filtrar
                    </AccentButton>
                </div>
                <div title="Baixar CSV">
                    <BasicButton
                        className="h-[38px] px-6 !bg-green-400 !border-none hover:!bg-green-500"
                        onClick={downloadCSV}
                    >
                        <ArrowDownTrayIcon color="#fff" height={24} />
                    </BasicButton>
                </div>
            </div>
            <Table
                headers={headers}
                data={transactions?.data || []}
                className="mt-4 !max-w-full"
                valueClassName="!py-2"
                actionButtonLabel="Detalhes"
                loading={loading}
            />
            <Pagination meta={transactions?.meta} changePage={changePage} />
        </>
    );
};

export default TransactionList;
