import { yupResolver } from '@hookform/resolvers/yup';
import { createId } from '@paralleldrive/cuid2';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { AdminLayout, AdminLayoutContentCard } from '../../../common/components';
import { useOrgs } from '../../../common/hooks';
import {
    positiveNumberMessage,
    requiredFieldMessage,
    validEmailMessage,
} from '../../../common/utils/validation.utils';
import OrgForm from './OrgForm';
import { useEffect } from 'react';

const schema = yup
    .object({
        name: yup.string().trim().required(requiredFieldMessage()),
        country: yup.object().shape({
            value: yup.string().trim().required(requiredFieldMessage()),
        }),
        platform_split: yup.object({
            total_percentage: yup
                .number()
                .positive(positiveNumberMessage())
                .required(requiredFieldMessage()),
            percentage_splits: yup.array().of(
                yup.object().shape({
                    id: yup.string().trim().required(requiredFieldMessage()),
                    total_percentage: yup
                        .number()
                        .positive(positiveNumberMessage())
                        .required(requiredFieldMessage()),
                    bank_account_id: yup.object().shape({
                        value: yup.string().trim().required(requiredFieldMessage()),
                    }),
                }),
            ),
        }),
        partner_user: yup.object({
            name: yup.string().trim().required(requiredFieldMessage()),
            email: yup.string().trim().email(validEmailMessage()).required(requiredFieldMessage()),
        }),
        advertising_platform_time_percentage: yup
            .number()
            .positive(positiveNumberMessage())
            .required(requiredFieldMessage())
            .min(0)
            .max(100),
        advertising_platform_value_percentage: yup
            .number()
            .positive(positiveNumberMessage())
            .required(requiredFieldMessage())
            .min(0)
            .max(100),
        advertising_org_time_percentage: yup.number(),
        advertising_org_value_percentage: yup.number(),
    })
    .required(requiredFieldMessage());

const initialValue = {
    name: '',
    country: { value: 'BRA', label: 'Brasil' },
    platform_split: {
        total_percentage: 0,
        percentage_splits: [
            {
                id: createId(),
                total_percentage: 0,
                bank_account_id: { value: '' },
            },
        ],
    },
    partner_user: {
        email: '',
        name: '',
    },
    advertising_platform_time_percentage: 0,
    advertising_platform_value_percentage: 0,
    advertising_org_time_percentage: 100,
    advertising_org_value_percentage: 100,
};

export const payloadFromSchema = (data: typeof initialValue) => {
    return {
        ...data,
        country: data.country.value,
        platform_split: {
            ...data.platform_split,
            total_percentage: data.platform_split.total_percentage.toFixed(2),
            percentage_splits: data.platform_split.percentage_splits.map((percentage: any) => ({
                ...percentage,
                total_percentage: percentage.total_percentage.toFixed(2),
                bank_account_id: percentage.bank_account_id.value,
            })),
        },
    };
};

const CreateOrg = () => {
    const { createOrg, loading, error } = useOrgs();
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        watch,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: initialValue,
    });

    const onSubmit = async (data: any) => {
        const payload = payloadFromSchema(data);

        try {
            await createOrg(payload);
            navigate('/orgs');
        } catch (err) {
            // try again...
        }
    };

    const advertising_platform_time_percentage = watch('advertising_platform_time_percentage');
    const advertising_platform_value_percentage = watch('advertising_platform_value_percentage');

    useEffect(() => {
        setValue('advertising_org_time_percentage', 100 - advertising_platform_time_percentage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advertising_platform_time_percentage]);

    useEffect(() => {
        setValue('advertising_org_value_percentage', 100 - advertising_platform_value_percentage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advertising_platform_value_percentage]);

    return (
        <AdminLayout title="Adicionar parceiro">
            <AdminLayoutContentCard>
                <OrgForm
                    control={control}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    errors={errors} // client-side validation
                    error={error} // server-side error
                    getValues={getValues}
                    setValue={setValue}
                    watch={watch}
                    loading={loading}
                />
            </AdminLayoutContentCard>
        </AdminLayout>
    );
};

export default CreateOrg;
