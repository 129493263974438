import { FC } from 'react';
import { Control, UseFormHandleSubmit } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
    AccentButton,
    ErrorMessage,
    FormColumn,
    FormDescriptionColumn,
    FormSection,
    Input,
    SelectInput,
} from '../../../common/components';
import { useOrgs } from '../../../common/hooks';

interface Props {
    control: Control<any>;
    handleSubmit: UseFormHandleSubmit<any>;
    onSubmit: (data: any) => void;
    errors: any;
    error?: string;
    loading?: boolean;
    isEdit?: boolean;
}

const BatteryForm: FC<Props> = ({ control, handleSubmit, onSubmit, errors, error, loading }) => {
    const { orgOptions } = useOrgs({ fetchDataOptions: true });

    return (
        <form className="flex flex-col space-y-6 divide-y" onSubmit={handleSubmit(onSubmit)}>
            <ErrorMessage message={error} />
            <FormSection>
                <FormDescriptionColumn
                    title="Informações da bateria"
                    description="O parceiro poderá ser associado à bateria 
          posteriormente"
                />
                <FormColumn>
                    <Input
                        control={control}
                        name="serial_number"
                        label="Número de série"
                        placeholder="GT"
                        error={errors?.serial_number}
                    />
                    <SelectInput
                        control={control}
                        name="org_id"
                        label="Parceiro (opcional)"
                        error={errors?.org_id?.value}
                        hasEmptyOption={true}
                        emptyOptionLabel="Sem parceiro"
                        options={orgOptions}
                    />
                </FormColumn>
            </FormSection>
            <div className="flex justify-end items-center pt-7 text-sm">
                <Link to="/batteries" className="px-7 py-2 font-bold">
                    Cancelar
                </Link>
                <AccentButton type="submit" loading={loading} className="px-4 py-2 ml-3 w-max">
                    Salvar
                </AccentButton>
            </div>
        </form>
    );
};

export default BatteryForm;
