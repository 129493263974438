import { useMemo, useState } from 'react';
import { AdminSettingsLayout, Table, UpdateFieldModal } from '../../../common/components';
import { useScreenRent } from '../../../common/hooks';
import { LinkComponent } from './ScreenPlan';

const messageHeaders = [
    { label: 'Tipo', path: 'description' },
    { label: 'Mensagem', path: 'value' },
];

const imageHeaders = [
    { label: 'Tipo', path: 'description' },
    // { label: 'Tamanho', path: 'size' },
    { label: 'Imagem', path: 'value', Component: LinkComponent },
];

const messageValues = [
    { id: 'success_text', description: 'Titulo de aluguel com sucesso', value: '', type: 'text' },
    { id: 'fail_text', description: 'Titulo de aluguel com falha', value: '', type: 'text' },
    {
        id: 'success_content',
        description: 'Mensagem de aluguel com sucesso',
        value: '',
        type: 'text',
    },
    { id: 'fail_content', description: 'Mensagem do aluguel com falha', value: '', type: 'text' },
];

const imageValues = [
    {
        id: 'success_cross',
        description: 'Imagem de aluguel com sucesso da máquina de 8 slots',
        value: '',
        // size: '1280x1024',
        type: 'image',
    },
    {
        id: 'fail_cross',
        description: 'Imagem de aluguel com falha da máquina de 8 slots',
        value: '',
        // size: '1280x1024',
        type: 'image',
    },
    {
        id: 'success_vertical',
        description: 'Imagem de aluguel com sucesso do gabinete grande',
        value: '',
        // size: '1280x1024',
        type: 'image',
    },
    {
        id: 'fail_vertical',
        description: 'Imagem de aluguel com falha do gabinete grande',
        value: '',
        // size: '1280x1024',
        type: 'image',
    },
];

const AdminScreenRents = () => {
    const { screenRent, updateScreenRentFile, updateScreenRent, loading } = useScreenRent(true);

    const [screenRentMessagesData, screenRentImagesData] = useMemo(() => {
        if (!screenRent) return [messageValues, imageValues];

        const _messages = messageValues.map((message) => ({
            ...message,
            value: screenRent[message.id],
        }));

        const _images = imageValues.map((image) => ({
            ...image,
            value: screenRent[image.id],
        }));

        return [_messages, _images];
        // eslint-disable-next-line
    }, [screenRent]);

    const [updateRent, setUpdateRent] = useState<any>();

    const onConfirmUpdate = async (data: any) => {
        if (!data) return;
        try {
            if (typeof data === 'string') {
                await updateScreenRent({ [updateRent.id]: data });
            } else {
                await updateScreenRentFile(updateRent.id, data);
            }
        } catch (err) {}
    };

    return (
        <AdminSettingsLayout title="Padrões de aluguel">
            <div className="flex flex-col flex-1">
                <div className="mb-4 text-lg font-semibold">Mensagens de texto</div>
                <Table
                    headers={messageHeaders}
                    data={screenRentMessagesData}
                    actionButtonLabel="Alterar"
                    onClickAction={setUpdateRent}
                    loading={loading}
                />
                <div className="mt-10 mb-4 text-lg font-semibold">Imagens</div>
                <Table
                    headers={imageHeaders}
                    data={screenRentImagesData}
                    actionButtonLabel="Alterar"
                    onClickAction={setUpdateRent}
                    loading={loading}
                />
            </div>
            <UpdateFieldModal
                title={updateRent?.description}
                open={!!updateRent}
                acceptButtonAction={onConfirmUpdate}
                inputName={updateRent?.id}
                closeModal={() => setUpdateRent(undefined)}
                loading={loading}
                type={updateRent?.type}
                fileInputAccept="image/*"
                value={updateRent?.value}
            />
        </AdminSettingsLayout>
    );
};

export default AdminScreenRents;
