import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';

const useAdminAccounts = (fetchData = false) => {
    const [adminAccounts, setAdminAccounts] = useState([]);
    const { get, post, loading, error } = useRequests();

    const fetchAdminAccounts = async () => {
        const route = '/auth/users?role=ADMIN';
        try {
            const response = await get(route);
            const data = response?.data?.data;
            setAdminAccounts(data || []);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const createAdminAccount = async (data: any) => {
        // TODO: update this route?
        const route = '/auth/users/admin';
        try {
            const response = await post(route, data);
            const user = response?.data?.data;
            return user;
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    useEffect(() => {
        if (fetchData) {
            fetchAdminAccounts();
        }
        // eslint-disable-next-line
    }, []);

    return {
        adminAccounts,
        fetchAdminAccounts,
        createAdminAccount,
        error,
        loading,
    };
};

export default useAdminAccounts;
