import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { AdminLayout, AdminLayoutContentCard } from '../../../common/components';
import { useDevices } from '../../../common/hooks';
import { requiredFieldMessage } from '../../../common/utils/validation.utils';
import DeviceForm from './DeviceForm';

const schema = yup
    .object({
        serial_number: yup.string().trim().required(requiredFieldMessage()),
        model: yup.object().shape({
            value: yup.string().trim().required(requiredFieldMessage()),
        }),
        battery_slot_quantity: yup.number().required(requiredFieldMessage()),
        org_id: yup.object().shape({
            value: yup.string().trim(),
        }),
    })
    .required(requiredFieldMessage());

const initialValue = {
    serial_number: '',
    model: { value: 'ZD-08P/D', label: 'ZD-08P/D' },
    battery_slot_quantity: 8,
    org_id: { value: '' },
};

export const payloadFromSchema = (data: typeof initialValue) => {
    return {
        ...data,
        model: data.model.value,
        org_id: data.org_id.value || null,
    };
};

const CreateDevice = () => {
    const { createDevice, loading, error } = useDevices();
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: initialValue,
    });

    const onSubmit = async (data: any) => {
        const payload = payloadFromSchema(data);

        try {
            await createDevice(payload);
            navigate('/devices');
        } catch (err) {
            // try again...
        }
    };

    return (
        <AdminLayout title="Adicionar máquina">
            <AdminLayoutContentCard>
                <DeviceForm
                    control={control}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    errors={errors} // client-side validation
                    error={error} // server-side error
                    loading={loading}
                />
            </AdminLayoutContentCard>
        </AdminLayout>
    );
};

export default CreateDevice;
