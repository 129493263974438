import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';

interface Options {
    fetchData?: boolean;
    orgId?: string;
}

const useBatteries = ({ fetchData = false, orgId }: Options = {} as any) => {
    const [batteries, setBatteries] = useState<{ meta: any; data: any[] }>();
    const { get, post, patch, _delete, loading, error } = useRequests();

    const fetchBatteries = async ({ page, search }: FetchParams = {}) => {
        let route = '/admin/batteries?';

        if (orgId) {
            route += `orgId=${orgId}&`;
        }

        if (page) {
            route += `page=${page}&`;
        }

        if (search) {
            route += `search=${search}&`;
        }

        try {
            const response = await get(route);
            const data = response?.data;
            setBatteries(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const createBattery = async (payload: any) => {
        let route = '/admin/batteries';

        try {
            await post(route, payload);
            setTimeout(() => toast.success('Bateria criada com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const updateBattery = async (id: string, payload: any) => {
        let route = `/admin/batteries/${id}`;

        try {
            await patch(route, payload);
            setTimeout(() => toast.success('Bateria alterada com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const deleteBattery = async (id: string) => {
        let route = `/admin/batteries/${id}`;

        try {
            await _delete(route);
            setTimeout(() => toast.success('Bateria removida com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    useEffect(() => {
        if (fetchData) {
            fetchBatteries();
        }
        // eslint-disable-next-line
    }, []);

    return {
        batteries,
        fetchBatteries,
        createBattery,
        updateBattery,
        deleteBattery,
        error,
        loading,
    };
};

export default useBatteries;
