import { FC, useState } from 'react';
import { DeleteConfirmationModal, Pagination, Table } from '../../../common/components';
import { useAdvertisingContents } from '../../../common/hooks';
import {
    ImagePreviewComponent,
    UpperCaseComponent,
} from '../../../common/components/Table/TableComponents';
import { MinutesParserComponent } from '../../partner/rents/PartnerRentList';

const headers = [
    {
        label: 'Preview',
        path: 'file_url',
        Component: ImagePreviewComponent,
    },
    {
        label: 'Conteúdo',
        path: 'name',
        containerClassName: 'sm:font-medium sm:text-gray-900',
    },
    { label: 'Formato', path: 'file_ext', Component: UpperCaseComponent },
    { label: 'Duração', path: 'duration', Component: MinutesParserComponent },
];

interface Props {
    orgId?: string;
}

const AdvertisingContent: FC<Props> = ({ orgId }) => {
    const { advertisingContents, fetchAdvertisingContents, deleteAdvertisingContent, loading } =
        useAdvertisingContents(orgId);

    const [advertisingContentToDelete, setAdvertisingContentToDelete] =
        useState<AdvertisingContent>();
    const closeAdvertisingContentDeleteModal = () => setAdvertisingContentToDelete(undefined);

    const [search, setSearch] = useState('');

    const submitSearch = () => {
        fetchAdvertisingContents({ search });
    };

    const changePage = (page: number) => {
        if (page !== advertisingContents?.meta.currentPage) {
            fetchAdvertisingContents({ page: page.toString(), search });
        }
    };

    const onClickDelete = (advertisingContent: AdvertisingContent) => {
        setAdvertisingContentToDelete(advertisingContent);
    };

    const onConfirmDelete = async () => {
        if (!advertisingContentToDelete) return;
        try {
            await deleteAdvertisingContent(advertisingContentToDelete.id);
        } catch (err) {
            // try again...
        }

        setAdvertisingContentToDelete(undefined);
        submitSearch();
    };

    const addAdvertisingContentTitle = 'Adicionar conteúdo';

    const activePath = orgId ? `/orgs/${orgId}` : '';
    const addAdvertisingContentHref = `${activePath}/advertising/contents/new`;
    const editAdvertisingContentHref = `${activePath}/advertising/contents/edit`;

    return (
        <>
            <Table
                headers={headers}
                data={advertisingContents?.data || []}
                onClickDelete={onClickDelete}
                titleButtonLabel={addAdvertisingContentTitle}
                titleButtonHref={addAdvertisingContentHref}
                editPath={editAdvertisingContentHref}
                searchBarProps={{ search, setSearch, submit: submitSearch }}
                loading={loading}
            />
            <Pagination meta={advertisingContents?.meta} changePage={changePage} />
            <DeleteConfirmationModal
                title="Excluir conteúdo"
                description="Você tem certeza que deseja excluir essa conteúdo? 
                Essa operação não pode ser desfeita."
                acceptButtonLabel="conteúdo"
                open={!!advertisingContentToDelete}
                closeModal={closeAdvertisingContentDeleteModal}
                acceptButtonAction={onConfirmDelete}
            />
        </>
    );
};

export default AdvertisingContent;
