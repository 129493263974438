import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { BlueArrowDown, GreenArrowUp } from '../../../common/assets';
import {
    AccentButton,
    BaseInput,
    BaseSelectInput,
    BasicButton,
    DatePickerRangeInput,
    Pagination,
    PartnerLayoutWithSidebar,
    RentStatusPillComponent,
    Table,
} from '../../../common/components';
import { useAdminRents } from '../../../common/hooks';
import { getSelectedOrg } from '../../../common/store';
import { formatDate, secondsToFormatedMinutes } from '../../../common/utils/date.utils';
import { useLocation } from 'react-router-dom';

export const MinutesParserComponent: FC<{ value: string }> = ({ value }) => {
    const date = useMemo(() => {
        const seconds = secondsToFormatedMinutes(Number(value));
        if (seconds.includes('NaN')) return '-';
        return seconds;
    }, [value]);

    return <>{date}</>;
};

export const StartFinishComponent: FC<{ item: Rent }> = ({ item }) => {
    const { start, finish } = useMemo(() => {
        const start = item.created_at;
        const finish = item.end_time;
        return { start, finish };
    }, [item]);

    return (
        <div>
            <div className="flex leading-5">
                <GreenArrowUp />
                {formatDate(start)}
            </div>
            {finish && (
                <div className="flex leading-5">
                    <BlueArrowDown />
                    {formatDate(finish)}
                </div>
            )}
        </div>
    );
};

const headers = [
    { label: 'Número do pedido', path: 'id' },
    { label: 'Aluguel/Retorno', path: 'start_time', Component: StartFinishComponent },
    { label: 'Duração', path: 'total_time', Component: MinutesParserComponent },
    { label: 'Bateria', path: 'battery.serial_number' },
    { label: 'Máquina Origem', path: 'device.serial_number' },
    { label: 'Máquina Destino', path: 'return_device.serial_number' },
    { label: 'Status', path: 'status', Component: RentStatusPillComponent },
];

const statusOptions = [
    { label: 'Todos', value: '' },
    { label: 'Pagamento pendente', value: 'PENDING_PAYMENT' },
    { label: 'Em andamento', value: 'ACTIVE' },
    { label: 'Finalizado', value: 'FINISHED' },
    { label: 'Cancelado', value: 'CANCELED' },
    { label: 'Falha', value: 'FAILED' },
];

const defaultOption = { value: '', label: '-' };

const defaultDateRange = {
    startDate: null,
    endDate: null,
};

const PartnerRentList = () => {
    const location = useLocation();

    const selectedOrg = useSelector(getSelectedOrg);
    const orgOption = { value: selectedOrg?.id || '', label: selectedOrg?.name || '' };
    const orgId = selectedOrg?.id;

    const { rents, locationOptions, fetchRents, loading } = useAdminRents({
        fetchData: true,
        fetchOptions: true,
        orgId,
    });

    // const { rents, fetchRents, loading } = useRents({
    //     fetchData: true,
    //     orgId,
    // });

    // const [search, setSearch] = useState('');

    const [status, setType] = useState(statusOptions[0]); // TODOS
    const [clientSearch, setClientSearch] = useState('');
    const [dateRange, setDateRange] = useState<any>(defaultDateRange);
    const [trigger, setTrigger] = useState(false);

    // advanced
    const [idSearch, setIdSearch] = useState('');
    const [locationId, setLocationId] = useState<SelectOption>(defaultOption);
    const [batterySearch, setBatterySearch] = useState('');
    const [deviceOriginSearch, setDeviceOriginSearch] = useState('');
    const [deviceReturnSearch, setDeviceReturnSearch] = useState('');

    const clearFilters = () => {
        // basic filters
        setType(statusOptions[0]);
        setDateRange(defaultDateRange);

        // advanced filters
        setLocationId(defaultOption);
        setClientSearch('');
        setIdSearch('');
        setDeviceOriginSearch('');
        setDeviceReturnSearch('');
        setBatterySearch('');

        // trigger to re-fetch rents
        setTrigger(true);
    };

    const [showFilters, setShowFilters] = useState(false);

    const toggleFilters = () => {
        setShowFilters((old) => !old);
    };

    const submitFilters = (page?: any) => {
        const search_filters = {
            id_search: idSearch,
            user_search: clientSearch,
            device_origin_search: deviceOriginSearch,
            device_return_search: deviceReturnSearch,
            battery_search: batterySearch,
            location_id: locationId?.value,
        };

        // console.log({ page, search_filters });

        fetchRents({
            status: status?.value as RentStatus,
            date_from: dateRange.startDate,
            date_to: dateRange.endDate,
            search_filters,
            page,
        });
    };

    const changePage = (page: number | string) => {
        if (page !== rents?.meta.currentPage) {
            submitFilters(page);
        }
    };

    useEffect(() => {
        if (trigger) {
            setTrigger(false);
            submitFilters();
        }
        // eslint-disable-next-line
    }, [trigger]);

    return (
        <PartnerLayoutWithSidebar>
            {/* <SearchBarInput search={search} setSearch={setSearch} submit={submitSearch} /> */}
            <div className="flex flex-row flex-wrap gap-4 items-end">
                <BaseSelectInput
                    name="Status"
                    options={statusOptions}
                    className="!max-w-[160px]"
                    value={status}
                    onChange={setType}
                />
                <div className="!max-w-[290px]">
                    <DatePickerRangeInput
                        value={dateRange}
                        onChange={setDateRange}
                        name="date_filter"
                        label="Período"
                        useRange={false}
                    />
                </div>
                <BaseInput
                    name="cliente"
                    label="Cliente"
                    value={location?.state?.filters?.user_id || clientSearch}
                    onChange={(e: any) => setClientSearch(e.target.value)}
                    className="!max-w-[220px]"
                    inputClassName="h-[38px]"
                    disabled={location?.state?.filters?.user_id}
                />
                {showFilters && (
                    <>
                        <BaseSelectInput
                            name="org"
                            label="Parceiro"
                            value={orgOption}
                            className="!max-w-[220px]"
                            options={[orgOption]}
                            hasEmptyOption
                            isDisabled
                        />
                        <BaseSelectInput
                            name="location"
                            label="Local"
                            value={locationId}
                            onChange={setLocationId}
                            className="!max-w-[220px]"
                            options={locationOptions}
                            hasEmptyOption
                        />

                        <BaseInput
                            name="battery"
                            label="Bateria"
                            value={location?.state?.filters?.battery_id || batterySearch}
                            onChange={(e: any) => setBatterySearch(e.target.value)}
                            className="!max-w-[220px]"
                            inputClassName="h-[38px]"
                            disabled={location?.state?.filters?.battery_id}
                        />
                        <BaseInput
                            name="device_origin"
                            label="Máquina Origem"
                            value={deviceOriginSearch}
                            onChange={(e: any) => setDeviceOriginSearch(e.target.value)}
                            className="!max-w-[220px]"
                            inputClassName="h-[38px]"
                        />
                        <BaseInput
                            name="device_return"
                            label="Máquina Destino"
                            value={deviceReturnSearch}
                            onChange={(e: any) => setDeviceReturnSearch(e.target.value)}
                            className="!max-w-[220px]"
                            inputClassName="h-[38px]"
                        />
                        <BaseInput
                            name="id"
                            label="Número do pedido"
                            value={idSearch}
                            onChange={(e: any) => setIdSearch(e.target.value)}
                            className="!max-w-[220px]"
                            inputClassName="h-[38px]"
                        />
                    </>
                )}
                <BasicButton className="h-[38px] !shadow-none !px-4" onClick={toggleFilters}>
                    {showFilters ? (
                        <EyeIcon color="#6B7280" height={24} />
                    ) : (
                        <EyeSlashIcon color="#6B7280" height={24} />
                    )}
                    <p className="ml-2">Filtros Avançados</p>
                </BasicButton>
                <BasicButton
                    // className="h-[36px] border-0 !shadow-none px-6"
                    className="h-[38px] !shadow-none px-6"
                    onClick={clearFilters}
                >
                    Limpar
                </BasicButton>
                <div>
                    <AccentButton className="h-[38px] px-6" onClick={() => submitFilters()}>
                        Filtrar
                    </AccentButton>
                </div>
            </div>
            <Table
                headers={headers}
                data={rents?.data || []}
                className="mt-4"
                valueClassName="!py-2"
                loading={loading}
            />
            <Pagination meta={rents?.meta} changePage={changePage} />
        </PartnerLayoutWithSidebar>
    );
};

export default PartnerRentList;
