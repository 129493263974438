import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';

const useFaqs = () => {
    const [faqs, setFaqs] = useState<any[]>([]);
    const { get, post, patch, _delete, loading, error } = useRequests();

    const fetchFaqs = async () => {
        let route = '/admin/faqs?perPage=100';

        try {
            const response = await get(route);
            const data = response?.data;

            // DEBUG FAQ POSITION
            // data.data = data.data?.map((item: any) => ({
            //     ...item,
            //     title: `${item.position} ${item.title}`,
            // }));

            setFaqs(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const createFaq = async (payload: any) => {
        let route = '/admin/faqs';

        try {
            await post(route, payload);
            setTimeout(() => toast.success('Tópico de ajuda criado com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const updateFaq = async (id: string, payload: any) => {
        let route = `/admin/faqs/${id}`;

        try {
            await patch(route, payload);
            setTimeout(() => toast.success('Tópico de ajuda alterado com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const deleteFaq = async (id: string) => {
        let route = `/admin/faqs/${id}`;

        try {
            await _delete(route);
            setTimeout(() => toast.success('Tópico de ajuda removido com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const updateFaqPosition = async (id: string, position: number) => {
        try {
            await updateFaq(id, { position });
            await fetchFaqs();
        } catch (err) {}
    };

    useEffect(() => {
        fetchFaqs();

        // eslint-disable-next-line
    }, []);

    return {
        faqs: faqs,
        fetchFaqs,
        createFaq,
        updateFaq,
        updateFaqPosition,
        deleteFaq,
        error,
        loading,
    };
};

export default useFaqs;
