import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';

interface Options {
    fetchData?: boolean;
    fetchDataOptions?: boolean;
}

const useOrgs = ({ fetchData = false, fetchDataOptions = false }: Options = {} as any) => {
    const [orgs, setOrgs] = useState<{ meta: any; data: any[] }>();
    const [orgOptions, setOrgOptions] = useState<SelectOption[]>([]);
    const { get, post, patch, _delete, loading, error } = useRequests();

    const fetchOrgs = async ({ page, search }: FetchParams = {}) => {
        let route = '/admin/orgs?';

        if (page) {
            route += `page=${page}&`;
        }

        if (search) {
            route += `search=${search}&`;
        }

        try {
            const response = await get(route);
            const data = response?.data;
            setOrgs(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const fetchOrgOptions = async ({ search }: FetchParams = {}) => {
        let route = '/admin/orgs?perPage=9999&';

        if (search) {
            route += `search=${search}&`;
        }

        try {
            const response = await get(route);
            const data = response?.data?.data;
            const options = data?.map((org: any) => ({
                value: org.id,
                label: org.name,
            }));
            setOrgOptions(options || []);
            setOrgs(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const createOrg = async (payload: any) => {
        let route = '/admin/orgs';

        try {
            await post(route, payload);
            setTimeout(() => toast.success('Parceiro criado com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const updateOrg = async (id: string, payload: any) => {
        let route = `/admin/orgs/${id}`;

        try {
            await patch(route, payload);
            setTimeout(() => toast.success('Parceiro alterado com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const deleteOrg = async (id: string) => {
        let route = `/admin/orgs/${id}`;

        try {
            await _delete(route);
            setTimeout(() => toast.success('Parceiro removido com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    useEffect(() => {
        if (fetchData) {
            fetchOrgs();
        }
        if (fetchDataOptions) {
            fetchOrgOptions();
        }
        // eslint-disable-next-line
    }, []);

    return {
        orgs,
        orgOptions,
        fetchOrgs,
        createOrg,
        updateOrg,
        deleteOrg,
        error,
        loading,
    };
};

export default useOrgs;
