import { useSelector } from 'react-redux';
import { PartnerLayoutWithSidebar } from '../../../common/components';
import { getSelectedOrg } from '../../../common/store';
import UpdateAdvertisingContent from '../../common/advertising-content/UpdateAdvertisingContent';

const PartnerUpdateAdvertisingContent = () => {
    const selectedOrg = useSelector(getSelectedOrg);
    const orgId = selectedOrg?.id as string;

    return (
        <PartnerLayoutWithSidebar>
            <UpdateAdvertisingContent orgId={orgId} />
        </PartnerLayoutWithSidebar>
    );
};
export default PartnerUpdateAdvertisingContent;
