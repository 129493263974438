import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';

interface Options {
    fetchData?: boolean;
}

type SearchFilters = 'battery_serial_number' | 'log_status';

type FetchLogsParams = FetchParams & {
    search_filters?: {
        [K in SearchFilters]: string;
    };
};

const useCommandLogs = ({ fetchData = false }: Options = {} as any) => {
    const [logs, setLogs] = useState<{ meta: any; data: any[] }>();
    const { get, loading, error } = useRequests();

    const fetchCommandLogs = async (queryParams: FetchLogsParams = {}) => {
        let route = '/admin/command-logs?';

        // add query filter key/values
        const queryKeys: (keyof FetchLogsParams)[] = ['page'];

        for (const queryKey of queryKeys) {
            const queryValue = queryParams[queryKey];
            if (queryValue) {
                route += `${queryKey}=${queryValue}&`;
            }
        }

        // add query search filter key/values
        const searchFilterKeys: SearchFilters[] = ['battery_serial_number', 'log_status'];

        for (const searchKey of searchFilterKeys) {
            const searchValue = queryParams.search_filters?.[searchKey];
            if (searchValue) {
                route += `${searchKey}=${searchValue}&`;
            }
        }

        try {
            const response = await get(route);
            const data = response?.data;
            setLogs(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    useEffect(() => {
        if (fetchData) {
            fetchCommandLogs();
        }
        // eslint-disable-next-line
    }, []);

    return {
        logs,
        fetchCommandLogs,
        error,
        loading,
    };
};

export default useCommandLogs;
