import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    DeleteConfirmationModal,
    LocationStatusPillComponent,
    Pagination,
    PartnerLayoutWithSidebar,
    Table,
} from '../../../common/components';
import { useLocations } from '../../../common/hooks';
import { getSelectedOrg } from '../../../common/store';

const ArrayLength: FC<any> = ({ value }) => {
    return <>{value.length}</>;
};

const headers = [
    { label: 'Nome', path: 'name' },
    { label: 'Endereço', path: 'address_name', displayTitle: true },
    { label: 'Máquinas', path: 'devices', Component: ArrayLength },
    { label: 'Status', path: 'status', Component: LocationStatusPillComponent },
];

const PartnerLocationList = () => {
    const selectedOrg = useSelector(getSelectedOrg);
    const orgId = selectedOrg?.id as any;
    const { locations, fetchLocations, deleteLocation, loading } = useLocations({
        fetchData: true,
        orgId,
    });

    const [search, setSearch] = useState('');

    const submitSearch = () => {
        console.log('searching for', { search });
        fetchLocations({ search });
    };

    const changePage = (page: number | string) => {
        if (page !== locations?.meta.currentPage) {
            fetchLocations({ search, page });
        }
    };

    const [locationToDelete, setLocationToDelete] = useState<User>();
    const closeUserDeleteModal = () => setLocationToDelete(undefined);

    const onClickDelete = (user: User) => {
        setLocationToDelete(user);
    };

    const onConfirmDelete = async () => {
        if (!locationToDelete) return;
        try {
            await deleteLocation(locationToDelete.id);
        } catch (err) {}
        setLocationToDelete(undefined);
        fetchLocations();
    };

    const activePath = `/orgs/${orgId}/locations`;
    const addLocationHref = `${activePath}/new`;
    const editLocationHref = `${activePath}/edit`;

    return (
        <PartnerLayoutWithSidebar>
            <Table
                headers={headers}
                data={locations?.data || []}
                className="mt-4"
                onClickDelete={onClickDelete}
                titleButtonLabel="Adicionar local"
                titleButtonHref={addLocationHref}
                editPath={editLocationHref}
                searchBarProps={{ search, setSearch, submit: submitSearch }}
                loading={loading}
            />
            <Pagination meta={locations?.meta} changePage={changePage} />
            <DeleteConfirmationModal
                title="Excluir local"
                description="Você tem certeza que deseja excluir esse local? 
        Essa operação não pode ser desfeita."
                open={!!locationToDelete}
                closeModal={closeUserDeleteModal}
                acceptButtonAction={onConfirmDelete}
                acceptButtonLabel="conta"
            />
        </PartnerLayoutWithSidebar>
    );
};

export default PartnerLocationList;
