import { FC } from 'react';

interface Props {
    message?: string;
}

const ErrorMessage: FC<Props> = ({ message }) => {
    const _message = typeof message === 'string' ? [message] : message;

    if (!message) return <></>;

    return (
        <div className="flex flex-col">
            {_message?.map((msg) => (
                <div key={msg} className="mt-2 max-w-full text-red-600 break-all">
                    {msg}
                </div>
            ))}
        </div>
    );
};

export default ErrorMessage;
