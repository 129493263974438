import { FC } from 'react';

interface Props {
    partnerId?: string;
    partnerName?: string;
}

const PointList: FC<Props> = ({ partnerId, partnerName }) => {
    return <></>;
};

export default PointList;
