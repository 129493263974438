import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { BoltIcon } from '../../common/assets';
import { AccentButton, BaseLayout, Input } from '../../common/components';
import { useAuth } from '../../common/hooks';
import { requiredFieldMessage } from '../../common/utils/validation.utils';

const schema = yup
    .object({
        password: yup.string().trim().min(8).required(requiredFieldMessage()),
    })
    .required(requiredFieldMessage());

function ResetPassword() {
    const { resetPassword, loading } = useAuth();
    const [searchParams] = useSearchParams();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            password: '',
        },
    });

    const onSubmit = async (data: any) => {
        const id = searchParams.get('id');
        const access_token = searchParams.get('access-token');
        const payload = { ...data, id, access_token };
        await resetPassword(payload);
    };

    return (
        <BaseLayout>
            <div className="flex flex-col flex-1 px-6 py-12 min-h-full lg:px-8">
                <div className="mt-16 sm:mx-auto sm:w-full sm:max-w-sm">
                    <BoltIcon className="mx-auto w-auto h-10" />
                    <h2 className="mt-10 text-2xl font-bold tracking-tight leading-9 text-center text-gray-900">
                        Redefinir senha
                    </h2>
                </div>

                <div className="p-12 mt-10 bg-white rounded shadow-md sm:mx-auto md:w-full md:max-w-md">
                    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <Input
                                name="password"
                                label="Senha"
                                type="password"
                                required
                                control={control}
                                error={errors?.password}
                            />
                        </div>

                        <div>
                            <AccentButton type="submit" loading={loading}>
                                Redefinir senha
                            </AccentButton>
                        </div>
                    </form>
                </div>
            </div>
        </BaseLayout>
    );
}

export default ResetPassword;
