import { AdminSettingsLayout } from '../../../common/components';
import CreateBankAccount from '../../common/bank-accounts/CreateBankAccount';

const AdminCreateBankAccount = () => {
    return (
        <AdminSettingsLayout title="Adicionar conta bancária">
            <CreateBankAccount />
        </AdminSettingsLayout>
    );
};
export default AdminCreateBankAccount;
