import { AdminAdvertisingLayout } from '../../../../common/components';
import AdvertisingContent from '../../../common/advertising-content/AdvertisingContent';

const AdminAdvertisingContent = () => {
    return (
        <AdminAdvertisingLayout title="Conteúdos de publicidade">
            <AdvertisingContent />
        </AdminAdvertisingLayout>
    );
};

export default AdminAdvertisingContent;
