import axios, { AxiosResponse } from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, authActions } from '../store';
import { getErrorMessage } from '../utils/application.utils';

export const isUserUnauthenticated = (response?: AxiosResponse) => {
    if (!response) return false;
    const condition = response.status === 401 || (response.status === 403 && !response?.data);
    return condition;
};

const useRequests = () => {
    const token = useSelector((state: RootState) => state.auth.token);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState<true>();
    const dispatch = useDispatch();

    const host = process.env.REACT_APP_API_HOST_URL;
    const headers = { Authorization: `Bearer ${token}` };

    const get: any = async (
        route: string,
        options: { raw?: boolean; params?: any; token?: string; responseType?: string } = {},
    ) => {
        setError('');
        setLoading(true);
        const { raw, params } = options;
        const endpoint = raw ? route : `${host}${route}`;
        const requestOptions: any = raw ? {} : { headers, params };

        if (options.token && requestOptions.headers) {
            requestOptions.headers = { Authorization: `Bearer ${options.token}` };
        }

        if (options.responseType) {
            requestOptions.responseType = options.responseType;
        }

        try {
            const response = await axios.get(endpoint, requestOptions);
            // console.log(response);
            return response;
        } catch (err: any) {
            handleError(err);
        } finally {
            setLoading(undefined);
        }
    };

    const post = async (route: string, payload: any) => {
        setError('');
        setLoading(true);
        const endpoint = `${host}${route}`;

        try {
            const response = await axios.post(endpoint, payload, { headers });
            // console.log(response);
            return response;
        } catch (err: any) {
            handleError(err);
        } finally {
            setLoading(undefined);
        }
    };

    const patch = async (route: string, payload: any) => {
        setError('');
        setLoading(true);
        const endpoint = `${host}${route}`;

        try {
            const response = await axios.patch(endpoint, payload, { headers });
            // console.log(response);
            return response;
        } catch (err: any) {
            handleError(err);
        } finally {
            setLoading(undefined);
        }
    };

    const _delete = async (route: string) => {
        setError('');
        setLoading(true);
        const endpoint = `${host}${route}`;

        try {
            const response = await axios.delete(endpoint, { headers });
            // console.log(response);
            return response;
        } catch (err: any) {
            handleError(err);
        } finally {
            setLoading(undefined);
        }
    };

    const handleError = (err: any) => {
        verifyAuthError(err);
        const errorMessage = getErrorMessage(err);
        setError(errorMessage);
        console.error(err);
        throw err;
    };

    const verifyAuthError = (err: any) => {
        if (isUserUnauthenticated(err?.response)) {
            console.log('!!! USER NOT AUTHENTICATED !!!');
            dispatch(authActions.logout());
        }
    };

    const clear = () => {
        setError('');
    };

    return { get, post, patch, _delete, error, loading, clear };
};

export default useRequests;
