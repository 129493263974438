import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PartnerLayoutWithSidebar } from '../../../common/components';
import { usePoints } from '../../../common/hooks';
import { getSelectedOrg } from '../../../common/store';
import { schema } from './PartnerCreatePoint';
import UserForm from '../users/UserForm';

interface Props {
    orgId?: string;
}

const createFormData = (data: any) => ({
    ...data,
});

const PartnerUpdatePoint: FC<Props> = () => {
    const org = useSelector(getSelectedOrg);
    const orgId = org?.id as string;
    const { updatePoint, loading, error } = usePoints();
    const navigate = useNavigate();
    const location = useLocation();

    const pointData = location.state?.data;

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: useMemo(() => pointData && createFormData(pointData), [pointData]),
    });

    useEffect(() => {
        reset(createFormData(pointData));

        // eslint-disable-next-line
    }, []);

    const onSubmit = async (data: any) => {
        try {
            await updatePoint(pointData.id, data);
            const path = `/orgs/${orgId}/points`;
            navigate(path);
        } catch (err) {
            // try again...
        }
    };

    return (
        <PartnerLayoutWithSidebar>
            <UserForm
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors} // client-side validation
                error={error} // server-side error
                loading={loading}
                title="Conta de acesso do ponto"
                description="Informações da conta de usuário que terá acesso a conta do ponto"
            />
        </PartnerLayoutWithSidebar>
    );
};

export default PartnerUpdatePoint;
