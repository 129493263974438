import { yupResolver } from '@hookform/resolvers/yup';
import { createId } from '@paralleldrive/cuid2';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { PartnerLayoutWithSidebar } from '../../../common/components';
import { useLocations } from '../../../common/hooks';
import { getSelectedOrg } from '../../../common/store';
import {
    minNumberMessage,
    positiveNumberMessage,
    requiredFieldMessage,
} from '../../../common/utils/validation.utils';
import PartnerLocationForm from './PartnerLocationForm';

export const schema = yup
    .object({
        is_active: yup.boolean().required(requiredFieldMessage()),
        name: yup.string().trim().required(requiredFieldMessage()),
        address_name: yup.string().trim().required(requiredFieldMessage()),
        address_lat: yup.string().trim().required(requiredFieldMessage()),
        address_lon: yup.string().trim().required(requiredFieldMessage()),
        opening_hour: yup.string().trim().required(requiredFieldMessage()),
        closing_hour: yup.string().trim().required(requiredFieldMessage()),
        charge_value_first_hour: yup
            .number()
            .min(0.01, minNumberMessage('0.01'))
            .required(requiredFieldMessage()),
        charge_value_per_hour: yup
            .number()
            .min(0.01, minNumberMessage('0.01'))
            .required(requiredFieldMessage()),
        charge_free_minutes: yup.number().required(requiredFieldMessage()),
        charge_daily_limit: yup.number().required(requiredFieldMessage()),
        split_rule: yup.object({
            total_percentage: yup
                .number()
                .positive(positiveNumberMessage())
                .required(requiredFieldMessage()),
            percentage_splits: yup.array().of(
                yup.object().shape({
                    id: yup.string().trim().required(requiredFieldMessage()),
                    total_percentage: yup
                        .number()
                        .positive(positiveNumberMessage())
                        .required(requiredFieldMessage()),
                    bank_account_id: yup.object().shape({
                        value: yup.string().trim().required(requiredFieldMessage()),
                    }),
                }),
            ),
        }),
        advertising_total_time_percentage: yup.number(),
        advertising_point_share_active: yup.boolean().required(requiredFieldMessage()),
        advertising_point_require_approval: yup.boolean().required(requiredFieldMessage()),
        advertising_org_time_percentage: yup
            .number()
            .positive(positiveNumberMessage())
            .required(requiredFieldMessage())
            .min(0)
            .max(100),
        advertising_org_value_percentage: yup
            .number()
            .positive(positiveNumberMessage())
            .required(requiredFieldMessage())
            .min(0)
            .max(100),
        advertising_point_time_percentage: yup.number(),
        advertising_point_value_percentage: yup.number(),
        devices: yup.array().of(
            yup.object().shape({
                value: yup.string().trim().required(requiredFieldMessage()),
            }),
        ),
    })
    .required(requiredFieldMessage());

const initialValue = {
    is_active: true,
    name: '',
    address_name: '',
    address_lat: '',
    address_lon: '',
    opening_hour: '',
    closing_hour: '',
    charge_value_first_hour: 0,
    charge_value_per_hour: 0,
    charge_free_minutes: 0,
    charge_daily_limit: 0,
    allow_free_credits: true,
    split_rule: {
        total_percentage: 0,
        percentage_splits: [
            { id: createId(), total_percentage: 0, bank_account_id: { value: '' } },
        ],
    },
    advertising_total_time_percentage: 100,
    advertising_point_share_active: false,
    advertising_point_require_approval: false,
    advertising_org_time_percentage: 100,
    advertising_org_value_percentage: 100,
    advertising_point_time_percentage: 0,
    advertising_point_value_percentage: 0,
    devices: [],
};

export const payloadFromSchema = (data: typeof initialValue, org_id: string) => {
    return {
        ...data,
        charge_value_first_hour: data.charge_value_first_hour.toFixed(2),
        charge_value_per_hour: data.charge_value_per_hour.toFixed(2),
        org_id,
        split_rule: {
            ...data.split_rule,
            total_percentage: data.split_rule.total_percentage.toFixed(2),
            percentage_splits: data.split_rule.percentage_splits.map((percentage: any) => ({
                ...percentage,
                total_percentage: percentage.total_percentage.toFixed(2),
                bank_account_id: percentage.bank_account_id.value,
            })),
        },
        devices: data.devices.map((device: any) => device.value),
    };
};

const PartnerCreateLocation = () => {
    const org = useSelector(getSelectedOrg);
    const orgId = org?.id as string;
    const { createLocation, loading, error } = useLocations({
        orgId,
    });
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        watch,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: initialValue,
    });

    useEffect(() => {
        setValue('split_rule.total_percentage', 100 - Number(org?.platform_split.total_percentage));

        const advertising_org_time_percentage = Number(org?.advertising_org_time_percentage);
        setValue('advertising_total_time_percentage', advertising_org_time_percentage);
        setValue('advertising_org_time_percentage', advertising_org_time_percentage);
        // eslint-disable-next-line
    }, []);

    const onSubmit = async (data: any) => {
        const payload = payloadFromSchema(data, orgId);

        try {
            await createLocation(payload);
            const path = `/orgs/${orgId}/locations`;
            navigate(path);
        } catch (err) {
            // try again...
        }
    };

    return (
        <PartnerLayoutWithSidebar>
            <PartnerLocationForm
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors} // client-side validation
                error={error} // server-side error
                loading={loading}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
                org={org}
            />
        </PartnerLayoutWithSidebar>
    );
};

export default PartnerCreateLocation;
