import { useSelector } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { getAuthToken } from '../common/store';
import { getAccessType } from '../common/store/slices/application';
import { adminRoutes, guestRoutes, partnerRoutes } from './routes';

const guestRouter = createBrowserRouter(guestRoutes);
const partnerRouter = createBrowserRouter(partnerRoutes);
const adminRouter = createBrowserRouter(adminRoutes);

const Routing = () => {
    const token = useSelector(getAuthToken);
    const accessType = useSelector(getAccessType);

    let selectedRouter = guestRouter;
    if (token && accessType === 'PARTNER') selectedRouter = partnerRouter;
    if (token && accessType === 'ADMIN') selectedRouter = adminRouter;

    return <RouterProvider router={selectedRouter} />;
};

export default Routing;
