export const requiredFieldMessage = (field?: string) => {
    const prefix = field ? `${field} ` : '';
    const message = `${prefix}é um campo obrigatório`;
    return message;
};

export const validEmailMessage = (field?: string) => {
    const prefix = field ? `${field} ` : '';
    const message = `${prefix}deve ser um e-mail válido`;
    return message;
};

export const positiveNumberMessage = (field?: string) => {
    const prefix = field ? `${field} ` : '';
    const message = `${prefix}deve ser um número positivo`;
    return message;
};

export const minNumberMessage = (min: string, field?: string) => {
    const prefix = field ? `${field} ` : '';
    const message = `${prefix}deve ser um número maior que ${min}`;
    return message;
};

export const requiredNumberMessage = (field?: string) => {
    const prefix = field ? `${field} ` : '';
    const message = `${prefix}é um campo numérico obrigatório`;
    return message;
};
