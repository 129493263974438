import { FC, useMemo, useState } from 'react';
import { AdminSettingsLayout, Table, UpdateFieldModal } from '../../../common/components';
import { useScreenAudio } from '../../../common/hooks';

const AudioPlayerComponent: FC<any> = ({ value }) => {
    if (!value) return <>-</>;
    return (
        <audio controls className="max-h-10">
            <source src={value} />
            navegador não suportado
        </audio>
    );
};

const headers = [
    { label: 'Descrição', path: 'description' },
    {
        label: 'Audio',
        path: 'audio',
        Component: AudioPlayerComponent,
        containerClassName: 'sm:py-1',
    },
];

const values = [
    { id: 'boot_strap', description: 'Inicialização', audio: '' },
    { id: 'networking_success', description: 'Rede - Sucesso', audio: '' },
    { id: 'networking_fail', description: 'Rede - Falha', audio: '' },
    { id: 'rent_success', description: 'Aluguel - Sucesso', audio: '' },
    { id: 'rent_fail', description: 'Aluguel - Falha', audio: '' },
    { id: 'return_success', description: 'Devolução - Sucesso', audio: '' },
    { id: 'return_fail', description: 'Devolução - Falha', audio: '' },
];

const AdminScreenAudios = () => {
    const { screenAudio, updateScreenAudio, loading } = useScreenAudio(true);

    const screenAudioTableData = useMemo(() => {
        if (!screenAudio) return values;

        return values.map((value) => ({
            ...value,
            audio: screenAudio[value.id],
        }));
        // eslint-disable-next-line
    }, [screenAudio]);

    const [updateAudio, setUpdateAudio] = useState<any>();

    const onConfirmUpdate = async (data: any) => {
        if (!data) return;
        try {
            // updateScreenAudio (fieldName, file)
            await updateScreenAudio(updateAudio.id, data);
        } catch (err) {
            // try again...
        }
    };

    return (
        <AdminSettingsLayout title="Padrões de Áudio">
            <Table
                headers={headers}
                data={screenAudioTableData}
                actionButtonLabel="Alterar"
                onClickAction={setUpdateAudio}
                loading={loading}
            />
            <UpdateFieldModal
                title={updateAudio?.description}
                open={!!updateAudio}
                // description="testing"
                acceptButtonAction={onConfirmUpdate}
                inputName={updateAudio?.id}
                closeModal={() => setUpdateAudio(undefined)}
                loading={loading}
                fileInputAccept="audio/*"
            />
        </AdminSettingsLayout>
    );
};

export default AdminScreenAudios;
