import { useSelector } from 'react-redux';
import { PartnerLayoutWithSidebar } from '../../../common/components';
import { getSelectedOrg } from '../../../common/store';
import CreateAdvertisingContent from '../../common/advertising-content/CreateAdvertisingContent';

const PartnerCreateAdvertisingContent = () => {
    const selectedOrg = useSelector(getSelectedOrg);
    const orgId = selectedOrg?.id as string;

    return (
        <PartnerLayoutWithSidebar>
            <CreateAdvertisingContent orgId={orgId} />
        </PartnerLayoutWithSidebar>
    );
};
export default PartnerCreateAdvertisingContent;
