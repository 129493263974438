import { AdminAdvertisingLayout } from '../../../../common/components';
import UpdateAdvertisingContent from '../../../common/advertising-content/UpdateAdvertisingContent';

const AdminUpdateAdvertisingContent = () => {
    return (
        <AdminAdvertisingLayout title="Alterar conteúdo de publicidade">
            <UpdateAdvertisingContent />
        </AdminAdvertisingLayout>
    );
};

export default AdminUpdateAdvertisingContent;
