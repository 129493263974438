import { FC } from 'react';
import { FieldError } from 'react-hook-form';
import Datepicker from 'react-tailwindcss-datepicker';
import InputContainer from './InputContainer';

type Props = {
    name: string;
    label?: string;
    className?: string;
    error?: FieldError;
    value: any;
    onChange?: any;
    useRange?: boolean;
};

const DatePickerRangeInput: FC<Props> = ({
    name,
    label,
    className,
    error,
    onChange,
    ...selectProps
}) => {
    return (
        <InputContainer name={name} label={label} className={className} error={error}>
            <Datepicker
                onChange={(value: any) => onChange(value)}
                inputClassName={(d) => `${d} max-h-[38px] !rounded`}
                containerClassName={(d) => `${d} max-h-[38px]`}
                {...selectProps}
            />
        </InputContainer>
    );
};

export default DatePickerRangeInput;
