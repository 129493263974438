import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    AdminLayout,
    AdminLayoutContentCard,
    DeleteConfirmationModal,
    Pagination,
    RentStatusPillComponent,
    SearchBarInput,
    Table,
} from '../../../common/components';
import { useRents } from '../../../common/hooks';
import { DateParserComponent } from '../../partner/batteries/PartnerBatteryList';
import { MinutesParserComponent } from '../../partner/rents/PartnerRentList';

const headers = [
    { label: 'Número do pedido', path: 'id' },
    { label: 'Data de início', path: 'start_time', Component: DateParserComponent },
    { label: 'Data de retorno', path: 'end_time', Component: DateParserComponent },
    { label: 'Duração', path: 'total_time', Component: MinutesParserComponent },
    { label: 'Bateria', path: 'battery.serial_number' },
    { label: 'Máquina Origem', path: 'device.serial_number' },
    { label: 'Máquina Destino', path: 'return_device.serial_number' },
    { label: 'Status', path: 'status', Component: RentStatusPillComponent },
    // cancelar
];

const ClientRentList = () => {
    const {
        state: { client },
    } = useLocation();

    const { rents, fetchRents, cancelRent, loading } = useRents({
        fetchData: true,
        clientId: client.id,
    });

    const [search, setSearch] = useState('');

    const submitSearch = () => {
        fetchRents({ search });
    };

    const changePage = (page: number | string) => {
        if (page !== rents?.meta.currentPage) {
            fetchRents({ search, page });
        }
    };

    const [rentToCancel, setRentToCancel] = useState<Rent>();
    const closeRentDeleteModal = () => setRentToCancel(undefined);

    const onClickDelete = (rent: Rent) => {
        setRentToCancel(rent);
    };

    const onConfirmCancel = async () => {
        if (!rentToCancel) return;
        try {
            await cancelRent(rentToCancel.id);
            setRentToCancel(undefined);
            submitSearch();
        } catch (err) {
            // try again...
        }
    };

    return (
        <AdminLayout title={`Pedidos de ${client?.user?.name}`}>
            <AdminLayoutContentCard>
                <SearchBarInput search={search} setSearch={setSearch} submit={submitSearch} />
                <Table
                    headers={headers}
                    data={rents?.data || []}
                    className="mt-4"
                    valueClassName="!py-2"
                    deleteButtonLabel="Cancelar" // replace this to onClickDelete...
                    onClickDelete={onClickDelete}
                    deleteCondition={(item: Rent) =>
                        ['PENDING_PAYMENT', 'ACTIVE', 'FINISHED'].includes(item.status)
                    }
                    loading={loading}
                />
                <Pagination meta={rents?.meta} changePage={changePage} />
            </AdminLayoutContentCard>
            <DeleteConfirmationModal
                title="Cancelar Aluguel"
                description="Você tem certeza que deseja cancelar esse aluguel? Se já existir uma transação a mesma também será cancelada/estornada"
                open={!!rentToCancel}
                closeModal={closeRentDeleteModal}
                acceptButtonAction={onConfirmCancel}
                acceptButtonCompleteLabel="Sim, cancelar alguel"
                loading={loading}
            />
        </AdminLayout>
    );
};

export default ClientRentList;
