import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { RootState, applicationActions, authActions } from '../store';
import { getErrorMessage } from '../utils/application.utils';

const useAuth = () => {
    const dispatch = useDispatch();
    const { get, post, error, loading } = useRequests();
    const user = useSelector((state: RootState) => state.auth.user);
    const [hasSentRecoverRequest, setHasSentRecoverRequest] = useState(false);

    const fetchOrg = async (id: string, token: string) => {
        let route = `/admin/orgs/${id}`;
        const response = await get(route, { token });
        return response?.data;
    };

    const login = async (payload: LoginPayload) => {
        const route = '/auth/login';

        try {
            const response = await post(route, payload);

            const user = response?.data?.user;

            if (user?.role === 'CLIENT' || !user?.org) {
                throw new Error('Usuário sem permissão');
            }

            if (user?.role === 'PARTNER') {
                try {
                    const token = response?.data?.access_token;
                    const org = await fetchOrg(user.org.id, token);
                    dispatch(applicationActions.selectOrg(org));
                } catch (err) {
                    throw new Error('Falha na requisição de informações do parceiro.');
                }
            }

            dispatch(applicationActions.setAccessType(user.role));
            dispatch(authActions.login(response?.data));
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const logout = async () => {
        dispatch(authActions.logout());
    };

    // const signup = async (payload: SignupPayload) => {
    //   const route = "/auth/users";

    //   try {
    //     const response = await post(route, payload);
    //     dispatch(authActions.login(response.data));
    //   } catch (err: any) {}
    // };

    const forgotPassword = async (payload: ForgotPasswordPayload) => {
        const route = '/auth/forgot-password';

        try {
            await post(route, payload);
            setHasSentRecoverRequest(true);
            toast.success('Email enviado com sucesso!');
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const resetPassword = async (payload: ResetPasswordPayload) => {
        const route = '/auth/reset-password';

        try {
            const response = await post(route, payload);
            dispatch(authActions.login(response?.data));
            toast.success('Senha atualizada com sucesso!');
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const refreshUser = async () => {
        const route = '/auth/profile';

        try {
            const response = await get(route);

            if (user?.role !== response.data.role) {
                dispatch(authActions.logout());
            } else {
                dispatch(authActions.refreshUser(response.data));
            }
        } catch (err: any) {}
    };

    return {
        // signup,
        login,
        logout,
        refreshUser,
        forgotPassword,
        hasSentRecoverRequest,
        resetPassword,
        error,
        loading,
    };
};

export default useAuth;
