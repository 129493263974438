import { FC, useMemo, useState } from 'react';
import { AdminSettingsLayout, Table, UpdateFieldModal } from '../../../common/components';
import { useScreenPlan } from '../../../common/hooks';

export const LinkComponent: FC<any> = ({ value }) => {
    if (!value) return <>-</>;
    return (
        <a
            href={value}
            target="_blank"
            rel="noreferrer"
            className="text-accent-600 border-b-accent-600 hover:border-b"
        >
            Visualizar
        </a>
    );
};

const headers = [
    { label: 'Tipo', path: 'description' },
    // { label: 'Tamanho', path: 'size' },
    { label: 'Imagem', path: 'value', Component: LinkComponent },
];

const tableEntry = (id: string, description: string) => {
    return {
        id,
        description,
        value: '',
        // size: '1280x1024',
        type: 'image',
    };
};

const values = [
    tableEntry('default_cross', 'Imagem de publicidade padrão da máquina de 8 slots'),
    tableEntry(
        'default_cross_left',
        'Imagem de publicidade esquerda padrão do gabinete de 8 slots',
    ),
    tableEntry('default_vertical', 'Imagem de publicidade padrão do gabinete grande'),
    tableEntry(
        'default_vertical_bottom',
        'Imagem de publicidade inferior padrão  do gabinete grande',
    ),
];

const AdminScreenPlans = () => {
    const { screenPlan, updateScreenPlanFile, loading } = useScreenPlan(true);

    const tableData = useMemo(() => {
        const imagesData = values.map((val) => ({
            ...val,
            value: screenPlan[val.id],
        }));

        return imagesData;
        // eslint-disable-next-line
    }, [screenPlan]);

    const [updatePlan, setUpdatePlan] = useState<any>();

    const onConfirmUpdate = async (data: any) => {
        if (!data) return;
        try {
            await updateScreenPlanFile(updatePlan.id, data);
        } catch (err) {}
    };

    return (
        <AdminSettingsLayout title="Padrões de publicidade">
            <div className="flex flex-col flex-1">
                <Table
                    headers={headers}
                    data={tableData}
                    actionButtonLabel="Alterar"
                    onClickAction={setUpdatePlan}
                    loading={loading}
                />
            </div>
            <UpdateFieldModal
                title={updatePlan?.description}
                open={!!updatePlan}
                acceptButtonAction={onConfirmUpdate}
                inputName={updatePlan?.id}
                closeModal={() => setUpdatePlan(undefined)}
                loading={loading}
                type="video,image"
                fileInputAccept="video/*, image/*"
                value={updatePlan?.value}
            />
        </AdminSettingsLayout>
    );
};

export default AdminScreenPlans;
