import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';

const useScreenPlan = (fetchData = false) => {
    const [screenPlan, setScreenPlan] = useState<any>({});
    const { get, patch, loading, error } = useRequests();

    const fetchScreenPlan = async () => {
        const route = '/admin/screen-plans';

        try {
            const response = await get(route);
            const data = response?.data?.data?.[0];
            setScreenPlan(data || {});
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const updateScreenPlanFile = async (field: string, file: any) => {
        // to upload file
        const formData = new FormData();
        formData.append(field, file);
        return updateScreenPlan(formData);
    };

    const updateScreenPlan = async (payload: any) => {
        if (!screenPlan.id) return;
        const route = `/admin/screen-plans/${screenPlan.id}`;

        try {
            const response = await patch(route, payload);
            const _screenPlan = response?.data;
            setScreenPlan(_screenPlan || {});
            return _screenPlan;
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    useEffect(() => {
        if (fetchData) {
            fetchScreenPlan();
        }
        // eslint-disable-next-line
    }, []);

    return {
        screenPlan,
        updateScreenPlanFile,
        updateScreenPlan,
        error,
        loading,
    };
};

export default useScreenPlan;
