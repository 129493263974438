import { useSelector } from 'react-redux';
import { PartnerLayoutWithSidebar } from '../../../common/components';
import { getSelectedOrg } from '../../../common/store';
import CreateBankAccount from '../../common/bank-accounts/CreateBankAccount';

const PartnerCreateBankAccount = () => {
    const selectedOrg = useSelector(getSelectedOrg);
    const orgId = selectedOrg?.id;

    return (
        <PartnerLayoutWithSidebar>
            <CreateBankAccount orgId={orgId} />
        </PartnerLayoutWithSidebar>
    );
};
export default PartnerCreateBankAccount;
