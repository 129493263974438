import clsx from 'clsx';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

type NavigationItem = {
    name: string;
    href: string;
    icon: FC<any>;
};

type NavigationDivider = {
    label: string;
    type: 'divider';
};

interface Props {
    navigation: any[];
    className?: string;
}

export const getCurrentRouteName = (
    current: string,
    navigation: any[], // NavigationItem[]
) => {
    for (const route of navigation) {
        if (!route.href) continue;
        if (current.includes(route.href)) {
            return route.name;
        }
    }

    if (current === '/') return navigation[0]?.name;
};

const SidebarNavigation: FC<Props> = ({ className, navigation }) => {
    const location = useLocation();

    const currentRouteName = getCurrentRouteName(location.pathname, navigation);

    const renderNavigationItem = (item: NavigationItem) => {
        return (
            <Link
                key={item.name}
                to={item.href}
                className={clsx(
                    currentRouteName === item.name
                        ? 'bg-gray-100 text-accent-400'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-accent-400',
                    'group flex items-center px-3 py-2 text-sm font-medium rounded-md',
                )}
                aria-current={currentRouteName === item.name ? 'page' : undefined}
            >
                <item.icon
                    className={clsx(
                        currentRouteName === item.name
                            ? 'text-accent-400'
                            : 'text-gray-400 group-hover:text-accent-400',
                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                    )}
                    aria-hidden="true"
                />
                <span className="truncate">{item.name}</span>
            </Link>
        );
    };

    const renderDividerLabel = (item: NavigationDivider) => {
        return <div className="!my-5 text-xs pl-3 font-bold text-gray-700">{item.label}</div>;
    };

    const renderItem = (item: any) => {
        if (item.type === 'divider') {
            return renderDividerLabel(item as NavigationDivider);
        }

        return renderNavigationItem(item as NavigationItem);
    };

    return (
        <nav
            className={`hidden space-y-1 min-w-[16rem] ${className} md:block`}
            aria-label="Sidebar"
        >
            {navigation.map((item) => renderItem(item))}
        </nav>
    );
};

export default SidebarNavigation;
