import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    DeleteConfirmationModal,
    PartnerLayoutWithSidebar,
    Table,
} from '../../../common/components';
import { useOrgUsers } from '../../../common/hooks';
import { getSelectedOrg } from '../../../common/store';

const headers = [
    { label: 'Nome', path: 'name' },
    { label: 'Email', path: 'email' },
];

const PartnerUserList = () => {
    const { orgUsers, fetchOrgUsers, removeOrgUser, loading } = useOrgUsers(true);

    const selectedOrg = useSelector(getSelectedOrg);

    const [userToDelete, setUserToDelete] = useState<User>();
    const closeUserDeleteModal = () => setUserToDelete(undefined);

    const onClickDelete = (user: User) => {
        setUserToDelete(user);
    };

    const onConfirmDelete = async () => {
        if (!userToDelete) return;
        try {
            await removeOrgUser(userToDelete.id);
        } catch (err) {}
        setUserToDelete(undefined);
        fetchOrgUsers();
    };

    const addOrgUserHref = `/orgs/${selectedOrg?.id}/users/new`;

    return (
        <PartnerLayoutWithSidebar>
            <Table
                headers={headers}
                data={orgUsers?.data || []}
                onClickDelete={onClickDelete}
                titleButtonLabel="Adicionar usuário parceiro"
                titleButtonHref={addOrgUserHref}
                loading={loading}
            />
            <DeleteConfirmationModal
                title="Remover conta de parceiro"
                description="Você tem certeza que deseja remover essa conta de usuário
parceiro? Essa operação não pode ser desfeita."
                open={!!userToDelete}
                closeModal={closeUserDeleteModal}
                acceptButtonAction={onConfirmDelete}
            />
        </PartnerLayoutWithSidebar>
    );
};

export default PartnerUserList;
