import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { GoogleMap, MarkerF, StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import { FC, Fragment, useCallback, useEffect, useRef, useState, type CSSProperties } from 'react';
import { AccentButton } from '../../../common/components';
import { useGeocoder, useGeolocation } from '../../../common/hooks';

interface Props {
    open: boolean;
    closeModal: () => void;
    onSubmitSelection: (params: { name: string; coords: any }) => void;
}

const containerStyle = {
    width: '1179px',
    height: '437px',
};

export const concordiaCoords = {
    lat: -27.2342,
    lng: -52.0286,
};

const inputStyle: CSSProperties = {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: 'relative',
    top: '10px',
    left: '10px',
};

const libraries = ['places' as const];

const LocationPickerModal: FC<Props> = ({ open, closeModal, onSubmitSelection }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
        libraries,
    });

    const { getAddressFromLatLng } = useGeocoder();

    // eslint-disable-next-line
    const [map, setMap] = useState(null);
    const [mapCenter, setMapCenter] = useState(concordiaCoords);

    const { coords } = useGeolocation();

    useEffect(() => {
        if (coords.lat && coords.lng) {
            setMapCenter(coords);
        }
    }, [coords]);

    const inputRef = useRef<any>();

    const onLoad = useCallback(function callback(map: any) {
        const autoComplete = new window.google.maps.places.Autocomplete(inputRef?.current, {
            componentRestrictions: { country: 'br' },
        });

        autoComplete.addListener('place_changed', () => {
            const place = autoComplete.getPlace();
            if (!place.geometry || !place.geometry.location) {
                // User entered the name of a Place that was not suggested and
                // pressed the Enter key, or the Place Details request failed.
                alert('this location not available');
            }
            if (place.geometry?.viewport || place.geometry?.location) {
                // do something
                const result = place.geometry?.location;

                const coords = {
                    lat: result?.lat(),
                    lng: result?.lng(),
                };

                if (coords.lat) {
                    setMapCenter(coords as any);
                }
            }
        });

        setMap(map);
    }, []);

    const onUnmount = useCallback(function callback(map: any) {
        setMap(null);
    }, []);

    const [selection, setSelection] = useState<any>();

    const handleMapSelect = (params: any) => {
        const coords = {
            lat: params.latLng?.lat(),
            lng: params.latLng?.lng(),
        };

        if (coords.lat) {
            setSelection(coords as any);
        }
    };

    const submitSelection = async () => {
        const coords = selection;

        const address = await getAddressFromLatLng(coords.lat, coords.lng);

        onSubmitSelection({ coords, name: address.address });

        closeModal();
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10 w-full" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="hidden sm:fixed sm:inset-0 sm:block sm:bg-gray-500 sm:bg-opacity-75 sm:transition-opacity" />
                </Transition.Child>

                <div className="overflow-y-auto fixed inset-0 z-10">
                    <div className="flex justify-center items-stretch w-full min-h-full text-center sm:items-center sm:px-6 lg:px-8">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-105"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-105"
                        >
                            <Dialog.Panel className="flex relative justify-center w-full max-w-7xl text-base text-left transition transform sm:my-8 sm:w-full">
                                <div className="flex overflow-hidden relative flex-col gap-5 p-4 pb-6 max-w-[100vw] bg-white sm:py-6 w-[1216px]">
                                    <div className="flex font-semibold">Mapa</div>
                                    <div className="flex w-[1179px] h-[437px]">
                                        {isLoaded ? (
                                            <GoogleMap
                                                mapContainerStyle={containerStyle}
                                                center={mapCenter}
                                                zoom={15}
                                                onLoad={onLoad}
                                                onUnmount={onUnmount}
                                                onClick={handleMapSelect}
                                                options={{
                                                    streetViewControl: false,
                                                    mapTypeControl: false,
                                                }}
                                            >
                                                <StandaloneSearchBox>
                                                    <input
                                                        ref={inputRef}
                                                        type="text"
                                                        placeholder="Pesquisar Endereço"
                                                        style={inputStyle}
                                                        className="w-[240px] focus:!w-[480px] transition-all"
                                                    />
                                                </StandaloneSearchBox>
                                                {isLoaded && selection && (
                                                    <MarkerF position={selection} />
                                                )}
                                            </GoogleMap>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <div className="flex justify-end">
                                        <AccentButton
                                            onClick={submitSelection}
                                            className="max-w-[177px]"
                                            disabled={!selection}
                                        >
                                            Confirmar seleção
                                        </AccentButton>
                                    </div>
                                </div>
                                <div className="block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={closeModal}
                                    >
                                        <span className="sr-only">fechar</span>
                                        <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
export default LocationPickerModal;
