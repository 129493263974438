import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { BoltIcon } from '../assets';
import { useAuth } from '../hooks';
import { getUser } from '../store';
import { getAccessType } from '../store/slices/application';

type Route = {
    name: string;
    href: string;
    path?: string;
    perfect?: boolean;
};

const partnerNavigation: Route[] = [{ name: 'Parceiros', href: '/', path: 'orgs' }];

const adminNavigation: Route[] = [
    { name: 'Dashboard', href: '/', perfect: true },
    { name: 'Máquinas', href: '/devices' },
    { name: 'Baterias', href: '/batteries' },
    { name: 'Parceiros', href: '/orgs' },
    { name: 'Clientes', href: '/clients' },
    // { name: "Transações", href: "#" },
    // { name: "Comissões", href: "#" },
    { name: 'Pedidos', href: '/rents' },
    { name: 'Financeiro', href: '/transactions' },
    { name: 'Publicidade', href: '/advertising/points' },
    { name: 'Configurações', href: '/settings/users' },
    { name: 'Logs', href: '/logs' },
];

interface Props {}

export const getCurrentRouteName = (current: string, navigation: typeof adminNavigation) => {
    for (const route of navigation) {
        const currentRoute = current.split('/')[1];
        if (currentRoute === route.href.split('/')[1] || currentRoute === route?.path) {
            return route.name;
        }
    }
};

const NavigationHeader: FC<Props> = () => {
    const user = useSelector(getUser);
    const { logout } = useAuth();
    const location = useLocation();

    const accessType = useSelector(getAccessType);
    let selectedNavigation = partnerNavigation;
    if (accessType === 'ADMIN') selectedNavigation = adminNavigation;

    const currentRouteName = getCurrentRouteName(location.pathname, selectedNavigation);

    return (
        <Disclosure as="nav" className="bg-white border-b border-gray-200">
            {({ open }) => (
                <>
                    <div className="px-4 mx-auto max-w-7xl md:px-6 lg:px-8">
                        <div className="flex justify-between h-16">
                            <div className="flex">
                                <div className="flex flex-shrink-0 items-center">
                                    <BoltIcon className="mx-auto w-auto h-8" />
                                </div>
                                <div className="hidden md:-my-px md:ml-6 md:flex md:space-x-8">
                                    {selectedNavigation.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            className={clsx(
                                                currentRouteName === item.name
                                                    ? 'border-accent-400 text-gray-900'
                                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
                                            )}
                                            aria-current={
                                                currentRouteName === item.name ? 'page' : undefined
                                            }
                                        >
                                            {item.name}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                            <div className="hidden md:ml-6 md:flex md:items-center">
                                {/* <button
                  type="button"
                  className="p-1 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="w-6 h-6" aria-hidden="true" />
                </button> */}

                                {/* Profile dropdown */}
                                <Menu as="div" className="relative ml-3">
                                    <div>
                                        <Menu.Button className="flex items-center max-w-xs text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2">
                                            <span className="sr-only">Open user menu</span>
                                            <div className="flex justify-center items-center w-8 h-8 text-xl font-normal text-white rounded-full bg-accent-400">
                                                <span className="relative -top-0.5">
                                                    {user?.email?.slice(0, 2)}
                                                </span>
                                            </div>
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 py-1 mt-2 w-48 bg-white rounded-md ring-1 ring-black ring-opacity-5 shadow-lg origin-top-right focus:outline-none">
                                            <Menu.Item key="Sign out">
                                                {({ active }) => (
                                                    <Link
                                                        to="/"
                                                        onClick={logout}
                                                        className={clsx(
                                                            active ? 'bg-gray-100' : '',
                                                            'block px-4 py-2 text-sm text-gray-700',
                                                        )}
                                                    >
                                                        Sair
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                            <div className="flex items-center -mr-2 md:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="inline-flex justify-center items-center p-2 text-gray-400 bg-white rounded-md hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block w-6 h-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block w-6 h-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="md:hidden">
                        <div className="pt-2 pb-3 space-y-1">
                            {selectedNavigation.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className={clsx(
                                        currentRouteName === item.name
                                            ? 'bg-indigo-50 border-accent-400 text-indigo-700'
                                            : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                                        'block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
                                    )}
                                    aria-current={
                                        currentRouteName === item.name ? 'page' : undefined
                                    }
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                        </div>
                        <div className="pt-4 pb-3 border-t border-gray-200">
                            <div className="flex items-center px-4">
                                <div className="flex-shrink-0">
                                    <div className="flex justify-center items-center w-8 h-8 text-xl font-normal leading-none text-white rounded-full bg-accent-400">
                                        <span className="relative -top-0.5">
                                            {user?.email?.slice(0, 2)}
                                        </span>
                                    </div>
                                </div>
                                <div className="ml-3">
                                    <div className="text-base font-medium text-gray-800">
                                        {user?.name || user?.email?.split('@')[0]}
                                    </div>
                                    <div className="text-sm font-medium text-gray-500">
                                        {user?.email}
                                    </div>
                                </div>
                                {/* <button
                  type="button"
                  className="flex-shrink-0 p-1 ml-auto text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="w-6 h-6" aria-hidden="true" />
                </button> */}
                            </div>
                            <div className="mt-3 space-y-1">
                                <Disclosure.Button
                                    as="a"
                                    onClick={logout}
                                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                                >
                                    Sair
                                </Disclosure.Button>
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};

export default NavigationHeader;
