import { FC, ReactNode } from 'react';

import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import BaseLayout from './BaseLayout';
import NavigationHeader from './NavigationHeader';
import { getCurrentRouteName } from './SidebarNavigation';

interface PageContent {
    title?: string;
    children?: ReactNode;
    titleButtonLabel?: string;
    titleButtonHref?: string;
    navigation?: any;
}

const AdminLayout: FC<PageContent> = ({ navigation, ...props }) => {
    return (
        <BaseLayout>
            <div className="min-h-full">
                <NavigationHeader />
                {navigation && <SubNavigationMobile navigation={navigation} title={props.title} />}
                <PageContentLayout {...props} />
            </div>
        </BaseLayout>
    );
};

const SubNavigationMobile: FC<any> = ({ navigation, title }) => {
    const location = useLocation();
    const currentRouteName = getCurrentRouteName(location.pathname, navigation);

    return (
        <Disclosure as="nav" className="bg-gray-200 border-b border-gray-200 md:hidden">
            {({ open }) => (
                <>
                    <div className="px-4 mx-auto max-w-7xl md:px-6 lg:px-8">
                        <div className="flex justify-between items-center h-16">
                            <h1 className="text-3xl font-bold tracking-tight leading-tight text-gray-900">
                                {title}
                            </h1>
                            <div className="flex">
                                <div className="flex items-center -mr-2 md:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="inline-flex justify-center items-center p-2 text-gray-400 bg-white rounded-md hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XMarkIcon
                                                className="block w-6 h-6"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <Bars3Icon
                                                className="block w-6 h-6"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Disclosure.Panel className="md:hidden">
                        <div className="pt-2 pb-3 space-y-1">
                            {navigation.map((item: any) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className={clsx(
                                        currentRouteName === item.name
                                            ? 'bg-indigo-50 border-accent-400 text-indigo-700'
                                            : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                                        'block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
                                    )}
                                    aria-current={
                                        currentRouteName === item.name ? 'page' : undefined
                                    }
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};

const PageContentLayout: FC<PageContent> = ({
    children,
    title,
    titleButtonLabel,
    titleButtonHref,
}) => {
    return (
        <div className="md:py-10">
            {/* <h1 className="text-3xl font-bold tracking-tight leading-tight text-gray-900 sm:ml-6 md:hidden">
                {title}
            </h1> */}
            <header className="hidden md:block">
                <div className="flex justify-between items-center px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight leading-tight text-gray-900">
                        {title}
                    </h1>
                    {titleButtonHref && (
                        <Link
                            className="flex w-max justify-center rounded-md bg-accent-400 px-8 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-accent-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-400 disabled:opacity-50 disabled:pointer-events-none"
                            to={titleButtonHref}
                        >
                            {titleButtonLabel}
                        </Link>
                    )}
                </div>
            </header>
            <main className="mt-8">
                <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">{children}</div>
            </main>
        </div>
    );
};

interface PageContentCard {
    children?: ReactNode;
}

export const AdminLayoutContentCard: FC<PageContentCard> = ({ children }) => {
    return <div className="p-8 bg-white rounded-lg">{children}</div>;
};

export default AdminLayout;
