import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';

const useAdvertisingSegments = () => {
    const [advertisingSegments, setAdvertisingSegments] = useState<{
        meta: any;
        data: any[];
    }>();
    const { get, post, patch, _delete, loading, error } = useRequests();

    const fetchAdvertisingSegments = async (page?: string) => {
        let route = '/admin/advertising-segments?';

        if (page) {
            route += `page=${page}`;
        }

        try {
            const response = await get(route);
            const data = response?.data;

            setAdvertisingSegments(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const createAdvertisingSegment = async (payload: any) => {
        let route = '/admin/advertising-segments';

        try {
            await post(route, payload);
            setTimeout(() => toast.success('Segmento de publicidade criado com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const updateAdvertisingSegment = async (id: string, payload: any) => {
        let route = `/admin/advertising-segments/${id}`;

        try {
            await patch(route, payload);
            setTimeout(() => toast.success('Segmento de publicidade alterado com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const deleteAdvertisingSegment = async (id: string) => {
        let route = `/admin/advertising-segments/${id}`;

        try {
            await _delete(route);
            setTimeout(() => toast.success('Segmento de publicidade removido com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    useEffect(() => {
        fetchAdvertisingSegments();

        // eslint-disable-next-line
    }, []);

    return {
        advertisingSegments: advertisingSegments,
        fetchAdvertisingSegments,
        createAdvertisingSegment,
        updateAdvertisingSegment,
        deleteAdvertisingSegment,
        error,
        loading,
    };
};

export default useAdvertisingSegments;
