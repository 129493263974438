import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getSelectedOrg } from '../store';
import { getErrorMessage } from '../utils/application.utils';

const useOrgUsers = (fetchData = false) => {
    const [orgUsers, setOrgUsers] = useState<{ meta: any; data: any[] }>();
    const { get, post, _delete, loading, error } = useRequests();

    const selectedOrg = useSelector(getSelectedOrg);
    const orgId = selectedOrg?.id || 'MISSING-ORG-ID';

    const fetchOrgUsers = async ({ page, search }: FetchParams = {}) => {
        let route = `/admin/orgs/${orgId}/users`;

        if (page) {
            route += `page=${page}&`;
        }

        if (search) {
            route += `search=${search}&`;
        }

        try {
            const response = await get(route);
            const data = response?.data;
            setOrgUsers(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const addOrgUser = async (payload: any) => {
        let route = `/admin/orgs/${orgId}/users`;

        try {
            await post(route, payload);
            setTimeout(() => toast.success('Usuário parceiro adicionado com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    // const updateOrgUser = async (id: string, payload: any) => {
    //   let route = `/admin/orgs/${orgId}/users/${id}`;

    //   try {
    //     const response = await patch(route, payload);
    //     const data = response?.data;
    //     setTimeout(() => toast.success("Parceiro alterado com sucesso!"), 0);
    //     console.log("updated org:", data);
    //   } catch (err: any) {
    //     const errorMessage = err.response?.data?.message;
    //     toast.error(errorMessage);
    //     throw err;
    //   }
    // };

    const removeOrgUser = async (id: string) => {
        let route = `/admin/orgs/${orgId}/users/${id}`;

        try {
            await _delete(route);
            setTimeout(() => toast.success('Usuário parceiro removido com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    useEffect(() => {
        if (fetchData) {
            fetchOrgUsers();
        }
        // eslint-disable-next-line
    }, []);

    return {
        orgUsers,
        fetchOrgUsers,
        addOrgUser,
        removeOrgUser,
        error,
        loading,
    };
};

export default useOrgUsers;
